import React, { useState, useEffect } from 'react';
import { Base64 } from 'js-base64';
import nftabi from "../abis/nftu.json";
import bnbabi from "../abis/bnbabi.json";
import { storage } from "../firebase.js";
import Nfts from './marketplace/ModalNft';
import PublishNft from './marketplace/ModalPublish';
import CreateNft from './marketplace/ModalCreator';
import EditorNft from './marketplace/ModalEditor';
const Web3 = require('web3-eth');
const { toChecksumAddress } = require('ethereum-checksum-address')
let list = [];
let hvga = false;

let w = window.innerWidth;
const Nftmodal = (props) => {

  let provider = props.array[0]
  let contract = props.array[1]
  let stakesabi = props.array[2]
  let tokenct = props.array[3]
  let tokenabi = props.array[4]
  const [STAKECTS, setSTAKECTS] = useState(contract);
  const [web3, setWeb3] = useState(provider == "https://bsc-dataseed.binance.org" ? new Web3(new Web3.providers.HttpProvider(provider)) : new Web3(provider));
  const [stakect, setstakect] = useState(new web3.Contract(stakesabi, STAKECTS));
  const [tokenB, settokenB] = useState(new web3.Contract(tokenabi, tokenct));
  const [accounts, setaccounts] = useState([props.array[9]]);

  const [progress, setProgress] = useState(0);
  const [imagefile, setIF] = useState("0");
  const [imagePI, setIPI] = useState("0");
  const [imagePB, setIPB] = useState("0");
  const [imageuri, seturi] = useState("0");

  const [APR, setAPR] = useState(0);
  const [poolfee, setpoofee] = useState(0);
  const [aprC, setAPRC] = useState(1);

  const [name, setN] = useState("");
  const [desc, setD] = useState("");
  const [price, setP] = useState(1);
  const [limit, setL] = useState(0);
  const [_ct, setCT] = useState("");
  const [ID, setID] = useState(0);
  const [ctr, setctr] = useState("");
  const [wr, setwr] = useState(false);
  const [cnm, setcnm] = useState(0);
  const [cdesc, setcdesc] = useState(0);
  const [cava, setcava] = useState(0);
  const [ccap, setccap] = useState(0);

  useEffect(() => {
    load()
  },
    [])
  async function load() {
    call(stakect.methods.taxa, setpoofee);
    loadlist()
  }
  function update() {

  }

  function getmkerror(e) {
    let a = '';
    if (e.toString().includes('{')) {
      // let b = e.toString().split('{');
      // let c = '{' + b[1];
      //let aaa = JSON.parse(c);
      return e;
    } else {
      a = e;
      return a;
    }
  }

  function nextblock(accountBalancemctTB, d) {

    if ((accountBalancemctTB / (10 ** d)).toString().includes('.')) {
      if (accountBalancemctTB.toString().length >= d) {

        return (
          (accountBalancemctTB / (10 ** d)).toString().split('.')[0] +
          '.' +
          (accountBalancemctTB / (10 ** d)).toString().split('.')[1].slice(0, 4)
        );
      } else {
        return (
          '0.' +
          '0'.repeat(d - accountBalancemctTB.toString().length) +
          accountBalancemctTB.toString().slice(0, 2)
        );
      }
    } else {
      return accountBalancemctTB / (10 ** d);
    }
  }

  async function call(func, callback, ...args) {
    try {
      const result = await func(...args).call()
      callback(result)
      return result
    } catch (error) {
      return ""
    }
  }



  function calculateSize(img, maxWidth, maxHeight) {
    let width = img.width;
    let height = img.height;

    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
      }
    }
    return [width, height];
  }
  const formHandlerPI = (e) => {
    const file = e.target.files[0];
    function toDataURL(src, callback, outputFormat) {
      let image = new Image();
      image.crossOrigin = 'Anonymous';
      image.onload = function () {
        const [newWidth, newHeight] = calculateSize(this, 128, 128);
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        let dataURL;
        canvas.width = newWidth;
        canvas.height = newHeight;
        //ctx.drawImage(this, 0, 0);
        ctx.drawImage(this, 0, 0, newWidth, newHeight);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
      };
      image.src = src;
      if (image.complete || image.complete === undefined) {
        image.src = "data:image/gif;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
        image.src = src;
      }

    }
    toDataURL(URL.createObjectURL(e.target.files[0]),
      function (dataUrl) {
        setIPI(dataUrl)
        setcava(dataUrl)
      }
    )

  }
  const formHandlerPB = (e) => {
    const file = e.target.files[0];
    function toDataURL(src, callback, outputFormat) {
      let image = new Image();
      image.crossOrigin = 'Anonymous';
      image.onload = function () {
        const [newWidth, newHeight] = calculateSize(this, 179, 675);
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        let dataURL;
        canvas.width = newWidth;
        canvas.height = newHeight;
        //ctx.drawImage(this, 0, 0);
        ctx.drawImage(this, 0, 0, newWidth, newHeight);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
      };
      image.src = src;
      if (image.complete || image.complete === undefined) {
        image.src = "data:image/gif;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
        image.src = src;
      }

    }
    toDataURL(URL.createObjectURL(e.target.files[0]),
      function (dataUrl) {
        setIPB(dataUrl)
        setccap(dataUrl)
      }
    )

  }
  const uploadFilespf = (file, fileB) => {

    if (accounts != null) {
      if (accounts[0] != undefined) {

        const uploadTask = storage.ref(accounts[0] + `/${"PROFILELOGO"}`).putString(file.split(',')[1], "base64", { contentType: 'image/png' });
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgress(prog);
          },
          (error) => alert("UPLOAD FAILED, TRY LATER"),
          () => {
            storage
              .ref(accounts[0])
              .child("PROFILELOGO")
              .getDownloadURL()
              .then(
                (url) => {
                  const uploadTaskb = storage.ref(accounts[0] + `/${"PROFILEBANNER"}`).putString(fileB.split(',')[1], "base64", { contentType: 'image/png' });
                  uploadTaskb.on(
                    "state_changed",
                    (snapshot) => {
                      const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                      setProgress(prog);
                    },
                    (error) => alert("UPLOAD FAILED, TRY LATER"),
                    () => {
                      storage
                        .ref(accounts[0])
                        .child("PROFILEBANNER")
                        .getDownloadURL()
                        .then(
                          (urlb) => {
                            uploadprf(url, urlb, cnm, cdesc)
                          }
                        );
                    }
                  )
                }
              );
          }
        )
      }
    }
  }
  const uploadprf = (url, urlb, name, desc) => {

    if (accounts != null) {
      if (accounts[0] != undefined) {
        stakect.methods.editProfile(name, desc, url, urlb).estimateGas({ from: accounts[0], value: parseInt(poolfee) + 1000000000 })
          .then((gas) => {
            stakect.methods.editProfile(name, desc, url, urlb).send({
              from: accounts[0],
              value: parseInt(poolfee) + 1000000000,
              gas: gas
            })
              .then(gg => {
                update()
                alert("Success")
                window.location.location = "/marketplace"
              })
              .catch(error => {

                alert(getmkerror(error.message))
              });
          }).catch((error) => {
            alert(getmkerror(error.message))
          });
      }
    }
  }
  const formHandler = (e) => {
    const file = e.target.files[0];
    function toDataURL(src, callback, outputFormat) {
      let image = new Image();
      image.crossOrigin = 'Anonymous';
      image.onload = function () {
        const [newWidth, newHeight] = calculateSize(this, 512, 512);
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        let dataURL;
        canvas.width = newWidth;
        canvas.height = newHeight;
        //ctx.drawImage(this, 0, 0);
        ctx.drawImage(this, 0, 0, newWidth, newHeight);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
      };
      image.src = src;
      if (image.complete || image.complete === undefined) {
        image.src = "data:image/gif;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
        image.src = src;
      }

    }
    toDataURL(URL.createObjectURL(e.target.files[0]),
      function (dataUrl) {
        seturi(dataUrl)
        setIF(dataUrl)
      }
    )
  }

  function randomNumberInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const Globe = (props) => {

    return (
      <circle cx={props.cx} cy={props.cy} r={props.r} fill={props.fill} />
    )
  }
  let lusvg = [
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
    { nada: "nada" },
  ]
  let h = <div style={{
    height: "260px",
    width: "100%",
    display: "grid",
    marginTop: "auto",
    borderRadius: "10px",
    background: "#fff",
    color: "#fff",
  }}>
    <svg xmlns="http://www.w3.org/2000/svg" style={{ fill: "#ffffff", width: "100%", height: "100%" }}>
      {lusvg.map((e) => <Globe cx={randomNumberInRange(0, window.innerWidth)} cy={randomNumberInRange(0, 512)} r={randomNumberInRange(10, 250)} fill={"#" + randomNumberInRange(0, 99) + randomNumberInRange(0, 99) + randomNumberInRange(0, 99)} />)}
    </svg>
  </div>
  function getdatanm(cnm) {
    if (cnm == 0) {
      return (window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 1]).toUpperCase().toString().slice(0, 4) + '...' + (window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 1]).toUpperCase().toString().slice(-4)
    } else {
      return cnm
    }
  }
  function getdatadesc(cnm) {
    if (cnm == 0) {
      return "this is a profile from " + (window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 1]).toUpperCase().toString().slice(0, 4) + '...' + (window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 1]).toUpperCase().toString().slice(-4)
    } else {
      return cnm
    }
  }
  function getdatalogo(cnm) {
    if (cnm == 0) {
      return "https://avatars.dicebear.com/api/initials/" + (window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 1]).toUpperCase().toString().slice(-2) + ".svg"
    } else {
      return cnm
    }
  }
  function getdatacapa(cnm) {
    if (cnm == 0) {
      return h
    } else {


      return <i style={{
        height: "260px",
        width: "100%",
        margin: "auto",
        background: 'url(' + cnm + ') center center / 100% no-repeat',

        borderRadius: "5px",
      }}>
      </i>
    }
  }

  const uploadFiles = (file) => {
    if (accounts != null) {
      if (accounts[0] != undefined) {
        const n = name + Math.floor((new Date().getTime() / 1000));
        const uploadTask = storage.ref(accounts[0] + `/${n}`).putString(file.split(',')[1], "base64", { contentType: 'image/png' });
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgress(prog);
          },
          (error) => alert("UPLOAD FAILED, TRY LATER"),
          () => {
            storage
              .ref(accounts[0])
              .child(n)
              .getDownloadURL()
              .then(
                (url) => {
                  uploadnft(url, n, desc)
                }
              );
          }
        )
      }
    }
  }
  const uploadnft = (url, nam, desc) => {

    if (accounts != null) {
      if (accounts[0] != undefined) {

        let jsonb64 = {
          "name": name,
          "description": desc,
          "image": url
        }

        const string = JSON.stringify(jsonb64) // convert Object to a String
        const encodedString = Base64.encode(string);
        let uri = "data:application/json;base64," + encodedString.toString()
        let ct
        if (wr) {
          ct = ctr;
        } else {
          ct = '0x0000000000000000000000000000000000000000';
        }
        stakect.methods.createNFTs(uri, price, limit, ct, aprC).estimateGas({ from: accounts[0], value: parseInt(poolfee) + 1000000000 })
          .then((gas) => {
            stakect.methods.createNFTs(uri, price, limit, ct, aprC).send({
              from: accounts[0],
              value: parseInt(poolfee) + 1000000000,
              gas: gas
            })
              .then(gg => {
                update()
                alert("Success")
                window.location.location = "/marketplace"
              })
              .catch(error => {
                const deleteTask = storage.ref(accounts[0] + `/${nam}`)
                deleteTask.delete().then(() => {
                  // File deleted successfully
                }).catch((error) => {
                  // Uh-oh, an error occurred!
                });

                alert(getmkerror(error.message))
              });
          }).catch((error) => {
            const deleteTask = storage.ref(accounts[0] + `/${nam}`)
            deleteTask.delete().then(() => {
              // File deleted successfully
            }).catch((error) => {
              // Uh-oh, an error occurred!
            });
            alert(getmkerror(error.message))
          });
      }
    }
  }
  async function loadlist() {
    if (hvga) { } else {
      const address = (window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 1])

      stakect.methods.nftsview().call().then(string => {
        let cu = [0, 1]
        for (var i = 0; i < string.length; i++) {
          if (cu.includes(i)) {
          } else {
            let obj = {
              pos: i,
            }
            list.push(obj)
          }
        };
      }).catch(error => {
        //console.log(error)
        hvga = false;
      })
      if (props.view == 3) {
        await call(stakect.methods.profile, setcnm, address, 0);
        await call(stakect.methods.profile, setcdesc, address, 1);
        await call(stakect.methods.profile, setcava, address, 2);
        await call(stakect.methods.profile, setccap, address, 3);
      }
      if (props.view == 4) {
        if (accounts != null) {
          if (accounts[0] != undefined) {
            await call(stakect.methods.profile, setcnm, accounts[0], 0);
            await call(stakect.methods.profile, setcdesc, accounts[0], 1);
            await call(stakect.methods.profile, setcava, accounts[0], 2);
            await call(stakect.methods.profile, setccap, accounts[0], 3);
          }
        }

      }
    }
    hvga = true;
  }



  async function getimage(ct, id) {
    let address = "0";
    try {
      address = toChecksumAddress(ct)
    } catch (e) {
      console.error('invalid ethereum address', e.message)
    }
    if (address != 0) {
      const a = await new web3.Contract(nftabi, toChecksumAddress(ct))
      a.methods.tokenURI(id).call().then(string => {
        let link = string
        if (string.toString().includes("ipfs") && !string.toString().includes("https")) {
          link = "https://ipfs.io/ipfs/" + string.toString().split("//")[1]
        }
        fetch(link)
          .then((response) => response.json())
          .then((responseJson) => {
            let links = responseJson.image
            if (links.toString().includes("ipfs") && !links.toString().includes("https")) {
              links = "https://ipfs.io/ipfs/" + links.toString().split("//")[1]
            }
            document.getElementById("image" + ct + id).style.background = "url(" + links + ") center center / 100% no-repeat"
            document.getElementById("name" + ct + id).innerHTML = responseJson.name
          })
          .catch((error) => {
            console.error(error);
          });

      }).catch(error => {
        if (APR == 0) { } else {

        }
      })
    }
  }


  function sellnft(pric, contract, id) {
    stakect.methods.sellNFTs(pric, contract, id).estimateGas({ from: accounts[0], value: parseInt(poolfee) + 10 })
      .then((gas) => {
        stakect.methods.sellNFTs(pric, contract, id).send({
          from: accounts[0],
          value: parseInt(poolfee) + 10,
          gas: gas
        })
          .then(gg => {
            update()
            alert("Success")
            window.location.location = "/marketplace"
          })
          .catch(error => {

            alert(getmkerror(error.message))
          });
      }).catch((error) => {
        alert(getmkerror(error.message))
      });
  }
  async function approve(contract, id) {
    let address = "0";
    try {
      address = toChecksumAddress(contract)
    } catch (e) {
      alert('invalid ethereum address', e.message)
    }
    if (address != 0) {
      const a = await new web3.Contract(nftabi, address)
      a.methods.approve(STAKECTS, id).send({
        from: accounts[0],
      })
        .then(gg => {
          alert("Success")
        })
        .catch(error => {

          alert(getmkerror(error.message))
        });
    }
  }
  const MODAs = (props) => {
    const [smo, setsmo] = useState(false);
    const [display, setdisplay] = useState("grid");
    const [show, setshow] = useState(0);
    const [name, setname] = useState(0);
    const [desc, setdesc] = useState(0);
    const [image, setimage] = useState(0);
    const [cont, setcont] = useState(0);
    const [tax, setax] = useState(0);
    const [dol, setdol] = useState(0);
    const [limit, setlimit] = useState(0);
    const [buys, setbuys] = useState(0);
    const [creator, setcreator] = useState("0");
    const [text, settext] = useState("");

    const [cnm, setcnm] = useState(0);
    const [cdesc, setcdesc] = useState(0);
    const [cava, setcava] = useState(0);
    const [ccap, setccap] = useState(0);

    const [showr, setshowr] = useState(0);
    const [apr, setAPR] = useState(0);

    const [symb, setSymb] = useState("0");
    const [dec, setDec] = useState("0");
    const [clb, setC] = useState(0);

    if (image == 0) {
      getdata()
    }
    async function getdata() {
      const _contract = await stakect.methods.nfts(props.pos).call()
      const r = await stakect.methods.reject(_contract).call()
      if (show != r) {
        setshow(r)
      }
      if (cont != contract) {
        setcont(_contract)
      }
      if (image == 0) {
        getimage(_contract, 0)
      }
    }

    async function claimnftrewardu() {
      const a = await new web3.Contract(nftabi, cont)
      a.methods._withdrawStakeR().estimateGas({ from: accounts[0] })
        .then((gas) => {

          a.methods._withdrawStakeR().send({
            from: accounts[0],
            gas: gas
          })
            .then(gg => {
              alert("Success withdraw")
            })
            .catch(error => {
              alert(getmkerror(error.message))
            });

        }).catch((error) => {
          alert(getmkerror(error.message))
        });
    }
    async function buy() {

      const a = await new web3.Contract(nftabi, cont)
      a.methods._buynft(0).estimateGas({ from: accounts[0], value: parseInt(tax) + 1000000 })
        .then((gas) => {
          a.methods._buynft(0).send({
            from: accounts[0],
            value: parseInt(tax) + 1000000,
            gas: gas
          })
            .then(gg => {
              update()
              alert("Success")
            })
            .catch(error => {
              alert(getmkerror(error.message))
            });
        }).catch((error) => {
          alert(getmkerror(error.message))
        });
    }

    async function getimage(ct, id) {
      let address = "0";
      try {
        address = toChecksumAddress(ct)
      } catch (e) {
        console.error('invalid ethereum address', e.message)
      }
      if (address != 0) {
        const a = await new web3.Contract(nftabi, toChecksumAddress(ct))
        call(a.methods.ower, (strin) => {
          if (props.view == 3) {
            if (strin != (window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 1])) {
              setshow(1)
            }
          }
          if (creator != strin) {
            setcreator(strin)
          }
        })
        call(a.methods.price, setax)
        call(a.methods.tax, setdol)
        call(a.methods.limit, setlimit)
        call(a.methods.buys, setbuys)
        call(a.methods.BEP20Rtoken, (string) => {
          if (string == "0x0000000000000000000000000000000000000000") {
            setshowr(1)
          } else {
            setshowr(0)
            const b = new web3.Contract(bnbabi, string)
            call(b.methods.symbol, setSymb)
            call(b.methods.decimals, setDec)
          }
        })
        call(a.methods.FIXAPR, setAPR)

        if (accounts != null) {
          if (accounts[0] != undefined) {
            let timestamp = parseInt(new Date() / 1000);
            let rpr = parseInt(31449600000 / 1000)
            const amount = await call(a.methods.amount, (e) => { }, accounts[0])
            call(a.methods.since, (since) => {
              let times = parseInt((timestamp - since) / 1)
              let reward = (parseInt(amount / rpr) * times);
              setC(reward)
            }, accounts[0])
          }
        }
        call(a.methods.cont, (cont) => {

          if (cont == "0x0000000000000000000000000000000000000000") {

          } else {
            getfromother(a, cont)
          }

        })
        call(a.methods.turi, async (string) => {
          let link = string
          if (string.toString().includes("ipfs") && !string.toString().includes("https")) {
            link = "https://ipfs.io/ipfs/" + string.toString().split("//")[1]
          }
          const responseJson = await fetch(link)
            .then((response) => response.json())
          let links = responseJson.image
          if (links.toString().includes("ipfs") && !links.toString().includes("https")) {
            links = "https://ipfs.io/ipfs/" + links.toString().split("//")[1]
          }
          setimage(links)
          setdesc(responseJson.description)
          setname(responseJson.name)
        },)
      }
    }


    async function getfromother(a, cont) {
      const b = await new web3.Contract(nftabi, cont)
      const _id = await call(b.methods.id, () => { })
      const string = await call(b.methods.tokenURI, () => { }, _id)

      let link = string
      if (string.toString().includes("ipfs") && !string.toString().includes("https")) {
        link = "https://ipfs.io/ipfs/" + string.toString().split("//")[1]
      }
      const responseJson = await fetch(link)
        .then((response) => response.json())
      let links = responseJson.image
      if (links.toString().includes("ipfs") && !links.toString().includes("https")) {
        links = "https://ipfs.io/ipfs/" + links.toString().split("//")[1]
      }
      setimage(links)
      setdesc(responseJson.description)
      setname(responseJson.name)
    }
    function getdatanm(cnm) {
      if (cnm == 0) {
        return creator.toString().slice(0, 4) + '...' + creator.toString().slice(-4)
      } else {
        return cnm
      }
    }
    function getdatadesc(cnm) {
      if (cnm == 0) {
        return "this is a profile from " + creator.toString().slice(0, 4) + '...' + creator.toString().slice(-4)
      } else {
        return cnm
      }
    }
    function getdatalogo(cnm) {
      if (cnm == 0) {
        return "https://avatars.dicebear.com/api/initials/" + creator.toString().slice(-2) + ".svg"
      } else {
        return cnm
      }
    }
    function getdatacapa(cnm) {
      if (cnm == 0) {
        return creator
      } else {
        return cnm
      }
    }

    function sett() {
      if (limit == 0) {
        if (buys == 0) {
          return "Ilimited"

        } else {
          if (show == 0) { setshow(1) }
          return "Out of stock"
        }
      } else {
        if (limit - buys == 0) {
          if (show == 0) { setshow(1) }
          return "Out of stock"
        } else {
          return limit - buys
        }

      }
    }
    function getshow() {
      if (show != 0) {
        return "none";
      } else {
        return "grid";
      }
    }
    function getshowr() {
      if (showr != 0) {
        return false;
      } else {
        return true;
      }
    }
    function getshort(e) {
      return e;
    }
    const ModalBuy = (info) => {
      return (
        <div style={{ color: "#222", height: "100%", width: "100%", position: "fixed", display: "grid", background: "linear-gradient(to bottom right, #ffffff20, #ffffff20)", top: "0", left: "0", zIndex: "10000" }}>

          <div style={{ height: "min-content", display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr)", margin: "auto", width: "100%", maxWidth: "600px", background: "linear-gradient(to bottom right, #222, #222)", boxShadow: "-5px 10px 10px 10px #00000020", borderRadius: "10px", border: "1px solid #ffffff40", paddingBottom: "10px" }}>
            <div style={{
              height: "260px",
              width: "260px",
              color: "#fff",
              margin: "auto",
              background: "url('" + getshort(image) + " ')  center center / 100% no-repeat",
              display: "grid",
              borderRadius: "10px",
            }}>

            </div>
            <div style={{
              height: "min-content",
              minHeight: "280px",
              width: "100%",
              maxWidth: "280px",
              display: getshow(),
              gridTemplateRows: "auto",
              margin: "auto",
              background: "#ffffff00",
              color: "#fff",
              borderRadius: "2px"
            }}>
              <div style={{
                height: "min-content",
                width: "100%",
                display: "grid",
                margin: "auto",
                gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)",
                gap: "2px",

              }}>
                <div style={{
                  height: "40px",
                  width: "260px",
                  display: "grid",
                  gridTemplateColumns: "40px auto 80px",
                  margin: "auto",
                  gap: "10px",
                  marginTop: "10px",
                }}>
                  <div onClick={() => { window.location.href = "/marketplace/profile/" + creator }} style={{
                    height: "30px",
                    width: "30px",
                    color: "#fff",
                    margin: "auto",
                    background: "url('" + getdatalogo(cava) + " ')  center center / 100% no-repeat",
                    cursor: "pointer",
                    display: "grid",
                    borderRadius: "90px",

                  }}>

                  </div>

                  <p style={{
                    textAlign: "left",
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "#fff",
                    margin: "auto",
                    marginLeft: "0",
                    overflow: "hidden"
                  }}>{name.toString().toUpperCase()}</p>

                  <div style={{
                    height: "50px",
                    width: "100%",
                    margin: "auto",
                    display: "grid", gridTemplateColumns: "auto",
                    borderRadius: "10px",
                    padding: "2px"
                  }}>
                    <p style={{ fontSize: "15px", fontWeight: "bold", textAlign: "right", height: "min-content", marginTop: "auto", marginBottom: "auto" }}>{nextblock(tax, 18)} BNB
                      <br />
                      <span style={{ fontSize: "12px", color: "#8b8b8b" }}>${nextblock(dol, 18)} USD</span></p>
                  </div>
                </div>
                <div style={{
                  height: "min-content",
                  width: "95%",
                  margin: "auto",
                  display: "grid",
                }}>
                  <p style={{
                    textAlign: "justify",
                    fontSize: "2vh",
                    color: "#e5e5e5",


                  }}>
                    Available: {sett()}
                  </p>

                  <p style={{
                    height: "100px",
                    textAlign: "justify",
                    fontSize: "12px",
                    color: "#e5e5e5",
                    overflowX: "hidden",
                    overflowY: "auto",
                    lineBreak: "auto"
                  }}>
                    <br />
                    {desc}
                    <br />
                    <br />
                  </p>
                  <div style={{ display: "grid", gridTemplateColumns: "auto auto ", border: "none", height: "40px", width: "100%", margin: "auto", borderRadius: "10px", color: "#fff" }}>
                    {<button onClick={() => { buy() }} style={{
                      cursor: "pointer", display: "grid", gridTemplateColumns: "auto", height: "50px", width: "auto", border: "1px solid #ffffff20", margin: "auto 1vh",
                      background: "#ffffff20", borderRadius: "2px", color: "#fff"
                    }}>
                      <p style={{ fontSize: "2vh", textAlign: "left", height: "min-content", margin: "auto" }}><span style={{ textAlign: "left", fontWeight: "bold", fontSize: "2.2vh" }}>BUY</span></p>
                    </button>}
                    <button onClick={() => { setsmo(false) }} style={{
                      cursor: "pointer", display: "grid", gridTemplateColumns: "auto", height: "50px", width: "auto", border: "1px solid #ffffff20", margin: "auto 1vh",
                      background: "#ffffff20", borderRadius: "2px", color: "#fff"
                    }}>
                      <p style={{ fontSize: "2vh", textAlign: "left", height: "min-content", margin: "auto" }}><span style={{ textAlign: "left", fontWeight: "bold", fontSize: "2.2vh" }}>CLOSE</span></p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <>
        {smo && <ModalBuy />}
        <div onClick={() => { setsmo(true) }} style={{
          height: "min-content",
          minHeight: "280px",
          width: "100%",
          maxWidth: "280px",
          display: getshow(),
          gridTemplateRows: "auto",
          margin: "auto",
          background: "#ffffff00",
          color: "#fff",
          borderRadius: "2px"
        }}>
          <div style={{
            height: "min-content",
            width: "100%",
            display: "grid",
            margin: "auto",
            gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)",
            gap: "2px",

          }}>
            <div style={{
              height: "260px",
              width: "260px",
              color: "#fff",
              margin: "auto",
              background: "url('" + getshort(image) + " ')  center center / 100% no-repeat",
              display: "grid",
              borderRadius: "10px",
            }}>
            </div>
            <div style={{
              height: "40px",
              width: "260px",
              display: "grid",
              gridTemplateColumns: "40px auto 80px",
              margin: "auto",
              gap: "10px",
              marginTop: "10px",
            }}>
              <div onClick={() => { window.location.href = "/marketplace/profile/" + creator }} style={{
                height: "30px",
                width: "30px",
                color: "#fff",
                margin: "auto",
                background: "url('" + getdatalogo(cava) + " ')  center center / 100% no-repeat",
                cursor: "pointer",
                display: "grid",
                borderRadius: "90px",

              }}>
              </div>
              <p style={{
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "bold",
                color: "#fff",
                margin: "auto",
                marginLeft: "0",
                overflow: "hidden"
              }}>{name.toString().toUpperCase()}</p>

              <div style={{
                height: "50px",
                width: "100%",
                margin: "auto",
                display: "grid", gridTemplateColumns: "auto",
                borderRadius: "10px",
                padding: "2px"
              }}>
                <p style={{ fontSize: "15px", fontWeight: "bold", textAlign: "right", height: "min-content", marginTop: "auto", marginBottom: "auto" }}>{nextblock(tax, 18)} BNB
                  <br />
                  <span style={{ fontSize: "12px", color: "#8b8b8b" }}>${nextblock(dol, 18)} USD</span></p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <div style={{
      height: "min-content",
      width: "100%",
      display: "grid",
      margin: "auto",
      marginTop: "auto",
      color: "#fff",

    }}>
      <div style={{
        height: "min-content",
        width: "100%",
        listStyleType: "none",
        display: "grid",

      }}>
        {props.view == 0 && <Nfts
          data={{
            getdatacapa,
            ccap,
            getdatalogo,
            cava,
            getdatanm,
            cnm,
            getdatadesc,
            cdesc,
            list,
            MODAs,
          }}
          view={0}
        />}
        {props.view == 1 && <PublishNft
          data={{
            _ct,
            ID,
            setCT,
            getimage,
            setP,
            setID,
            approve,
            sellnft,
            nextblock,
            poolfee,
            price,
          }}
        />}
        {props.view == 2 && <CreateNft
          data={{
            imageuri,
            formHandler,
            setN,
            setD,
            setP,
            setL,
            setwr,
            wr,
            setctr,
            setAPRC,
            nextblock,
            poolfee,
            imagefile,
            uploadFiles
          }}
        />}
        {props.view == 3 && <Nfts
          data={{
            getdatacapa,
            ccap,
            getdatalogo,
            cava,
            getdatanm,
            cnm,
            getdatadesc,
            cdesc,
            list,
            MODAs,
          }}
          view={3}
        />}
        {props.view == 4 && <EditorNft
          data={{
            getdatacapa,
            ccap,
            getdatalogo,
            cava,
            getdatanm,
            cnm,
            getdatadesc,
            cdesc,
            list,
            MODAs,
            setcnm,
            setcdesc,
            formHandlerPI,
            formHandlerPB,
            uploadFilespf,
            nextblock,
            poolfee
          }}
          view={4}
        />}
      </div>
    </div>
  );
}
export default Nftmodal;