export default {
    "martik": {
        Name: "Martik",
        logoUrl: "https://martik.site/icons/martik.png",
        Logo: <img
            src={"https://martik.site/icons/martik.png"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,
        backgroundColor: "linear-gradient(to bottom right, #161a1e, #161a1e)",
        buttonsColor: "#161a1e",
        modalsColor: "#161a1e",
        outbuttonsColor: "#fff",
        buttonsBorders: "1px solid #ffffff20",
        inputsColor: "#ffffff00",
        inputsBorders: "1px solid #ffffff20",
        Borders: "#ffffff20",
        outFont: "#fff",
        innerFont: "#fff",
        innerBFont: "#222",
        redirectTo: '/create/contract',
        Routers:
            [
                '/',
                '/projects',
                '/p2p',
                '/p2p/sell',
                '/pre-sales',
                '/fair-launchs',
                '/private-sales',
                '/pump-sales',
                '/pre-sale',
                '/fair-launch',
                '/private-sale',
                '/pump-sale',
                '/create/pre-sale',
                '/create/fair-launch',
                '/create/private-sale',
                '/create/pump-sale',
                '/pre-sale/edit',
                '/fair-launch/edit',
                '/private-sale/edit',
                '/pump-sale/edit',
                '/swap',
                '/smart-bridge',
                '/create/contract',
                '/betting',
                '/multistake',
                '/exchange',
                '/volume-bot',
                '/farms',
                '/manager',
                '/stake',
                '/stake/create',
                '/addlpv2',
                '/marketplace',
                '/marketplace/publish',
                '/marketplace/create',
                '/marketplace/edit/profile',
                '/marketplace/profile',
                '/lock',
                '/lock/data',
                '/lock/create',
                '/lock/update',
                '/lock/transfer',
                '/terms',
                '/whitepaper',
                '/nft/pools',
                '/explorer',
                '/explorer/address',
                '/explorer/token',
                '/explorer/block',
                '/explorer/tx',
            ],
        menudata: [
            {
                permit: true,//Trade
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: "0x116526135380E28836C6080f1997645d5A807FAE",
        info: {
            title: "if ( MARTIK ){ return TRUST; }",
            desc: "To leverage technology and achieve your business goals, Martik offers custom software verification and development services that put you light years ahead in security for your project and community.",
            buyon: "http://martik.site/swap?token=0x116526135380E28836C6080f1997645d5A807FAE",
            name: "martik",
            symbol: "MTK",
            telegram: "http://t.me/martik_en",
            twitter: "https://twitter.com/martikcrypto",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0xA4de20856640d59A146393ac8CcF3F8e2133DF33",
                    ccFees: [
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.0
                    ],

                    tokenTo50Fee: "0x116526135380E28836C6080f1997645d5A807FAE"
                }
            },
            "swap": {
                web3: {
                    Router: "0xA1a39C041221765eb52BaEA2FDBbc77aa92c0dF3",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0xC1b4AcC0E4420369BE3C634777F890e42007327B",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                }
            }

        }

    },
    "xshiba": {
        Name: "XHIBA",
        logoUrl: "https://ipfs.io/ipfs/QmW1rvRFjvTnddYJ1nBnsYMRiasf4WQQXpsqaa5oRxUmzb?filename=WhatsApp_Image_2023-09-07_at_13.50.18-removebg-preview.png",
        Logo: <img src={"https://ipfs.io/ipfs/QmW1rvRFjvTnddYJ1nBnsYMRiasf4WQQXpsqaa5oRxUmzb?filename=WhatsApp_Image_2023-09-07_at_13.50.18-removebg-preview.png"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,
        backgroundColor: "linear-gradient(to bottom right, #161a1e, #161a1e)",
        buttonsColor: "#161a1e",
        modalsColor: "#161a1e",
        outbuttonsColor: "#fff",
        buttonsBorders: "1px solid #ffffff20",
        inputsColor: "#ffffff00",
        inputsBorders: "1px solid #ffffff20",
        Borders: "#ffffff20",
        outFont: "#fff",
        innerFont: "#fff",
        innerBFont: "#222",
        redirectTo: '/create/contract',
        Routers:
            [
                '/',
                '/swap',
                '/create/contract',
                '/farms',
                '/manager',
                '/stake',
                '/terms',
                '/whitepaper',
                '/stake/create',
            ],
        menudata: [
            {
                permit: true,//Trade
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: true,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: "0x414Af493C4e2D463B2FdA65A28d1631d25ECC298",
        info: {
            title: "Everything is possible",
            desc: "XHIBA is a community-driven meme token from Martik, missed the Martik private sale? Don't miss Xhiba's 🚀🌒",
            buyon: "https://martik.site/exchange?trade=XHIBA_USDT",
            name: "XHIBA",
            symbol: "XHIBA",
            telegram: "https://t.me/xhiba_pt",
            twitter: "https://twitter.com/xhiba_pt",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0xA4de20856640d59A146393ac8CcF3F8e2133DF33",
                    ccFees: [
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.0
                    ],

                    tokenTo50Fee: "0x414Af493C4e2D463B2FdA65A28d1631d25ECC298"
                }
            },
            "swap": {
                web3: {
                    Router: "0xA1a39C041221765eb52BaEA2FDBbc77aa92c0dF3",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            }, {
                                "nome": "XHIBA",
                                "bname": "XHIBA",
                                "icon": "https://ipfs.io/ipfs/QmW1rvRFjvTnddYJ1nBnsYMRiasf4WQQXpsqaa5oRxUmzb?filename=WhatsApp_Image_2023-09-07_at_13.50.18-removebg-preview.png",
                                "contract": "0x414Af493C4e2D463B2FdA65A28d1631d25ECC298"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0xC1b4AcC0E4420369BE3C634777F890e42007327B",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                    "0x82FE030AE76Dd614FE053ca36b80D49879fF5082": true,
                    "0x71C65050D2B48b911F6F869fBDd6d822eE15FF6b": true,
                    "0x560fF6a296D5F84b480aEbf57CFc54E9Faec6354": true,
                    "0xD7984989E7693a19C2358BE526266A76b8A2b7BC": true,
                    "0x46006BEbd30f3864A2B2555f6D88fa3EE8BE342E": true,
                    "0x7B30A0C75a9d0bC3849f6D5BD2F6Bdc921573aa9": true,
                    "0x8423995001780bB80ABBbF24845507e21cA56061": true,
                    "0x0427d0497fd82507666c186B50188526e02F2701": true,
                    "0x4316380ad94458DF90f527b790eDBC81292846db": true,

                    "0x701f1254c5cCb95a09Ed82C6b23e19782fAa47A4": true,
                    "0x46b821064370bDaD28eaA2ECdf2629384CECF820": true,
                    "0x27A634CaF303ec10D5558e585A3dD6354d8ea389": true,
                    "0xAef0332741872259EC50A3cbA1DE82d5790D0bC0": true,
                    "0x583D5Ece5e83816689d2FbD0543f6B962474C1fd": true,
                    "0x41897De6fCa8B42C7233eB42f27756CE0E85cC9e": true,
                    "0xB7d3D8f6D8cCaF58744dF547AAdFFe0CFC48f729": true,
                    "0xcfCf1E93c2E38d2F3328cBDA6b4f22410749aF01": true,
                }
            }
        }

    },
    "sbk": {
        Name: "NSBK",
        logoUrl: "https://newsbk.site/wp-content/uploads/2023/08/photo_2023-08-19_18-43-04-removebg-preview.png",
        Logo: <img
            src={"https://newsbk.site/wp-content/uploads/2023/08/photo_2023-08-19_18-43-04-removebg-preview.png"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,

        backgroundColor: "url('" + "https://img.freepik.com/fotos-premium/um-close-up-de-um-monte-de-caixas-pretas-e-azuis-generativas-ai_974539-1304.jpg" + " ') center center / " + (window.innerWidth + window.innerHeight) + "px no-repeat",
        outFont: "#fff",
        buttonsColor: "#030017",
        modalsColor: "#030017",
        innerFont: "#fff",
        outbuttonsColor: "#FFAE00",
        innerBFont: "#222",
        buttonsBorders: "1px solid #00000020",
        inputsColor: "#ffffff00",
        inputsBorders: "1px solid #FFAE0050",
        Borders: "#FFAE0080",
        redirectTo: '/create/contract',
        Routers: [


            '/farms',

            '/stake',

            '/stake/create',

            '/',
            '/create/contract',
            '/manager',
            '/swap'
        ],
        menudata: [
            {
                permit: true,//Trade
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: true,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: '0xbE893B0C8d1b96666D17F344Dc095CCAFb9f0C9f',
        info: {
            title: "New Shiba Black 2.0 community",
            desc: "“Welcome to the New Shiba Black 2.0 universe! We are a passionate cryptocurrency community, pioneers in fair launches, and dedicated to the decentralized financial future. Join us today!”",
            buyon: "https://t.me/shibablackoficial",
            name: "SHIBA BLACK 2.0",
            symbol: "SBK",
            telegram: "https://t.me/shibablackoficial",
            twitter: undefined,
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0xA3a47569030aFF363Aed531d3443fd9bb9Caa022",
                    ccFees: [
                        0.2,
                        0.3,
                        0.3,
                        0.3,
                        0.4,
                        0.0
                    ],
                    tokenTo50Fee: "0xbE893B0C8d1b96666D17F344Dc095CCAFb9f0C9f"
                }
            },
            "swap": {
                web3: {
                    Router: "0xc9c1bf056E90d85dc8eb59717475Bd2b5C207696",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            }, {
                                "nome": "NSBK",
                                "bname": "Shiba Black 2.0",
                                "icon": "https://newsbk.site/wp-content/uploads/2023/08/photo_2023-08-19_18-43-04-removebg-preview.png",
                                "contract": "0xbE893B0C8d1b96666D17F344Dc095CCAFb9f0C9f"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0x7FD6bb4b075fd4932655F417bFaaf4528D2dbcfb",
                excludes: {
                    "0x82FE030AE76Dd614FE053ca36b80D49879fF5082": true,
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xa8b09b2cF2b1639c2A6aB0a32b5f73Dc61FA4318": true,
                    "0x9321eeE089364182C2A148FEb493CA0931d4Dab4": true,

                }
            }
        }
    },
    "xtiger": {
        Name: "xtiger",
        logoUrl: window.location.protocol + "//"+ window.location.host + "/icons/xt.jpeg",
        Logo: <img src={window.location.protocol + "//"+ window.location.host + "/icons/xt.jpeg"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,
        backgroundColor: "linear-gradient(to bottom right, #000000,#000000)",
        buttonsColor: "#000000",
        modalsColor: "#000000",
        outbuttonsColor: "#fff",
        buttonsBorders: "1px solid #ffffff20",
        inputsColor: "#ffffff00",
        inputsBorders: "1px solid #ffffff20",
        Borders: "#ffffff20",
        outFont: "#fff",
        innerFont: "#fff",
        innerBFont: "#222",
        redirectTo: '/create/contract',
        Routers:
            [
                '/',
                '/swap',
                '/create/contract',
                '/farms',
                '/manager',
                '/stake',
                '/terms',
                '/whitepaper',
                '/lock',
                '/lock/create',
                '/stake/create',
                '/play/slot'
            ],
        menudata: [
            {
                permit: true,//Trade
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: true,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: "0xEb7B213aaded6B1AEd9e4dC49a687764C216B35c",
        info: {
            title: "Everything is possible",
            desc: "XTIGER is a community-driven meme token from Martik, missed the Martik private sale? Don't miss Xtiger's 🚀🌒",
            buyon: "https://martik.site/exchange?trade=XTIGER_USDT",
            name: "XTIGER",
            symbol: "XTIGER",
            telegram: "https://t.me/xtiger_pt",
            twitter: "https://twitter.com/xtiger_pt",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0xA4de20856640d59A146393ac8CcF3F8e2133DF33",
                    ccFees: [
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.0
                    ],

                    tokenTo50Fee: "0xEb7B213aaded6B1AEd9e4dC49a687764C216B35c"
                }
            },
            "swap": {
                web3: {
                    Router: "0xA1a39C041221765eb52BaEA2FDBbc77aa92c0dF3",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            },
                            {
                                "nome": "XTIGER",
                                "bname": "XTIGER",
                                "icon": window.location.protocol + "//"+ window.location.host + "/icons/xt.jpeg",
                                "contract": "0xEb7B213aaded6B1AEd9e4dC49a687764C216B35c"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            }, {
                                "nome": "XTIGER",
                                "bname": "XTIGER",
                                "icon": window.location.protocol + "//"+ window.location.host + "/icons/xt.jpeg",
                                "contract": "0xEb7B213aaded6B1AEd9e4dC49a687764C216B35c"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0xC1b4AcC0E4420369BE3C634777F890e42007327B",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                    "0x82FE030AE76Dd614FE053ca36b80D49879fF5082": true,
                    "0x71C65050D2B48b911F6F869fBDd6d822eE15FF6b": true,
                    "0x560fF6a296D5F84b480aEbf57CFc54E9Faec6354": true,
                    "0xD7984989E7693a19C2358BE526266A76b8A2b7BC": true,
                    "0x46006BEbd30f3864A2B2555f6D88fa3EE8BE342E": true,
                    "0x7B30A0C75a9d0bC3849f6D5BD2F6Bdc921573aa9": true,
                    "0x8423995001780bB80ABBbF24845507e21cA56061": true,
                    "0x0427d0497fd82507666c186B50188526e02F2701": true,
                    "0x4316380ad94458DF90f527b790eDBC81292846db": true,
                    "0xBa7Bb73233b90502bEfF57A3e257f71A5fDF6739": true,

                    "0x701f1254c5cCb95a09Ed82C6b23e19782fAa47A4": true,
                    "0x46b821064370bDaD28eaA2ECdf2629384CECF820": true,
                    "0x27A634CaF303ec10D5558e585A3dD6354d8ea389": true,
                    "0xAef0332741872259EC50A3cbA1DE82d5790D0bC0": true,
                    "0x583D5Ece5e83816689d2FbD0543f6B962474C1fd": true,
                    "0x41897De6fCa8B42C7233eB42f27756CE0E85cC9e": true,
                    "0xB7d3D8f6D8cCaF58744dF547AAdFFe0CFC48f729": true,
                    "0xcfCf1E93c2E38d2F3328cBDA6b4f22410749aF01": true,
                }
            }
        }

    },
    "jijiswap": {
        Name: "jiji",
        logoUrl: window.location.protocol + "//"+ window.location.host + "/icons/jiji.jpg",
        Logo: <img src={window.location.protocol + "//"+ window.location.host + "/icons/jiji.jpg"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,
        backgroundColor: "linear-gradient(to bottom right, #110302, #110302)",
        buttonsColor: "#110302",
        modalsColor: "#110302",
        outbuttonsColor: "#fff",
        buttonsBorders: "1px solid #ffffff20",
        inputsColor: "#ffffff00",
        inputsBorders: "1px solid #ffffff20",
        Borders: "#ffffff20",
        outFont: "#fff",
        innerFont: "#fff",
        innerBFont: "#222",
        redirectTo: '/create/contract',
        Routers:
            [
                '/',
                '/swap',
                '/create/contract',
                '/farms',
                '/manager',
                '/stake',

                '/lock',
                '/lock/create',
                '/stake/create',
            ],
        menudata: [
            {
                permit: true,//Trade
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: true,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: "0x6a8cC171a671e0Aa7f84A0A36D7C1285D3A224D2",
        info: {
            title: "KURONEKO",
            desc: "$JIJI The token of New Era",
            buyon: "https://pancakeswap.finance/swap?outputCurrency=0x6a8cC171a671e0Aa7f84A0A36D7C1285D3A224D2",
            name: "KURONEKO ",
            symbol: "JIJI",
            telegram: "https://t.me/kuroneko1000xxx",
            twitter: "https://twitter.com/Kur0NekoCoin",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0xE17C02D39178DF74a860826ab33e93f694696849",
                    ccFees: [
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.0
                    ],

                    tokenTo50Fee: "0x6a8cC171a671e0Aa7f84A0A36D7C1285D3A224D2"
                }
            },
            "swap": {
                web3: {
                    Router: "0x4F42B4E05B48AC23bE2902c0000a99bB2A32d2A0",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            }, {
                                "nome": "JIJI",
                                "bname": "KURONEKO",
                                "icon": window.location.protocol + "//"+ window.location.host + "/icons/jiji.jpg",
                                "contract": "0x6a8cC171a671e0Aa7f84A0A36D7C1285D3A224D2"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0xe5963660d581DBDcfCaD7a44Bd31716a87fff84c",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                    "0x82FE030AE76Dd614FE053ca36b80D49879fF5082": true,
                    "0x71C65050D2B48b911F6F869fBDd6d822eE15FF6b": true,
                    "0x560fF6a296D5F84b480aEbf57CFc54E9Faec6354": true,
                    "0xD7984989E7693a19C2358BE526266A76b8A2b7BC": true,
                    "0x46006BEbd30f3864A2B2555f6D88fa3EE8BE342E": true,
                    "0x7B30A0C75a9d0bC3849f6D5BD2F6Bdc921573aa9": true,
                    "0x8423995001780bB80ABBbF24845507e21cA56061": true,
                    "0x0427d0497fd82507666c186B50188526e02F2701": true,
                    "0x4316380ad94458DF90f527b790eDBC81292846db": true,

                    "0x701f1254c5cCb95a09Ed82C6b23e19782fAa47A4": true,
                    "0x46b821064370bDaD28eaA2ECdf2629384CECF820": true,
                    "0x27A634CaF303ec10D5558e585A3dD6354d8ea389": true,
                    "0xAef0332741872259EC50A3cbA1DE82d5790D0bC0": true,
                    "0x583D5Ece5e83816689d2FbD0543f6B962474C1fd": true,
                    "0x41897De6fCa8B42C7233eB42f27756CE0E85cC9e": true,
                    "0xB7d3D8f6D8cCaF58744dF547AAdFFe0CFC48f729": true,
                    "0xcfCf1E93c2E38d2F3328cBDA6b4f22410749aF01": true,
                }
            }
        }

    },
    "casinosworld": {
        Name: "casinosworld",
        logoUrl: window.location.protocol + "//"+ window.location.host + "/icons/cw.png",
        Logo: <img src={window.location.protocol + "//"+ window.location.host + "/icons/cw.png"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,
        backgroundColor: "linear-gradient(to bottom right, #000, #000)",
        buttonsColor: "#000",
        modalsColor: "#00000000",
        outbuttonsColor: "#ffe804",
        buttonsBorders: "1px solid #33363f",
        inputsColor: "#16181d",
        inputsBorders: "1px solid #33363f",
        Borders: "#33363f",
        outFont: "#fff",
        innerFont: "#fff",
        innerBFont: "#000",
        redirectTo: '/create/contract',
        Routers:
            [
                '/',
                '/swap',
                '/create/contract',
                '/manager',
                '/pre-sales',
                '/fair-launchs',
                '/private-sales',
                '/pre-sale',
                '/fair-launch',
                '/private-sale',
                '/create/pre-sale',
                '/create/fair-launch',
                '/create/private-sale',
                '/pre-sale/edit',
                '/fair-launch/edit',
                '/private-sale/edit',
                '/lock',
                '/lock/create',

            ],
        menudata: [
            {
                permit: true,//Trade
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: true,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: "0x2Ea4f1E162a51b342A62Ae5F7D1506a6EBc98998",
        info: {
            title: "Casino World",
            desc: "",
            buyon: "https://pancakeswap.finance/swap?outputCurrency=0x2Ea4f1E162a51b342A62Ae5F7D1506a6EBc98998",
            name: "Casino World",
            symbol: "CW",
            telegram: "https://t.me/CasinooWorld",
            twitter: "https://x.com/CasinoWord",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0x9c2c848cb2917Df3410B7aaBc44d44735be324bd",
                    ccFees: [
                        0.15,
                        0.15,
                        0.15,
                        0.15,
                        0.15,
                        0.0,
                        0.0,
                     
                        0.0
                    ],

                    tokenTo50Fee: "0x2Ea4f1E162a51b342A62Ae5F7D1506a6EBc98998"
                }
            },
            "swap": {
                web3: {
                    Router: "0x11498dc56b3E87edbD54133e9f7e1bF4637c6818",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            }, {
                                "nome": "CW",
                                "bname": "Casino World",
                                "icon": window.location.protocol + "//"+ window.location.host + "/icons/cw.png",
                                "contract": "0x2Ea4f1E162a51b342A62Ae5F7D1506a6EBc98998"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0xe5963660d581DBDcfCaD7a44Bd31716a87fff84c",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                    "0x82FE030AE76Dd614FE053ca36b80D49879fF5082": true,
                    "0x71C65050D2B48b911F6F869fBDd6d822eE15FF6b": true,
                    "0x560fF6a296D5F84b480aEbf57CFc54E9Faec6354": true,
                    "0xD7984989E7693a19C2358BE526266A76b8A2b7BC": true,
                    "0x46006BEbd30f3864A2B2555f6D88fa3EE8BE342E": true,
                    "0x7B30A0C75a9d0bC3849f6D5BD2F6Bdc921573aa9": true,
                    "0x8423995001780bB80ABBbF24845507e21cA56061": true,
                    "0x0427d0497fd82507666c186B50188526e02F2701": true,
                    "0x4316380ad94458DF90f527b790eDBC81292846db": true,

                    "0x701f1254c5cCb95a09Ed82C6b23e19782fAa47A4": true,
                    "0x46b821064370bDaD28eaA2ECdf2629384CECF820": true,
                    "0x27A634CaF303ec10D5558e585A3dD6354d8ea389": true,
                    "0xAef0332741872259EC50A3cbA1DE82d5790D0bC0": true,
                    "0x583D5Ece5e83816689d2FbD0543f6B962474C1fd": true,
                    "0x41897De6fCa8B42C7233eB42f27756CE0E85cC9e": true,
                    "0xB7d3D8f6D8cCaF58744dF547AAdFFe0CFC48f729": true,
                    "0xcfCf1E93c2E38d2F3328cBDA6b4f22410749aF01": true,
                }
            }
        }

    },
    "brazuka": {
        Name: "brazuka",
        logoUrl: window.location.protocol + "//"+ window.location.host + "/icons/braz.png",
        Logo: <img src={window.location.protocol + "//"+ window.location.host + "/icons/braz.png"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,
        backgroundColor: "linear-gradient(to bottom right,#252831, #252831)",
        buttonsColor: "#33363f",
        modalsColor: "#00000000",
        outbuttonsColor: "#cbfe63",
        buttonsBorders: "1px solid #cbfe63",
        inputsColor: "#ffffff00",
        inputsBorders: "1px solid #ffffff20",
        Borders: "#ffffff20",
        outFont: "#fff",
        innerFont: "#fff",
        innerBFont: "#222",
        redirectTo: '/create/contract',
        Routers:
            [
                '/',
                '/create/contract',
                '/manager',
                '/pre-sales',
                '/fair-launchs',
                '/private-sales',
                '/pre-sale',
                '/fair-launch',
                '/private-sale',
                '/create/pre-sale',
                '/create/fair-launch',
                '/create/private-sale',
                '/pre-sale/edit',
                '/fair-launch/edit',
                '/private-sale/edit',
                '/lock',
                '/lock/create',
                '/swap',
                '/farms',
                '/stake',
                '/stake/create',
            ],
        menudata: [
            {
                permit: true,//Trade
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: true,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: true,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: undefined,
        info: {
            title: "Brazuka Sale",
            desc: "DESIGNED TO ANNOUNCE LAUNCHES, EXCHANGE KNOWLEDGE ABOUT THE CRYPTO MARKET ETC..",
            buyon: "https://t.me/brazukaBSC",
            name: "Brazuka Sale",
            symbol: "Brazuka Sale",
            telegram: "https://t.me/BrazukaBlockchain",
            twitter: "https://twitter.com/brazukafinance",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0x4AD3d8EbB831D5125Bae5629866a1442c11dB85B",
                    ccFees: [
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.0
                    ],

                    tokenTo50Fee: "0x116526135380E28836C6080f1997645d5A807FAE"
                }
            },
            "swap": {
                web3: {
                    Router: "0xA1a39C041221765eb52BaEA2FDBbc77aa92c0dF3",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0xa3eC2f9F6e2f1df9A66899F0E404E96854729CaC",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                    "0x82FE030AE76Dd614FE053ca36b80D49879fF5082": true,
                    "0x71C65050D2B48b911F6F869fBDd6d822eE15FF6b": true,
                    "0x560fF6a296D5F84b480aEbf57CFc54E9Faec6354": true,
                    "0xD7984989E7693a19C2358BE526266A76b8A2b7BC": true,
                    "0x46006BEbd30f3864A2B2555f6D88fa3EE8BE342E": true,
                    "0x7B30A0C75a9d0bC3849f6D5BD2F6Bdc921573aa9": true,
                    "0x8423995001780bB80ABBbF24845507e21cA56061": true,
                    "0x0427d0497fd82507666c186B50188526e02F2701": true,
                    "0x4316380ad94458DF90f527b790eDBC81292846db": true,

                    "0x701f1254c5cCb95a09Ed82C6b23e19782fAa47A4": true,
                    "0x46b821064370bDaD28eaA2ECdf2629384CECF820": true,
                    "0x27A634CaF303ec10D5558e585A3dD6354d8ea389": true,
                    "0xAef0332741872259EC50A3cbA1DE82d5790D0bC0": true,
                    "0x583D5Ece5e83816689d2FbD0543f6B962474C1fd": true,
                    "0x41897De6fCa8B42C7233eB42f27756CE0E85cC9e": true,
                    "0xB7d3D8f6D8cCaF58744dF547AAdFFe0CFC48f729": true,
                    "0xcfCf1E93c2E38d2F3328cBDA6b4f22410749aF01": true,
                }
            }
        }

    },
    "dodolabs":{
        Name: "dodolabs",
        logoUrl: window.location.protocol + "//"+ window.location.host + "/icons/dodo.jpg",
        Logo: <img src={window.location.protocol + "//"+ window.location.host + "/icons/dodo.jpg"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,
        backgroundColor: "linear-gradient(to bottom right,#252831, #252831)",
        buttonsColor: "#252831",
        modalsColor: "#00000000",
        outbuttonsColor: "#ffe804",
        buttonsBorders: "1px solid #33363f",
        inputsColor: "#16181d",
        inputsBorders: "1px solid #33363f",
        Borders: "#33363f",
        outFont: "#fff",
        innerFont: "#fff",
        innerBFont: "#000",
        redirectTo: '/create/contract',
        Routers:
            [
                '/',
                '/create/contract',
                '/manager',
            ],
        menudata: [
            {
                permit: false,//Trade
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: undefined,
        info: {
            title: "DODO LABS",
            desc: "Welcome to Dodo labs",
            buyon: "",
            name: "DODO LABS",
            symbol: "DODO LABS",
            telegram: "https://t.me/Weslehv",
            twitter: "",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0x0d93bA835C467f986cA0A6557F2Ffd7cB45E4e19",
                    ccFees: [
                        0.15,
                        0.15,
                        0.15,
                        0.15,
                        0.15,
                        0.0,
                        0.15,
                        0.15
                    ],

                    tokenTo50Fee: "0x116526135380E28836C6080f1997645d5A807FAE"
                }
            },
            "swap": {
                web3: {
                    Router: "0xA1a39C041221765eb52BaEA2FDBbc77aa92c0dF3",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0xa3eC2f9F6e2f1df9A66899F0E404E96854729CaC",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                    "0x82FE030AE76Dd614FE053ca36b80D49879fF5082": true,
                    "0x71C65050D2B48b911F6F869fBDd6d822eE15FF6b": true,
                    "0x560fF6a296D5F84b480aEbf57CFc54E9Faec6354": true,
                    "0xD7984989E7693a19C2358BE526266A76b8A2b7BC": true,
                    "0x46006BEbd30f3864A2B2555f6D88fa3EE8BE342E": true,
                    "0x7B30A0C75a9d0bC3849f6D5BD2F6Bdc921573aa9": true,
                    "0x8423995001780bB80ABBbF24845507e21cA56061": true,
                    "0x0427d0497fd82507666c186B50188526e02F2701": true,
                    "0x4316380ad94458DF90f527b790eDBC81292846db": true,

                    "0x701f1254c5cCb95a09Ed82C6b23e19782fAa47A4": true,
                    "0x46b821064370bDaD28eaA2ECdf2629384CECF820": true,
                    "0x27A634CaF303ec10D5558e585A3dD6354d8ea389": true,
                    "0xAef0332741872259EC50A3cbA1DE82d5790D0bC0": true,
                    "0x583D5Ece5e83816689d2FbD0543f6B962474C1fd": true,
                    "0x41897De6fCa8B42C7233eB42f27756CE0E85cC9e": true,
                    "0xB7d3D8f6D8cCaF58744dF547AAdFFe0CFC48f729": true,
                    "0xcfCf1E93c2E38d2F3328cBDA6b4f22410749aF01": true,
                }
            }
        }

    }
}