import React, { useState, useEffect } from 'react';

const PublishNft = (props) => {
    const {
        _ct,
        ID,
        setCT,
        getimage,
        setP,
        setID,
        approve,
        sellnft,
        nextblock,
        poolfee,
        price,
      } = props.data

    return (

        <div style={{
            height: "min-content",
            width: "100%",
            display: "grid",
            gridTemplateRows: "auto",
            margin: "auto",
            marginTop: "10px",
            marginBottom: "10px",
            background: "#1e1f26",
            borderRadius: "2px",
            border: "1px solid #1e1f26",
            color: "#fff"
        }}>
            <div style={{
                height: "min-height",
                width: "100%",
                display: "grid",
                margin: "auto",
                gridTemplateColumns: "repeat(auto-fit, minmax(260px, 1fr)",
                gap: "10px",
            }}>
                <div style={{
                    height: "100%",
                    width: "100%",
                    color: "#fff",
                    margin: "auto",

                    display: "grid",
                    borderRadius: "10px",

                }}>
                    <div style={{ padding: "10px 0px", height: "100%", width: "100%", display: "grid", gridTemplateRows: "min-content" }}>
                        <div id={"image" + _ct + ID} style={{ height: "280px", width: "280px", margin: "auto", background: "#000", borderRadius: "10px" }}>
                        </div>
                        <p style={{
                            textAlign: "justify",
                            fontSize: "18px",
                            color: "#e5e5e5",
                            margin: "auto"
                        }}><br /><span id={"name" + _ct + ID} style={{
                            textAlign: "center",
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#fff"
                        }}>...
                                <br /></span>
                        </p>
                    </div>
                </div>

                <div style={{
                    height: "min-content",
                    width: "90%",
                    margin: "auto",
                    display: "grid",
                    gap: "10px"
                }}>

                    <p style={{
                        textAlign: "justify",
                        fontSize: "15px",
                        color: "#e5e5e5"
                    }}><br /><span style={{
                        textAlign: "left",
                        fontSize: "25px",
                        fontWeight: "bold",
                        color: "#fff"
                    }}>PUBLISH NFT
                            <br /></span>
                        Publish and sell the NFTs you have
                        <br />
                    </p>
                    <br />
                    <br />
                    <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto" }}>
                        <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#fff", fontSize: "12px" }}>CONTRACT</p>
                        <input onChange={(e) => { setCT(e.target.value); getimage(e.target.value, ID) }} placeholder="0x..." style={{ fontSize: "15px", outline: "none", color: "#fff", textAlign: "left", height: "35px", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", border: "1px solid #ffffff20", background: "#00000050", borderRadius: "2px", }}></input>
                    </div>
                    <div style={{ height: "min-content", width: "100%", display: "grid", gap: "5px", gridTemplateColumns: "auto auto", margin: "auto", borderRadius: "10px", cursor: "pointer" }}>
                        <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", borderRadius: "10px", cursor: "pointer" }}>
                            <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#fff", fontSize: "12px" }}>Price (USD)</p>
                            <input type={"number"} onChange={(e) => { setP(e.target.value * 10) }} min={1} placeholder="0" style={{ fontSize: "15px", outline: "none", color: "#fff", textAlign: "center", height: "35px", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", border: "1px solid #ffffff20", background: "#00000050", borderRadius: "2px", }}></input>
                        </div>
                        <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", borderRadius: "10px", cursor: "pointer" }}>
                            <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#fff", fontSize: "12px" }}>ID</p>
                            <input type={"number"} onChange={(e) => { setID(e.target.value) }} min={0} value={ID} placeholder="ID" style={{ fontSize: "15px", outline: "none", color: "#fff", textAlign: "center", height: "35px", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", border: "1px solid #ffffff20", background: "#00000050", borderRadius: "2px", }}></input>
                        </div>
                    </div>
                    <div style={{ height: "min-content", width: "100%", display: "grid", gap: "5px", gridTemplateColumns: "auto auto", margin: "auto", borderRadius: "10px", cursor: "pointer" }}>
                        <button onClick={() => { approve(_ct, ID) }} style={{
                            cursor: "pointer", display: "grid", gridTemplateColumns: "auto", height: "35px", width: "auto", border: "1px solid #ffffff20",
                            background: "#ffffff20", borderRadius: "2px", color: "#fff"
                        }}>
                            <p style={{ fontSize: "15px", textAlign: "left", height: "min-content", margin: "auto" }}><span style={{ textAlign: "left", fontWeight: "bold", fontSize: "15px" }}>1 - APPROVE</span></p>
                        </button>
                        <button onClick={() => { sellnft(price, _ct, ID) }} style={{
                            cursor: "pointer", display: "grid", gridTemplateColumns: "auto", height: "35px", width: "auto", border: "1px solid #ffffff20",
                            background: "#ffffff20", borderRadius: "2px", color: "#fff"
                        }}>
                            <p style={{ fontSize: "15px", textAlign: "left", height: "min-content", margin: "auto" }}><span style={{ textAlign: "left", fontWeight: "bold", fontSize: "15px" }}>2 - PUBLISH</span></p>
                        </button>
                    </div>

                    <p style={{
                        textAlign: "justify",
                        fontSize: "15px",
                        color: "#e5e5e5"
                    }}>
                        FEE {nextblock(poolfee, 18)} (BNB)
                    </p>
                </div>

            </div>
        </div>
    );


}
export default PublishNft