import React, { useState, useEffect } from 'react';
import '../farm.css';
import Devconsole from './devconsole';
import list from "./audit.json";
import { Base64 } from 'js-base64';
import bnbabi from "../../abis/bnbabi.json";
const Web3 = require('web3-eth');
//let web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org'));
//let uri = []
const Audits = () => {
  const [uri, seturi] = useState([]);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    //document.title = "PROJECTS"
  }, []);
  async function getthefuckabi(cts, a) {
    if (a != "0x000") {
      if (uri == 0) {
        setTimeout(fetch('http://api.bscscan.com/api?module=contract&action=getabi&address=' + cts + '&format=raw')
          .then(x => x.json())
          .then(jsonb64 => {
            const string = JSON.stringify(jsonb64) // convert Object to a String
            const encodedString = Base64.encode(string);
            seturi(encodedString.toString())
            //localStorage.setItem('tokenabi', uri)

            //window.open(a)

          }), 2000)
      }
    }

  }
  useEffect(() => {
    let interval = null;
    if (uri == 0) {
    //  getthefuckabi(checkif(window.location.pathname), window.location.pathname)
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1)
      }, 4000);
    }


    return () => clearInterval(interval);
  }, [seconds]);
  function nextblock(accountBalancemctTB, d) {
    let a = accountBalancemctTB
    if (accountBalancemctTB.toString().length >= d) {
      return a.toString().slice(0, accountBalancemctTB.toString().length - d).length != 0
        ? a.toString().slice(0, accountBalancemctTB.toString().length - d) + "." + (a.toString().slice(accountBalancemctTB.toString().length - d, 2))
        : 0 + "." + (a.toString().slice(accountBalancemctTB.toString().length - d, d / 2))
    } else {
      return (
        '0.' +
        '0'.repeat(d - accountBalancemctTB.toString().length) +
        accountBalancemctTB.toString().slice(0, 2)
      );
    }
  }
  function checkif(url) {
    return contains(list, "url", url)
  }
  function contains(arr, key, val) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][key] === val) return arr[i]["ct"];
    }
    return "0x000";
  }
  function containss(arr, key, val) {
    return [arr[key]];
  }
  function containsa(arr, key, val, keyreturn) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][key] === val) return arr[i][keyreturn];
    }
    return "";
  }
  const SAFE = (props) => {

    let load = props.result
   
    return (
      <p onClick={() => {
        if (props.what == "Contract:") { window.open('https://bscscan.com/address/' + checkif(window.location.pathname) + '#code') }
        if (props.what == "Site:") { window.open(props.result) }
      }} style={{ width: "100%", display: "grid", gridTemplateColumns: "auto auto" }}><span style={{
        textAlign: "left",
        width: "100%",
        fontWeight: "bold",
        color: "#e5e5e5",
        fontSize: "12px"
      }}>{props.what} </span><span style={{
        textAlign: "right",
        width: "100%",
        fontSize: "13px",
        color: props.resultc,
        cursor: props.result.toString().includes("0x") || props.what == "Site:" ? "pointer" : "auto"
      }}>
          {
            load
          }
        </span></p>
    );
  }
  const SAFES = (props) => {
    function color(e) {
      if (e == "0") {
        return "#FF0000"
      } else {
        if (e == "1") {
          return "#17fc03"
        } else {
          if (e == "2") {
            return "#FFFF00"
          } else {

          }
        }
      }
    }
    return (
      <p style={{ width: "100%", display: "grid", gridTemplateColumns: "auto auto" }}><span style={{
        textAlign: "left",
        width: "100%",
        fontSize: "12px",
        color: "#e5e5e5",
      }}>{props.what.toUpperCase()}</span><span style={{
        textAlign: "right",
        width: "100%",
        fontSize: "12px",
        color: color(containss(props.result, props.what, "")[0][0]),
        fontWeight: "bold",
      }}>{containss(props.result, props.what, "")[0][0] == 0 && "REPROVED"}{containss(props.result, props.what, "")[0][0] == 1 && "PASSED"}{containss(props.result, props.what, "")[0][0] == 2 && "WARNING"}</span></p>

    );
  }
  const SAFESS = (props) => {
    function color(e) {
      if (e == "0") {
        return "#FF0000"
      } else {
        if (e == "1") {
          return "#17fc03"
        } else {
          if (e == "2") {
            return "#FFFF00"
          } else {

          }
        }
      }
    }
    return (
      <div style={{
        height: "min-content",
        minHeight: "150px",
        width: "100%",
        display: "grid",
        padding: "1vh",
        margin: "auto",
        border: "1px solid #26323850",
        background: "#26323840",
        color: "#fff",
        borderRadius: "10px",
        fontSize: "12px",
      }}>

        <p style={{ width: "100%", display: "grid", gridTemplateColumns: "auto" }}><span style={{
          textAlign: "left",
          width: "100%",
          fontSize: "18px",
          fontWeight: "bold",
          color: "#e5e5e5",
        }}>{props.what.toUpperCase()}</span></p>
        <br />

        <p style={{ width: "100%", display: "grid", gridTemplateColumns: "auto auto" }}><span style={{
          textAlign: "left",
          width: "100%",
          color: "#e5e5e5",
        }}>Status</span><span style={{
          textAlign: "right",
          width: "100%",
          color: color(containss(props.result, props.what, "")[0][0]),
          fontWeight: "bold",
        }}>{containss(props.result, props.what, "")[0][0] == 0 && "REPROVED"}{containss(props.result, props.what, "")[0][0] == 1 && "PASSED"}{containss(props.result, props.what, "")[0][0] == 2 && "WARNING"}</span></p>
        <br />
        <p style={{ width: "100%", display: "grid", gridTemplateColumns: "auto auto" }}><span style={{
          textAlign: "left",
          width: "100%",
          color: "#e5e5e5",
        }}>Why?<br /></span></p>
        <br />
        <p />
        <p style={{ width: "100%", display: "grid", gridTemplateColumns: "auto auto" }}><span style={{
          textAlign: "left",
          width: "100%",
          color: "#e5e5e5",
        }}>{containss(props.result, props.what, "")[0][1]}</span></p>
        <br />
      </div>


    );
  }
  function colors(e) {
    if (e < 5) {
      return "#FF0000"
    } else {
      if (e == 5) {
        return "#ffffff80"
      } else {
        if (e > 5) {
          return "#17fc03"
        }
      }
    }
  }
  function colorsk(e) {
    if (e == 0) {
      return "#FF0000"
    } else {
      if (e == 1) {
        return "#17fc0320"
      }
    }
  }
  const MODA = (props) => {
    return (
      <div style={{
        height: "min-content",
        minHeight: "70px",
        width: "100%",
        display: "grid",
        gridTemplateRows: "auto",

        margin: "auto",
        marginTop: "15px",
        border: "1px solid #ffffff10",
        background: "#ffffff10",
        marginBottom: "15px",
        color: "#fff",
        borderRadius: "2px",
      }}>
        <div style={{
          height: "min-height",
          width: "100%",
          display: "grid",
          margin: "auto",
          gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr)",
          gap: "10px",
        }}>

          <div style={{
            height: "min-content",
            width: "100%",
            display: "grid",
            margin: "auto"
          }}>

            <div style={{
              height: "100px",
              width: "100px",
              color: "#fff",
              margin: "auto",
              background: "url('" + props.logo + " ')",
              backgroundSize: "100px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              display: "grid",
              borderRadius: "360px",

            }}>

            </div>
            <p style={{
              textAlign: "center",
              fontSize: "15px",
              color: "#e5e5e5"
            }}><br /><span style={{
              textAlign: "center",
              fontSize: "15px",
              fontWeight: "bold",
              color: "#fff"
            }}>{props.name}
                <br /></span>
            </p>
          </div>
          <div style={{
            height: "min-content",
            width: "100%",
            display: "grid",
            gap: "5px"
          }}>

            <div style={{
              height: "min-content",
              width: "100%",
              margin: "auto",
              display: "grid",
              gridTemplateColumns: "auto auto",
              gap: "5px",
              fontSize: "15px",

            }}>
              <button style={{
                display: "grid", gridTemplateColumns: "auto", height: "45px", width: "auto", background: "#17fc0320", borderRadius: "2px", border: "1px solid #17fc0380", color: "#fff"
              }}>
                <p style={{ textAlign: "left", height: "min-content", margin: "auto" }}><span style={{ textAlign: "left", fontWeight: "bold" }}>AUDIT</span></p>
              </button>
              <button style={{
                display: "grid", gridTemplateColumns: "auto", height: "45px", width: "auto", background: colorsk(props.kyc), borderRadius: "2px", border: "1px solid " + colorsk(props.kyc).toString().slice(0, colorsk(props.kyc).toString().length - 2), color: "#fff"
              }}>
                <p style={{ textAlign: "left", height: "min-content", margin: "auto" }}><span style={{ textAlign: "left", fontWeight: "bold" }}>KYC</span></p>
              </button>

            </div>
            <button style={{
              display: "grid", gridTemplateColumns: "auto", height: "min-content", width: "100%", background: "#17fc0302", borderRadius: "2px", border: "1px solid" + colors(props.score), color: "#fff"
            }}>
              <p style={{
                textAlign: "left",
                width: "100%",
                padding: "1vh",
                fontSize: "15px",
                margin: "auto",
                color: "#e5e5e5"
              }}>SCORE:<p /><span style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold",
                color: colors(props.score)
              }}> {props.score} / 10
                </span>
                <p />
                {props.score < 5 && "BAD"}{props.score == 5 && "OK"}{props.score > 5 && "GOOD"}
              </p>
            </button>
            <button onClick={() => { window.open(props.url) }} style={{
              cursor: "pointer", display: "grid", gridTemplateColumns: "auto", height: "50px", width: "auto", background: "#ffffff00", borderRadius: "2px", border: "1px solid #ffffff80", color: "#fff"
            }}>
              <p style={{ fontSize: "15px", textAlign: "left", height: "min-content", margin: "auto" }}><span style={{ textAlign: "left", fontWeight: "bold", fontSize: "15px" }}>FULL ANALITICS</span></p>
            </button>
          </div>

        </div>
      </div>

    );
  }

  let titlessss = <div style={{
    height: "min-content",
    minHeight: "240px",
    width: "100%",
    display: "grid",
    background: "#00000010",
    borderRadius: "2px",
    margin: "auto",
    color: "#fff"
  }}>

    {(window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 1]).toUpperCase() == "PROJECTS" && <div style={{
      height: "100%",
      width: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)",

      gap: "10px",
    }}>

      <div style={{
        height: "min-content",
        width: "100%",
        padding: "1vh",
        display: "grid",
        margin: "auto",
        gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr)",

        gap: "10px",
      }}>
        {list.map((e) => <MODA url={e.url} logo={e.logo} name={e.nome} desc={e.desc} score={e.score} kyc={e.result.KYC[0]} />)}
      </div>


    </div>}
    {(window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 1]).toUpperCase() != "PROJECTS" && <div style={{
      height: "min-content",
      width: "100%",
      display: "grid",
      gap: "10px",
    }}>
      <div style={{
        height: "min-content",
        width: "90%",
        display: "grid",
        margin: "auto",
        gridTemplateColumns: "repeat(auto-fit, minmax(140px, 1fr)",
        gap: "10px",
        borderBottom: "1px solid #26323850",


      }}>
        <div style={{
          height: "70px",
          width: "100%",
          display: "grid",
          margin: "auto",
          gridTemplateColumns: "70px auto",
          gap: "10px",
        }}>
          <div style={{
            height: "50px",
            width: "50px",
            color: "#fff",
            margin: "auto",
            background: "url('" + containsa(list, "url", window.location.pathname, "logo") + " ')",
            backgroundSize: "50px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            display: "grid",
            borderRadius: "360px",
            border: "1px solid #fff",
          }}>

          </div>
          <p style={{
            textAlign: "left",
            fontSize: "12px",
            color: "#e5e5e5",
            margin: "auto 0"
          }}><span style={{
            textAlign: "left",
            margin: "auto",
            fontSize: "18px",
            fontWeight: "bold",
            overflowX: "hidden",
            color: "#fff"
          }}>{containsa(list, "url", window.location.pathname, "Symbol")}
              <br /></span>
            {containsa(list, "url", window.location.pathname, "nome")}
          </p>
        </div>


        <div style={{
          height: "50px",
          width: "auto",
          margin: "auto",
          marginRight: "0px",
          display: "grid",
          gridTemplateColumns: "min-content min-content",
          gap: "5px",
          fontSize: "20px",
        }}>
          <button style={{
            display: "grid", padding: "1vh", gridTemplateColumns: "auto", height: "50px", width: "auto", background: "#17fc0320", borderRadius: "10px", border: "1px solid #17fc0380", color: "#fff"
          }}>
            <p style={{ textAlign: "left", height: "min-content", margin: "auto" }}><span style={{ textAlign: "left", fontWeight: "bold" }}>AUDIT</span></p>
          </button>
          <button style={{
            display: "grid", padding: "1vh", gridTemplateColumns: "auto", height: "50px", width: "auto", background: colorsk(containsa(list, "url", window.location.pathname, "result").KYC[0]), borderRadius: "10px", border: "1px solid" + colorsk(containsa(list, "url", window.location.pathname, "result").KYC[0]).slice(0, colorsk(containsa(list, "url", window.location.pathname, "result").KYC[0]).toString().length - 2), color: "#fff"
          }}>
            <p style={{ textAlign: "left", height: "min-content", margin: "auto" }}><span style={{ textAlign: "left", fontWeight: "bold" }}>KYC</span></p>
          </button>

        </div>



      </div>
      <div style={{
        height: "380px", width: "90%", overflow: "auto", margin: "auto", overflow: "hidden", borderRadius: "10px", padding: "0.5vh", border: "1px solid #26323850",
        background: "#263238",
        color: "#fff",
        borderRadius: "10px",
      }}>
        <iframe frameBorder="0" height="100%" width="100%" src={"https://coinbrain.com/embed/bnb-" + checkif(window.location.pathname) + "?theme=custom&padding=2&background=263238&chart=1&trades=0&simple=0"}></iframe>
      </div>
      <div style={{
        height: "min-content",
        minHeight: "380px",
        width: "100%",
        padding: "1vh",
        display: "grid",
        margin: "auto",
        gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)",

        gap: "10px",
      }}>
        <div style={{
          height: "min-content",
          minHeight: "380px",
          width: "280px",
          display: "grid",
          padding: "1vh",
          margin: "auto",
          border: "1px solid #26323850",
          background: "#26323840",
          color: "#fff",
          borderRadius: "10px",
          fontSize: "16px",

        }}>
          <p style={{
            textAlign: "left",
            width: "100%",
            fontSize: "20px",
            color: "#fff",
            fontWeight: "bold",
          }}>Audit Score</p>
          <br />
          <button style={{
            display: "grid", gridTemplateColumns: "auto", height: "50px", width: "auto", background: "#17fc0302", borderRadius: "10px", border: "1px solid" + colors(containsa(list, "url", window.location.pathname, "score")), color: "#fff"
          }}>
            <p style={{
              textAlign: "left",
              width: "auto",
              padding: "1vh",
              fontSize: "14px",
              margin: "auto",
              color: "#e5e5e5"
            }}>SCORE: <span style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "bold",
              color: "#fff"
            }}> {containsa(list, "url", window.location.pathname, "score")} / 10
              </span>
              <span style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold",
                color: colors(containsa(list, "url", window.location.pathname, "score")),

              }}>  {containsa(list, "url", window.location.pathname, "score") < 5 && "BAD"}{containsa(list, "url", window.location.pathname, "score") == 5 && "OK"}{containsa(list, "url", window.location.pathname, "score") > 5 && "GOOD"}
              </span>

            </p>
          </button>
          <br />
          <SAFES what={"KYC"} result={containsa(list, "url", window.location.pathname, "result")} />
          <SAFES what={"Owner safe privileges"} result={containsa(list, "url", window.location.pathname, "result")} />
          <SAFES what={"No Supply Manipulation"} result={containsa(list, "url", window.location.pathname, "result")} />
          <SAFES what={"Contract clear code"} result={containsa(list, "url", window.location.pathname, "result")} />
          <SAFES what={"Gas consumption"} result={containsa(list, "url", window.location.pathname, "result")} />
          <SAFES what={"Trade rate can't change above 25%"} result={containsa(list, "url", window.location.pathname, "result")} />
          <SAFES what={"No bug critical"} result={containsa(list, "url", window.location.pathname, "result")} />
          <SAFES what={"No restrict functions"} result={containsa(list, "url", window.location.pathname, "result")} />
          <SAFES what={"Lock Liquidify"} result={containsa(list, "url", window.location.pathname, "result")} />
          <SAFES what={"Descentralized"} result={containsa(list, "url", window.location.pathname, "result")} />

        </div>

        <div style={{
          height: "min-content",
          minHeight: "380px",
          width: "280px",
          display: "grid",
          padding: "1vh",
          margin: "auto",
          border: "1px solid #26323850",
          background: "#26323840",
          color: "#fff",
          borderRadius: "10px",
          fontSize: "16px",

        }}>
          <p style={{
            textAlign: "left",
            width: "100%",
            fontSize: "20px",
            color: "#fff",
            fontWeight: "bold",
          }}>{containsa(list, "url", window.location.pathname, "nome")}</p>
          <br />
          <p style={{
            textAlign: "justify",
            width: "100%",
            height: "70px",
            overflow: "auto",
            color: "#e5e5e5",
            fontSize: "15px"
          }}>{containsa(list, "url", window.location.pathname, "desc")}</p>
          <br />
          <SAFE what={"Creation date:"} result={containsa(list, "url", window.location.pathname, "birth")} />
          <SAFE what={"Contract:"} result={checkif(window.location.pathname).slice(0, 4) + '...' + checkif(window.location.pathname).slice(-4)} />
          <SAFE what={"Site:"} result={containsa(list, "url", window.location.pathname, "site")} />
          {false && <SAFE what={"Social:"} result={""} />}
          <SAFE what={"Total Supply:"} result={containsa(list, "url", window.location.pathname, "Supply")} />
          <SAFE what={"Circulation Supply:"} result={containsa(list, "url", window.location.pathname, "CSupply")} />
          <SAFE what={"Decimals"} result={containsa(list, "url", window.location.pathname, "decimal")} />


        </div>
        <div style={{
          height: "min-content",
          width: "280px",
          display: "grid",

          margin: "0 auto",

          color: "#fff",
          borderRadius: "10px",
          fontSize: "16px",
          gap: "10px"
        }}>
          <div style={{
            height: "min-content",
            width: "280px",
            display: "grid",
            padding: "1vh",
            margin: "0 auto",
            border: "1px solid #26323850",
            background: "#26323840",
            color: "#fff",
            borderRadius: "10px",
            fontSize: "15px",
          }}>
            <p style={{
              textAlign: "left",
              width: "100%",
              fontSize: "20px",
              color: "#fff",
              fontWeight: "bold",
            }}>AUDIT INFO</p>
            <br />

            <SAFE what={"Audit Result:"} result={containsa(list, "url", window.location.pathname, "aresult")} />
            <SAFE what={"Audit Date:"} result={containsa(list, "url", window.location.pathname, "adate")} />
            <SAFE what={"Audit Number:"} result={containsa(list, "url", window.location.pathname, "anumber")} />
            <SAFE what={"Team"} result={"MARTIK"} />
            <SAFE what={"Test tools"} result={"Remix IDE"} />

          </div>
          <div style={{
            height: "min-content",
            width: "280px",
            display: "grid",
            padding: "1vh",
            margin: "0 auto",
            border: "1px solid #26323850",
            background: "#26323840",
            color: "#fff",
            borderRadius: "10px",
            fontSize: "15px",

          }}>
            <p style={{
              textAlign: "left",
              width: "100%",
              fontSize: "20px",
              color: "#fff",
              fontWeight: "bold",
            }}>DISCLAIMER</p>
            <br />
            <p style={{
              textAlign: "justify",
              width: "100%",
              fontSize: "13px",
              color: "#e5e5e5",

            }}>
              THIS IS NOT AN INVESTMENT RECOMMENDATION! ALSO DO YOUR ANALYSIS AND INVEST ONLY IN YOUR RESPONSIBILITY.
              AT MARTIK WE TAKE CARE OF OUR ANALYSIS SO THAT THEY ARE THE CLOSEST TO THE RESULT, BUT WE DON'T HAVE THE TOKENS THAT ARE HERE AND WE CANNOT CONTROL THE MARKET, SO ANY DECISION MADE FROM OUR ANALYSIS DOES NOT GUARANTEE RETURN, HAVE RECOGNITION THAT IT WILL BE YOURS RESPONSIBILITY.
              THANK YOU FOR UNDERSTANDING.
            </p>

          </div>
        </div>
      </div>
      <div style={{
        height: "min-content",
        width: "100%",
        minHeight: "360px",
        display: "grid",
        margin: "auto",
        gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)",
        padding: "2vh",
        gap: "10px",
      }}>

        <SAFESS what={"KYC"} result={containsa(list, "url", window.location.pathname, "result")} />
        <SAFESS what={"Owner safe privileges"} result={containsa(list, "url", window.location.pathname, "result")} />
        <SAFESS what={"No Supply Manipulation"} result={containsa(list, "url", window.location.pathname, "result")} />
        <SAFESS what={"Contract clear code"} result={containsa(list, "url", window.location.pathname, "result")} />
        <SAFESS what={"Gas consumption"} result={containsa(list, "url", window.location.pathname, "result")} />
        <SAFESS what={"Trade rate can't change above 25%"} result={containsa(list, "url", window.location.pathname, "result")} />
        <SAFESS what={"No bug critical"} result={containsa(list, "url", window.location.pathname, "result")} />
        <SAFESS what={"No restrict functions"} result={containsa(list, "url", window.location.pathname, "result")} />
        <SAFESS what={"Lock Liquidify"} result={containsa(list, "url", window.location.pathname, "result")} />
        <SAFESS what={"Descentralized"} result={containsa(list, "url", window.location.pathname, "result")} />
        {false && <div style={{
          height: "min-content",

          width: "100%",
          display: "grid",
          padding: "1vh",
          margin: "auto",
          border: "1px solid #26323850",
          background: "#26323840",
          color: "#fff",
          borderRadius: "10px",
          fontSize: "20px",

        }}>
          <p style={{
            textAlign: "left",
            width: "100%",
            fontSize: "18px",
            color: "#fff",
            fontWeight: "bold",
          }}>FUNCTION BY FUNCTION</p>
          <br />
          {uri.length != 0 && <Devconsole ct={checkif(window.location.pathname)} abi={uri} ctrc={containsa(list, "url", window.location.pathname, "abicriticals")} ctrw={containsa(list, "url", window.location.pathname, "abiwarnings")} />}
        </div>}
      </div>

    </div>}

  </div>
  //    <MyCodeComponent code={"jsx"} language={"jsx"} showLineNumbers={true}/>
  return (
    <div className="farms">

      {titlessss}

    </div>
  );
}

export default Audits;