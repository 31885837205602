import React, { useState, useEffect, useContext } from "react";
import { ethers } from 'ethers';
import abiToken from "../../abis/bnbabi.json";
import nftsabi from "../../abis/nftsn.json";
import nftfacabi from "../../abis/nftfactory.json";
import Modal from '../stake/components/modal';
import ModalH from '../stake/components/modalH';
import AppContext from "../../framework/helpers/AppContext";
import ProjectData from '../../config';
let projectName = "Martik"
const {
    Name,
    Logo,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,
    innerFont,
    Routers
} = ProjectData[window.location.host.split('.')[0] != undefined ? window.location.host.split('.')[0] : "Martik"]
const Web3 = require('web3-eth');
const Minimized = (props) => {

    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = useContext(AppContext)

    const [address, setAddress] = useState(account);

    const [list, setList] = useState([]);
    const [disable_list, setDisable_list] = useState([{}]);
    const [list_mode, setListMode] = useState(0);
    const [active_mode, setactiveMode] = useState(0);
    const [only_staked_mode, setOnly_Staked_Mode] = useState(false);
    const [filter, setFilter] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [poofee, setpoofee] = useState(0);

    const [filterlay, setFilterLay] = useState('none');
    useEffect(() => {
        loadlist()
    }, []);
    useEffect(() => {
        loadlist()
    }, [props.data]);
    function nextpercent(accountBalancemctTB, d) {
        return parseFloat((accountBalancemctTB) + "").toFixed(2)
    }
    function nextblock(accountBalancemctTB, d) {
        if (accountBalancemctTB != undefined && accountBalancemctTB != 0) {
            if (accountBalancemctTB.toString().length >= d) {
                return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(5)
            } else {
                let cutin = (d - accountBalancemctTB.toString().length) + 2
                return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(cutin)
            }
        } else {
            return 0.00
        }
    }
    const excludes = {

    }
    async function loadlist() {
        try {
           /* if (localStorage.getItem("mtk-stkcache") != undefined) {
                setList(JSON.parse(localStorage.getItem("mtk-stkcache")))
            }*/

            const stakefactory = await Tokencontract(nftfacabi, '0x32E2f0895C009cEA29798E0201e0069d02Db6A14')
            const poofee = await stakefactory.methods.pfee().call()
            setpoofee(poofee)
            const _list = await stakefactory.methods.poolsview().call()
     
            const _t_list = [
               
            ]
         
            for (let index = 0; index < _list.length; index++) {
                const stake = await Tokencontract(nftsabi, _list[index])

                const token0 = await stake['methods']['NFT']().call()
                const token1 = await stake['methods']['BEP20Rtoken']().call()

                const i0 = await stake['methods']['_icon']().call()
                const i1 = await stake['methods']['_iconb']().call()
                const tokenA = await Tokencontract(abiToken, token0);
                const tokenB = await Tokencontract(abiToken, token1);

                try {
                    const obj = {
                        title: '',
                        cts: _list[index],
                        apr: await stake.methods._FLEXAPR().call(),
                        ts: await stake.methods._totalstaked().call(),
                        min: await stake.methods.minStakeAmount().call(),
                        ia: i0,
                        i1: i1,
                        act: token0,
                        bct: token1,
                        taName: await tokenA['methods']['symbol']().call(),
                        tbName: await tokenB['methods']['symbol']().call(),
                        so: address != undefined ? (await stake.methods.userInfo(address).call()).amount > 0 : false,
                        desc: "",
                        apydesc: "",
                        poofee: poofee,
                        official: 1,
                        online: true
                    }
                    _t_list.push(obj)

                } catch (error) {
                    const obj = {
                        title: '',
                        cts: _list[index],
                        apr: await stake.methods._FLEXAPR().call(),
                        ts: await stake.methods._totalstaked().call(),
                        ia: i0,
                        i1: i1,
                        act: token0,
                        bct: token1,
                        taName: await tokenA['methods']['symbol']().call(),
                        tbName: await tokenB['methods']['symbol']().call(),
                        min: 0,
                        so: address != undefined ? (await stake.methods.userInfo(address).call()).amount > 0 : false,
                        desc: "",
                        apydesc: "",
                        poofee: poofee,
                        official: 1,
                        online: true
                    }
                    _t_list.push(obj)
                }

            }
           
            // localStorage.setItem("mtk-stkcache", JSON.stringify(_t_list))
            setList(_t_list)
        } catch (error) {
           console.log(error)
        }

    }

    let stakeHorizontal = <div style={{ width: "100%", margin: "auto", display: "grid", padding: "2vh" }}>
        <div style={{
            height: "min-content",
            width: "100%",
            display: "grid",
            background: "transparent",

            borderRadius: "10px",
            marginTop: "auto",
            color: outFont,
            gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)",
            gap: "20px",
        }}>

            {
                list.map((e, index) =>

                    <div style={{ height: "280px", width: "250px", cursor: "pointer", }}>
                        <div style={{
                            height: "230px",
                            width: "230px",
                            margin: "auto",
                            borderRadius: "10px",
                            background: "url('" + e.ia + " ') center center / 100% no-repeat",
                        }}>

                        </div>
                        <div onClick={() => {
                            props.setModal(undefined)
                            setTimeout(() => {
                                props.setModal(e)
                            }, 1000);

                        }} style={{ display: "grid", gridTemplateColumns: " 40px auto 40px", width: "230px", margin: "auto", }}>
                            <div style={{
                                height: "35px",
                                width: "35px",
                                margin: "auto",
                                borderRadius: "90px",
                                background: "url('" + e.i1 + " ')  center center / 35px no-repeat",
                            }}>

                            </div>
                            <p style={{ margin: "auto 0", fontSize: "11px" }}>
                                Stake {e.taName}
                                <p /> to Earn {e.tbName}
                            </p>
                            <p style={{ fontSize: "10px", margin: "auto" }}>
                                APR %
                                <p style={{ fontSize: "15px", margin: "auto" }}>
                                    {parseFloat(e.apr).toFixed(2)}
                                </p>
                            </p>
                        </div>

                    </div>

                )
            }

        </div>

        <p style={{ height: "10vh" }}></p>
    </div>

    return (
        <>
            {list.length > 0 && stakeHorizontal}
        </>
    );
}

export default Minimized;