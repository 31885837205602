import React, { useState, useEffect, useContext } from 'react';
import { ethers } from 'ethers';
import bnbabi from "../../../abis/bnbabi.json";
import Numberinput from "../../../components/Launchpad/numberInput";
import SAFE from "../../../components/Launchpad/textpar";
import InputBlock from "../../../components/Launchpad/InputBlock";
import DescBlock from "../../../components/Launchpad/descBlock";
import Button from "../../../components/Launchpad/Button";
import Check from "../../../components/Launchpad/Checkbox";
import Checklist from "../../../components/Launchpad/ListCheck";
import StepBar from "../../../components/Launchpad/Stepbar";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import enUS from 'date-fns/locale/en-US';
import "react-datepicker/dist/react-datepicker.css";

import AppContext from "../../../framework/helpers/AppContext";
import env from '../../../hooks/data/Config';
import { Tokens } from './tokens.js'
import { useNavigate } from 'react-router-dom';
import { sendTransaction, prepareSendTransaction } from '@wagmi/core'
import { parseEther } from 'viem'
import ProjectData from '../../../config';
import { func } from 'prop-types';
let projectName = "Martik"
const {
    Name,
    Logo,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    modalsColor,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,
    innerFont,
    innerBFont,
    menudata,
    Routers,
    logoUrl,
    info,
    screens
} = ProjectData[window.location.host.split('.')[0] != undefined ? window.location.host.split('.')[0] : "Martik"]
const Web3 = require('web3-eth');
registerLocale('en-US', enUS)
const Create = (props) => {
    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = useContext(AppContext)

    const [address, setaddress] = useState(account);
    const [token, setToken] = useState(0);
    const [tokenBname, setTokenBname] = useState("");
    const [tokenBsymbol, setTokenBsymbol] = useState("");
    const [tokenBdec, setTokenBdec] = useState(0);
    const [tokenBtotal, setTokenBtotalSupply] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [tokens, settokens] = useState(undefined);
    //standart
    const [_ecoFee_B, set_ecoFee_B] = useState(0);
    const [_burnFee_B, set_burnFee_B] = useState(0);
    const [_ecoFee_S, set_ecoFee_S] = useState(0);
    const [_burnFee_S, set_burnFee_S] = useState(0);
    //dividend
    const [rewardtoken, setrewardtoken] = useState('');
    const [_liquidityFee, set_liquidityFee] = useState(0);
    const [_reflectionFee, set_reflectionFee] = useState(0);
    const [_DevFee, set_DevFee] = useState(0);
    const [_burnFee, set_burnFee] = useState(0);
    const [_marketingFee, set_marketingFee] = useState(0);
    const [_sellLiquidityFee, set_sellLiquidityFee] = useState(0);
    const [_sellReflectionFee, set_sellReflectionFee] = useState(0);
    const [_sellDevFee, set_sellDevFee] = useState(0);
    const [_sellBurnFee, set_sellBurnFee] = useState(0);
    const [_sellMarketingFee, set_sellMarketingFee] = useState(0);
    //reflection
    const [_treflectionFee, set_treflectionFee] = useState(0);
    const [_selltReflectionFee, set_selltReflectionFee] = useState(0);
    useEffect(() => {
        //document.title = "Token Creator | Martik"
    }, []);
    useEffect(() => {

        getTokens()

    }, [])
    const getTokens = async () => {

        settokens(await Tokens())

    }
    function returnbignumber(value, dec) {
        let a = ['']
        let e = value + ""
        if (e.toString().includes(",")) {
            a = e.toString().split(",")
            e = ethers.utils.parseUnits(a[0] + "." + a[1], dec).toString()
        } else {
            e = ethers.utils.parseUnits(e, dec).toString()
        }
        return e
    }
    function numberformat(values) {
        let a = []
        for (let index = 0; index < values.length; index++) {
            a.push((values[index] * 100).toFixed(0))
        }
        return a
    }
    const navigate = useNavigate();

    function getFee() {
        const isRewardToken = (token == 2 || token == 4)
        const isUsingToken = rewardtoken == screens["createToken"].web3.tokenTo50Fee
        const fee = (isRewardToken && isUsingToken) ? (parseFloat(screens["createToken"].web3.ccFees[token]) / 2).toString() : (screens["createToken"].web3.ccFees[token]).toString()
        return fee;
    }
    async function getBNB(value) {
        const isRewardToken = (token == 2 || token == 4)
        const isUsingToken = rewardtoken == screens["createToken"].web3.tokenTo50Fee
        const fee = (isRewardToken && isUsingToken) ? (parseFloat(screens["createToken"].web3.ccFees[token]) / 2).toString() : (screens["createToken"].web3.ccFees[token]).toString()
        const config = await prepareSendTransaction({
            to: screens["createToken"].web3.receiver,
            value: parseEther((fee).toString())
        })

        await sendTransaction(config)
    }
    async function create() {


        if (token == 0) {
            try {
                await getBNB('0.10')
                nSendCT(tokens[getID()][token].bytecode, tokens[getID()][token].abi, '0', tokenBname, tokenBsymbol, tokenBdec, tokenBtotal
                ).then(a => {
                    //a.hash

                    navigate("/manager/?ca=" + a.address + "&type=" + token)

                }).catch(e => {
                    //console.log(e)
                })
            } catch (error) {

            }
        } else {
            if (token == 1) {
                try {
                    await getBNB('0.10')
                    nSendCT(tokens[getID()][token].bytecode, tokens[getID()][token].abi, '0', tokenBname, tokenBsymbol, tokenBdec, tokenBtotal,

                        numberformat([_ecoFee_B, _burnFee_B, _ecoFee_S, _burnFee_S])

                    ).then(a => {
                        navigate("/manager/?ca=" + a.address + "&type=" + token)
                    }).catch(e => {
                        //console.log(e)
                    })
                } catch (error) {

                }

            } else {
                if (token == 2) {
                    try {
                        if ((rewardtoken).toUpperCase() == ("0x116526135380E28836C6080f1997645d5A807FAE").toUpperCase()) {
                            await getBNB('0.05')
                        } else {
                            await getBNB('0.10')
                        }

                        nSendCT(tokens[getID()][token].bytecode, tokens[getID()][token].abi, '0', tokenBname, tokenBsymbol, tokenBdec, tokenBtotal, rewardtoken,

                            numberformat([
                                _liquidityFee,
                                _reflectionFee,
                                _DevFee,
                                _burnFee,
                                _marketingFee,
                                _sellLiquidityFee,
                                _sellReflectionFee,
                                _sellDevFee,
                                _sellBurnFee,
                                _sellMarketingFee
                            ])

                        ).then(a => {
                            navigate("/manager/?ca=" + a.address + "&type=" + token)
                        }).catch(e => {
                            //console.log(e)
                        })
                    } catch (error) {

                    }

                } else {
                    if (token == 3) {

                        try {
                            await getBNB('0.10')
                            nSendCT(tokens[getID()][token].bytecode, tokens[getID()][token].abi, '0', tokenBname, tokenBsymbol, tokenBdec, tokenBtotal,
                                numberformat([
                                    _reflectionFee,
                                    _marketingFee,
                                    _burnFee,
                                    _sellReflectionFee,
                                    _sellMarketingFee,
                                    _sellBurnFee
                                ])
                            ).then(a => {
                                navigate("/manager/?ca=" + a.address + "&type=" + token)
                            }).catch(e => {
                                //console.log(e)
                            })
                        } catch (error) {

                        }

                    } else {
                        if (token == 4) {
                            try {
                                if ((rewardtoken).toUpperCase() == ("0x116526135380E28836C6080f1997645d5A807FAE").toUpperCase()) {
                                    await getBNB('0.05')
                                } else {
                                    await getBNB('0.10')
                                }
                                nSendCT(tokens[getID()][token].bytecode, tokens[getID()][token].abi, '0', tokenBname, tokenBsymbol, tokenBdec, tokenBtotal, rewardtoken,
                                    numberformat([
                                        _reflectionFee,
                                        _marketingFee,
                                        _burnFee,
                                        _treflectionFee,
                                        _sellReflectionFee,
                                        _sellMarketingFee,
                                        _sellBurnFee,
                                        _selltReflectionFee,
                                    ])
                                ).then(a => {
                                    navigate("/manager/?ca=" + a.address + "&type=" + token)
                                }).catch(e => {
                                    //console.log(e)
                                })
                            } catch (error) {

                            }

                        } else {
                            if (token == 5) {
                                nSendCT(tokens[getID()][token].bytecode, tokens[getID()][token].abi, '0', tokenBname, tokenBsymbol, tokenBdec, tokenBtotal,

                                    numberformat([_ecoFee_B, _burnFee_B, _ecoFee_S, _burnFee_S])

                                ).then(a => {
                                    navigate("/manager/?ca=" + a.address + "&type=" + token)
                                }).catch(e => {
                                    //console.log(e)
                                })
                            } else {
                                if (token == 6) {
                                    nSendCT(tokens[getID()][token].bytecode, tokens[getID()][token].abi, '0', tokenBname, tokenBsymbol, tokenBdec, tokenBtotal,

                                        numberformat([_ecoFee_B, _burnFee_B, _ecoFee_S, _burnFee_S])

                                    ).then(a => {
                                        navigate("/manager/?ca=" + a.address + "&type=" + token)
                                    }).catch(e => {
                                        //console.log(e)
                                    })
                                } else {
                                    if (token == 7) {
                                        nSendCT(tokens[getID()][token].bytecode, tokens[getID()][token].abi, '0', tokenBname, tokenBsymbol, tokenBdec, tokenBtotal,

                                            numberformat([_ecoFee_B, _burnFee_B, _ecoFee_S, _burnFee_S])

                                        ).then(a => {
                                            navigate("/manager/?ca=" + a.address + "&type=" + token)
                                        }).catch(e => {
                                            //console.log(e)
                                        })
                                    } else {

                                    }
                                }
                            }
                        }

                    }
                }
            }
        }

    }

    const opssData = {
        "martik": [
            'Simples (without fees)',
            'Standart (with fees, buy: tokens transfer, sell: bnb transfer)',
            'Dividend (with fees, buy: tokens transfer, sell: bnb transfer, external token reflection)',
            'Reflection (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, Token reflection)',
            'Combo (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, external token reflection, Token reflection)',
            'Ultra (with fees, buy: tokens transfer, sell: Martik transfer)',

        ],
        "xshiba": [
            'Simples (without fees)',
            'Standart (with fees, buy: tokens transfer, sell: bnb transfer)',
            'Dividend (with fees, buy: tokens transfer, sell: bnb transfer, external token reflection)',
            'Reflection (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, Token reflection)',
            'Combo (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, external token reflection, Token reflection)',
            'Ultra (with fees, buy: tokens transfer, sell: Martik transfer)',

        ],
        "sbk": [
            'Simples (without fees)',
            'Standart (with fees, buy: tokens transfer, sell: bnb transfer)',
            'Dividend (with fees, buy: tokens transfer, sell: bnb transfer, external token reflection)',
            'Reflection (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, Token reflection)',
            'Combo (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, external token reflection, Token reflection)',


        ],
        "xtiger": [
            'Simples (without fees)',
            'Standart (with fees, buy: tokens transfer, sell: bnb transfer)',
            'Dividend (with fees, buy: tokens transfer, sell: bnb transfer, external token reflection)',
            'Reflection (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, Token reflection)',
            'Combo (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, external token reflection, Token reflection)',
            'Ultra (with fees, buy: tokens transfer, sell: Martik transfer)',
        ],
        "jijiswap": [
            'Simples (without fees)',
            'Standart (with fees, buy: tokens transfer, sell: bnb transfer)',
            'Dividend (with fees, buy: tokens transfer, sell: bnb transfer, external token reflection)',
            'Reflection (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, Token reflection)',
            'Combo (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, external token reflection, Token reflection)',
            'Ultra (with fees, buy: tokens transfer, sell: Martik transfer)',

        ],
        "casinosworld": [
            'Simples (without fees)',
            'Standart (with fees, buy: tokens transfer, sell: bnb transfer)',
            'Dividend (with fees, buy: tokens transfer, sell: bnb transfer, external token reflection)',
            'Reflection (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, Token reflection)',
            'Combo (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, external token reflection, Token reflection)',
            'Ultra (with fees, buy: tokens transfer, sell: CW transfer)',

        ],
        "brazuka": [
            'Simples (without fees)',
            'Standart (with fees, buy: tokens transfer, sell: bnb transfer)',
            'Dividend (with fees, buy: tokens transfer, sell: bnb transfer, external token reflection)',
            'Reflection (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, Token reflection)',
            'Combo (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, external token reflection, Token reflection)',
            'Ultra (with fees, buy: tokens transfer, sell: Martik transfer)',

        ],
        "dodolabs": [
            'Simples (without fees)',
            'Trading Fees (with fees, buy: tokens transfer, sell: bnb transfer)',
        ],
    }
    const opss = opssData[window.location.host.split('.')[0] != undefined ? window.location.host.split('.')[0] : "Martik"]
    /*   {token == 2 && <InputBlock title={"Buy liquidity Fee  %"} place={"0"} variavel={_liquidityFee} setvariavel={set_liquidityFee} desc={""} />}
                    {token == 2 && <InputBlock title={"Buy reflection Fee  %"} place={"0"} variavel={_reflectionFee} setvariavel={set_reflectionFee} desc={""} />}
                    {token == 2 && <InputBlock title={"Buy Dev Fee  %"} place={"0"} variavel={_DevFee} setvariavel={set_DevFee} desc={""} />}
                    {token == 2 && <InputBlock title={"Buy Burn Fee  %"} place={"0"} variavel={_burnFee} setvariavel={set_burnFee} desc={""} />}
                    {token == 2 && <InputBlock title={"Buy Marketing Fee  %"} place={"0"} variavel={_marketingFee} setvariavel={set_marketingFee} desc={""} />}
                    {token == 2 && <InputBlock title={"Sell liquidity Fee  %"} place={"0"} variavel={_sellLiquidityFee} setvariavel={set_sellLiquidityFee} desc={""} />}
                    {token == 2 && <InputBlock title={"Sell reflection Fee  %"} place={"0"} variavel={_sellReflectionFee} setvariavel={set_sellReflectionFee} desc={""} />}
                    {token == 2 && <InputBlock title={"Sell Dev Fee  %"} place={"0"} variavel={_sellDevFee} setvariavel={set_sellDevFee} desc={""} />}
                    {token == 2 && <InputBlock title={"Sell Burn Fee  %"} place={"0"} variavel={_sellBurnFee} setvariavel={set_sellBurnFee} desc={""} />}
                    {token == 2 && <InputBlock title={"Sell Marketing Fee  %"} place={"0"} variavel={_sellMarketingFee} setvariavel={set_sellMarketingFee} desc={""} />}
     
                    {token == 1 && <InputBlock title={"Buy Ecosystem Fee  %"} place={"0"} variavel={_ecoFee_B} setvariavel={set_ecoFee_B} desc={""} />}
                    {token == 1 && <InputBlock title={"Buy Burn Fee  %"} place={"0"} variavel={_burnFee_B} setvariavel={set_burnFee_B} desc={""} />}
                    {token == 1 && <InputBlock title={"Sell Ecosystem Fee  %"} place={"0"} variavel={_ecoFee_S} setvariavel={set_ecoFee_S} desc={""} />}
                    {token == 1 && <InputBlock title={"Sell Burn Fee  %"} place={"0"} variavel={_burnFee_S} setvariavel={set_burnFee_S} desc={""} />}
     
                    */
    const imageContainerStyle = {
        height: "100%",
        width: "100%",
        color: "#fff",
        margin: "auto ",
        background: "url(" + logoUrl + ")",
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",

        borderRadius: "360px",
    };
    return (
        <div style={{
            height: "min-content",
            minHeight: "720px",
            width: "100%",

            maxWidth: "1920px",
            display: "grid",
            color: "#fff",
            borderRadius: "2px",
            margin: "auto",
            padding: window.innerWidth < 720 ? "0px" : "0px 20px"
        }}>

            <div style={{
                height: "100%",
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)",
                gap: "10px",

            }}>
                <div style={{ height: "100%", padding: "20px 10px", width: "100%", maxWidth: "1024px", borderRadius: "10px", margin: "50px auto" }}>
                    <p style={{
                        textAlign: "center",
                        fontSize: "40px",
                        margin: "auto",
                        width: "100%",
                        height: "40px",
                        color: "#550882"
                    }}>

                        <span style={{ color: "#fff" }}>CREATE TOKEN</span>


                    </p>

                    <div style={imageContainerStyle}></div>
                </div>

                <div style={{ height: "min-content", padding: "20px 10px", width: "100%", maxWidth: "1024px", border: "1px solid " + Borders, background: modalsColor, borderRadius: "10px", margin: "50px auto" }}>
                    <div style={{ display: "grid", gap: "10px" }}>
                        <Checklist title={"Token Type *"} untitle={opss[token]} ops={opss} token={token} setvariavel={(e) => {
                           setToken(
                                e.includes('Simples') ? 0 :
                                    e.includes('Standart') ? 1 :
                                        e.includes('Dividend') ? 2 :
                                            e.includes('Reflection') ? 3 :
                                                e.includes('Combo') ? 4 :
                                                    e.includes('Ultra') && !e.includes('CW') ? 5 :
                                                        e.includes('Trading Fees') ? 6 :
                                                            e.includes('CW') ? 7 : 0)

                        }} />

                        <InputBlock title={"Name *"} place={""} variavel={tokenBname} setvariavel={setTokenBname} desc={""} />
                        <InputBlock title={"Symbol*"} place={""} variavel={tokenBsymbol} setvariavel={setTokenBsymbol} desc={""} />
                        <InputBlock title={"Decimals*"} place={""} variavel={tokenBdec} setvariavel={setTokenBdec} desc={""} />
                        <InputBlock title={"Total supply*"} place={""} variavel={tokenBtotal} setvariavel={setTokenBtotalSupply} desc={""} />


                        {(token == 2 || token == 4) && <InputBlock title={"Reward Token"} place={"0x"} variavel={rewardtoken} setvariavel={setrewardtoken} desc={""} />}
                        {(token == 2 || token == 4) && <Check title={"USE " + Name + " (-50% FEE TO DEPLOY)"} id={0} variavel={rewardtoken == screens["createToken"].web3.tokenTo50Fee ? 0 : 1} setvariavel={() => { setrewardtoken(screens["createToken"].web3.tokenTo50Fee) }} />}

                        <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr)", gap: "5px" }}>
                            {token == 1 && <InputBlock title={"Buy Ecosystem Fee  %"} place={"0"} variavel={_ecoFee_B} setvariavel={set_ecoFee_B} desc={""} />}
                            {token == 1 && <InputBlock title={"Buy Burn Fee  %"} place={"0"} variavel={_burnFee_B} setvariavel={set_burnFee_B} desc={""} />}
                            {token == 1 && <InputBlock title={"Sell Ecosystem Fee  %"} place={"0"} variavel={_ecoFee_S} setvariavel={set_ecoFee_S} desc={""} />}
                            {token == 1 && <InputBlock title={"Sell Burn Fee  %"} place={"0"} variavel={_burnFee_S} setvariavel={set_burnFee_S} desc={""} />}

                            {token == 2 && <InputBlock title={"Buy liquidity Fee  %"} place={"0"} variavel={_liquidityFee} setvariavel={set_liquidityFee} desc={""} />}
                            {token == 2 && <InputBlock title={"Buy reflection Fee  %"} place={"0"} variavel={_reflectionFee} setvariavel={set_reflectionFee} desc={""} />}
                            {token == 2 && <InputBlock title={"Buy Dev Fee  %"} place={"0"} variavel={_DevFee} setvariavel={set_DevFee} desc={""} />}
                            {token == 2 && <InputBlock title={"Buy Burn Fee  %"} place={"0"} variavel={_burnFee} setvariavel={set_burnFee} desc={""} />}
                            {token == 2 && <InputBlock title={"Buy Marketing Fee  %"} place={"0"} variavel={_marketingFee} setvariavel={set_marketingFee} desc={""} />}
                            {token == 2 && <InputBlock title={"Sell liquidity Fee  %"} place={"0"} variavel={_sellLiquidityFee} setvariavel={set_sellLiquidityFee} desc={""} />}
                            {token == 2 && <InputBlock title={"Sell reflection Fee  %"} place={"0"} variavel={_sellReflectionFee} setvariavel={set_sellReflectionFee} desc={""} />}
                            {token == 2 && <InputBlock title={"Sell Dev Fee  %"} place={"0"} variavel={_sellDevFee} setvariavel={set_sellDevFee} desc={""} />}
                            {token == 2 && <InputBlock title={"Sell Burn Fee  %"} place={"0"} variavel={_sellBurnFee} setvariavel={set_sellBurnFee} desc={""} />}
                            {token == 2 && <InputBlock title={"Sell Marketing Fee  %"} place={"0"} variavel={_sellMarketingFee} setvariavel={set_sellMarketingFee} desc={""} />}


                            {token == 3 && <InputBlock title={"Buy reflection Fee  %"} place={"0"} variavel={_reflectionFee} setvariavel={set_reflectionFee} desc={""} />}
                            {token == 3 && <InputBlock title={"Buy Marketing Fee  %"} place={"0"} variavel={_marketingFee} setvariavel={set_marketingFee} desc={""} />}
                            {token == 3 && <InputBlock title={"Buy Burn Fee  %"} place={"0"} variavel={_burnFee} setvariavel={set_burnFee} desc={""} />}
                            {token == 3 && <InputBlock title={"Sell reflection Fee  %"} place={"0"} variavel={_sellReflectionFee} setvariavel={set_sellReflectionFee} desc={""} />}
                            {token == 3 && <InputBlock title={"Sell Marketing Fee  %"} place={"0"} variavel={_sellMarketingFee} setvariavel={set_sellMarketingFee} desc={""} />}
                            {token == 3 && <InputBlock title={"Sell Burn Fee  %"} place={"0"} variavel={_sellBurnFee} setvariavel={set_sellBurnFee} desc={""} />}

                            {token == 4 && <InputBlock title={"Buy reflection Fee  %"} place={"0"} variavel={_reflectionFee} setvariavel={set_reflectionFee} desc={"Internal token reflection"} />}
                            {token == 4 && <InputBlock title={"Buy Marketing Fee  %"} place={"0"} variavel={_marketingFee} setvariavel={set_marketingFee} desc={""} />}
                            {token == 4 && <InputBlock title={"Buy Burn Fee  %"} place={"0"} variavel={_burnFee} setvariavel={set_burnFee} desc={""} />}
                            {token == 4 && <InputBlock title={"Buy token reflection Fee  %"} place={"0"} variavel={_treflectionFee} setvariavel={set_treflectionFee} desc={"External token reflection"} />}
                            {token == 4 && <InputBlock title={"Sell reflection Fee  %"} place={"0"} variavel={_sellReflectionFee} setvariavel={set_sellReflectionFee} desc={"Internal token reflection"} />}
                            {token == 4 && <InputBlock title={"Sell Marketing Fee  %"} place={"0"} variavel={_sellMarketingFee} setvariavel={set_sellMarketingFee} desc={""} />}
                            {token == 4 && <InputBlock title={"Sell Burn Fee  %"} place={"0"} variavel={_sellBurnFee} setvariavel={set_sellBurnFee} desc={""} />}
                            {token == 4 && <InputBlock title={"Sell token reflection Fee  %"} place={"0"} variavel={_selltReflectionFee} setvariavel={set_selltReflectionFee} desc={"External token reflection"} />}


                            {token == 5 && <InputBlock title={"Buy Ecosystem Fee  %"} place={"0"} variavel={_ecoFee_B} setvariavel={set_ecoFee_B} desc={""} />}
                            {token == 5 && <InputBlock title={"Buy Burn Fee  %"} place={"0"} variavel={_burnFee_B} setvariavel={set_burnFee_B} desc={""} />}
                            {token == 5 && <InputBlock title={"Sell Ecosystem Fee  %"} place={"0"} variavel={_ecoFee_S} setvariavel={set_ecoFee_S} desc={""} />}
                            {token == 5 && <InputBlock title={"Sell Burn Fee  %"} place={"0"} variavel={_burnFee_S} setvariavel={set_burnFee_S} desc={""} />}

                            {token == 6 && <InputBlock title={"Buy Ecosystem Fee  %"} place={"0"} variavel={_ecoFee_B} setvariavel={set_ecoFee_B} desc={""} />}
                            {token == 6 && <InputBlock title={"Buy Burn Fee  %"} place={"0"} variavel={_burnFee_B} setvariavel={set_burnFee_B} desc={""} />}
                            {token == 6 && <InputBlock title={"Sell Ecosystem Fee  %"} place={"0"} variavel={_ecoFee_S} setvariavel={set_ecoFee_S} desc={""} />}
                            {token == 6 && <InputBlock title={"Sell Burn Fee  %"} place={"0"} variavel={_burnFee_S} setvariavel={set_burnFee_S} desc={""} />}

                            {token == 7 && <InputBlock title={"Buy Ecosystem Fee  %"} place={"0"} variavel={_ecoFee_B} setvariavel={set_ecoFee_B} desc={""} />}
                            {token == 7 && <InputBlock title={"Buy Burn Fee  %"} place={"0"} variavel={_burnFee_B} setvariavel={set_burnFee_B} desc={""} />}
                            {token == 7 && <InputBlock title={"Sell Ecosystem Fee  %"} place={"0"} variavel={_ecoFee_S} setvariavel={set_ecoFee_S} desc={""} />}
                            {token == 7 && <InputBlock title={"Sell Burn Fee  %"} place={"0"} variavel={_burnFee_S} setvariavel={set_burnFee_S} desc={""} />}
                        </div>



                        <div className='flex items-center justify-center gap-4 mb-6'>
                            <Button func={() => {
                                create()
                            }} text={'CREATE'} next={true} />

                        </div>
                        {"SERVICE FEE: " + getFee() + " BNB"}
                    </div>

                </div>


            </div>
        </div>



    );


}
export default Create;