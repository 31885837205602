import React, { useState, useEffect, useContext } from 'react';

import ProjectData from '../../config';

let projectName = "Martik"
const {
  Name,
  Logo,
  backgroundColor,
  buttonsColor,
  buttonsBorders,
  inputsColor,
  inputsBorders,
  outbuttonsColor,
  Borders,
  outFont,
  innerFont,
  innerBFont,
  menudata,
  Routers,
  logoUrl,
  info,
  screens
} = ProjectData[window.location.host.split('.')[0] != undefined ? window.location.host.split('.')[0] : "Martik"]

function ModalTokens(data) {
    const {
        choicecoin,
        setHint,
        setAmountinSwap,
        setAmountoutSwap,
        setiTA,
        isa,
        iTA,
        iTB,
        setiTB,
        listsearch,
        ctfind,
        GLOBAL,
        hubinteract,
        search
    } = data.data

    const tokens_default = screens.swap.web3.Tokens
    function Token_lis(props) {
        return <li onClick={(e) => {
            choicecoin(props.brand, props.ct)
            setHint(false)
            setAmountinSwap(0)
            setAmountoutSwap(0)
            setiTA(isa ? props.icon : iTA)
            setiTB(!isa ? props.icon : iTB)
            // setiTA("https://r.poocoin.app/smartchain/assets/" + ethers.utils.getAddress(tokenACT) + "/logo.png")
            //setiTB("https://r.poocoin.app/smartchain/assets/" + ethers.utils.getAddress(tokenBCT) + "/logo.png")

        }} style={{ cursor: "pointer", height: '40px', width: "340px", display: "grid", gridTemplateColumns: "50px auto ", margin: "auto", marginTop: "10px" }}>
            <img style={{ height: '30px', width: "30px", margin: "auto" }} src={props.icon}></img>
            <h1 style={{ width: "100%", height: "min-content", margin: "auto", textAlign: "left", fontSize: "14px" }} >{props.brand}</h1>
        </li>;
    }

    function Tokens_Find(props) {
        return <li onClick={(e) => {
            choicecoin(props.brands, props.ct)
            setHint(false)
            setAmountinSwap(0)
            setAmountoutSwap(0)
            setiTA(isa ? props.icon : iTA)
            setiTB(!isa ? props.icon : iTB)
            /* var obj = {
               "nome": props.brands,
               "bname": props.brand,
               "icon": props.icon,
               "contract": props.ct,
             };*/

        }} style={{ cursor: "pointer", height: '40px', width: "100%", display: "grid", gridTemplateColumns: "50px auto ", margin: "auto", borderBottom: "1px solid #ffffff20", marginTop: "10px" }}>
            <img style={{ height: '30px', width: "30px", margin: "auto" }} src={props.icon}></img>
            <h1 style={{ width: "100%", height: "min-content", margin: "auto", textAlign: "left", fontSize: "14px" }}>{props.brand}</h1>
        </li>;
    }
    function getIcon(contract) {
        for (let index = 0; index < tokens_default[GLOBAL['netid']].length; index++) {
          if (tokens_default[GLOBAL['netid']][index].contract == contract) {
            return tokens_default[GLOBAL['netid']][index].icon
            break;
          } else {
            if (index == tokens_default[GLOBAL['netid']].length - 1) {
              return "https://bscscan.com/images/main/empty-token.png"
              break;
            }
          }
        }
      }
    let ulconfig = listsearch != null
        ? <ul id='list' style={{ overflow: "auto", height: "80%" }}>
            {<Tokens_Find brand={listsearch.name} brands={listsearch.symbol} icon={getIcon(ctfind)} ct={ctfind} />}
            {tokens_default[GLOBAL['netid']].map((Token_li) => <Token_lis brand={Token_li.nome} icon={Token_li.icon} ct={Token_li.contract} />)}
        </ul>
        : <ul id='list' style={{ overflow: "auto", height: "80%" }}>
            {tokens_default[GLOBAL['netid']].map((Token_li) => <Token_lis brand={Token_li.nome} icon={Token_li.icon} ct={Token_li.contract} />)}
        </ul>

    return (
        <div style={{ height: "100%", width: "100%", position: "fixed", display: "grid", background: "#00000050", top: "0", left: "0", zIndex: "4000" }}>
            <div style={{ height: '480px', width: "360px", background: "#222", borderRadius: "10px", zIndex: "10000", margin: "auto" }}>
                <div style={{ height: '40px', width: "340px", display: "grid", gridTemplateColumns: "auto 50px", margin: "auto" }}>
                    <h1 style={{ width: "100%", height: "min-content", margin: "auto", textAlign: "left", fontSize: "14px" }}>{isa ? "FROM" : "TO"}</h1>
                    <svg onClick={() => { setHint(!hubinteract) }} xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer", margin: "auto" }} width="25" height="25" fill="#fff" className="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
                {<input style={{ height: '40px', width: "340px", display: "grid", gridTemplateColumns: "auto 50px", margin: "auto", background: "#00000000", color: "#ddd", border: "1px solid #dddddd20", borderRadius: "10px", paddingLeft: "10px" }} placeholder="Enter token Address ex: 0x..." onChange={(e) => { search(e.target.value) }} />}
                {ulconfig}
            </div>
        </div>
    )
}
export default ModalTokens