import React, { useState, useEffect, useContext } from 'react';
import { Contract, ethers } from 'ethers';
import 'balloon-css';
import { useNavigate } from "react-router-dom";
import { toChecksumAddress } from 'ethereum-checksum-address';
import mtkswap from '../../abis/martikswap.json'
import Chart from './chart';
import TokenNomics from '../../components/Launchpad/TokenNomics';
import AppContext from '../../framework/helpers/AppContext';
import env from '../../hooks/data/Config';
import CheckStake from './checkStakePools';
import profiles from './profileTokens.json'
import ProjectData from '../../config';

let projectName = "Martik"
const {
    Name,
    Logo,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,
    innerFont,
    innerBFont,
    menudata,
    Routers,
    logoUrl,
    info,
    screens
} = ProjectData[window.location.host.split('.')[0] != undefined ? window.location.host.split('.')[0] : "Martik"]
const Web3 = require('web3-eth');
const TokenInfo = (props) => {

    const {
        iTB,
        usdprices,
        tokenBname,
        tokenAname,
        profile,
        totalSupplyB,
        nextblock,
        decimalsTB,
        tokenBCT,
        account,
        tokenomics,
        search,
        listsearch,
        Tokens_Find,
        getIcon,
        ctfind,
        priceStyle
    } = props.data
    useEffect(() => {

    }, [tokenBCT])
    return (
        <div style={{
            height: "min-content",
            width: "100%",
            gap: "5px",
            display: "grid",
            margin: "0 auto",
            borderRadius: "10px",
            color: outFont,
            fontSize: "12px",

        }}>


            <div style={{ borderRadius: "10px", height: "100px", width: "100%", display: "grid", gridTemplateColumns: "100px auto" }}>
                <i style={{
                    height: "100%",
                    width: "100%",
                    margin: "auto",
                    background: "url('" + iTB + " ')  center center / 50% no-repeat",
                }}>
                </i>
                <p style={{ margin: "auto", textAlign: "left", fontSize: "20px", color: "#28a745", width: "100%", fontWeight: "bold" }}>
                    {priceStyle()}
                    <p />
                    <span style={{ fontSize: "15px", color: outFont, fontWeight: "bold" }}>{tokenBname}/{tokenAname}</span>
                    <p />
                    $ {usdprices.token}
                </p>
            </div>
            <div style={{ borderRadius: "10px", gap: "10px", height: "min-content", width: "100%", display: "grid", paddingTop: "10px", paddingLeft: "10px" }}>

                <div style={{ height: "30px", width: "100%", margin: "auto" }}>
                    <p style={{ textAlign: "left", fontSize: "12px" }}>
                        <span style={{ fontSize: "13px", color: outFont, fontWeight: "bold" }}>{"Total Supply"}</span>
                        <p />
                        {nextblock(totalSupplyB, decimalsTB, decimalsTB / 2)} {tokenBname}
                    </p>
                </div>
                <div style={{ height: "30px", width: "100%", margin: "auto" }}>
                    <p style={{ textAlign: "left", fontSize: "12px" }}>
                        <span style={{ fontSize: "13px", color: outFont, fontWeight: "bold" }}>{"MarketCap (excludes burned)"}</span>
                        <p />
                        {((nextblock(totalSupplyB, decimalsTB, decimalsTB / 2) - nextblock(usdprices.dead, decimalsTB, decimalsTB / 2)) * usdprices.token).toFixed(2)} {"USD"}
                    </p>
                </div>
                <div style={{ height: "30px", width: "100%", margin: "auto" }}>
                    <p style={{ textAlign: "left", fontSize: "12px" }}>
                        <span style={{ fontSize: "13px", color: outFont, fontWeight: "bold" }}>{"Total liquidify"}</span>
                        <p />
                        {nextblock(usdprices.lpBNB, 18, 2)} {"BNB"} - {(parseFloat(usdprices.lpBNBprice)).toFixed(2)} {"USD"}
                    </p>
                </div>
                {false && <div style={{
                    height: "min-content",
                    width: "100%",
                    margin: "auto",
                    display: "flex",
                    gap: "10px",
                    fontSize: "15px",

                }}>{
                        profiles[tokenBCT] != undefined ?

                            <div style={{ display: "grid" }}>
                                <div style={{ display: "flex", gap: "10px" }}>
                                    {<a href={profile['Website']} rel="nofollow noreferrer" target="_blank" >
                                        <svg
                                            stroke="#fff" fill="none" viewBox="0 0 24 24"
                                            height="1em" style={{ fill: "#ffffff00", height: "23px", width: "23px" }} xmlns="http://www.w3.org/2000/svg">
                                            <circle cx={12} cy={12} r={10} />
                                            <line x1={2} y1={12} x2={22} y2={12} />
                                            <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z" />
                                        </svg>
                                    </a>}
                                    {<a href={profile['Facebook']} rel="nofollow noreferrer" target="_blank" >
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 24 24"
                                            height="1em" style={{ fill: "#fff", height: "23px", width: "23px" }} xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path d="M13 19.938A8.001 8.001 0 0 0 12 4a8 8 0 0 0-1 15.938V14H9v-2h2v-1.654c0-1.337.14-1.822.4-2.311A2.726 2.726 0 0 1 12.536 6.9c.382-.205.857-.328 1.687-.381.329-.021.755.005 1.278.08v1.9H15c-.917 0-1.296.043-1.522.164a.727.727 0 0 0-.314.314c-.12.226-.164.45-.164 1.368V12h2.5l-.5 2h-2v5.938zM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z" />
                                            </g>
                                        </svg>
                                    </a>}
                                    {<a href={profile['Twitter']} rel="nofollow noreferrer" target="_blank" >
                                        <svg stroke="#222" fill="none" viewBox="0 0 24 24"
                                            height="1em" style={{ fill: "#fff", height: "23px", width: "23px" }} xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
                                        </svg>
                                    </a>}
                                    {<a href={profile['Github']} rel="nofollow noreferrer" target="_blank" >
                                        <svg stroke="#222" fill="none" viewBox="0 0 24 24"
                                            height="1em" style={{ fill: "#fff", height: "23px", width: "23px" }} xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22" />
                                        </svg>
                                    </a>}
                                    {<a href={profile['Telegram']} rel="nofollow noreferrer" target="_blank" >
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 448 512"
                                            height="1em" style={{ fill: "#fff", height: "23px", width: "23px" }} xmlns="http://www.w3.org/2000/svg">
                                            <path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z" />
                                        </svg>
                                    </a>
                                    }
                                    {<a href={profile['Instagram']} rel="nofollow noreferrer" target="_blank" >
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth={0}
                                            viewBox="0 0 1024 1024" height="1em" style={{ fill: "#fff", height: "23px", width: "23px" }} xmlns="http://www.w3.org/2000/svg">
                                            <path d="M512 306.9c-113.5 0-205.1 91.6-205.1 205.1S398.5 717.1 512 717.1 717.1 625.5 717.1 512 625.5 306.9 512 306.9zm0 338.4c-73.4 0-133.3-59.9-133.3-133.3S438.6 378.7 512 378.7 645.3 438.6 645.3 512 585.4 645.3 512 645.3zm213.5-394.6c-26.5 0-47.9 21.4-47.9 47.9s21.4 47.9 47.9 47.9 47.9-21.3 47.9-47.9a47.84 47.84 0 0 0-47.9-47.9zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zm-88 235.8c-7.3 18.2-16.1 31.8-30.2 45.8-14.1 14.1-27.6 22.9-45.8 30.2C695.2 844.7 570.3 840 512 840c-58.3 0-183.3 4.7-235.9-16.1-18.2-7.3-31.8-16.1-45.8-30.2-14.1-14.1-22.9-27.6-30.2-45.8C179.3 695.2 184 570.3 184 512c0-58.3-4.7-183.3 16.1-235.9 7.3-18.2 16.1-31.8 30.2-45.8s27.6-22.9 45.8-30.2C328.7 179.3 453.7 184 512 184s183.3-4.7 235.9 16.1c18.2 7.3 31.8 16.1 45.8 30.2 14.1 14.1 22.9 27.6 30.2 45.8C844.7 328.7 840 453.7 840 512c0 58.3 4.7 183.2-16.2 235.8z" />
                                        </svg>
                                    </a>}
                                    {<a href={profile['Discord']} rel="nofollow noreferrer" target="_blank" >
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 448 512"
                                            height="1em" style={{ fill: "#fff", height: "23px", width: "23px" }} xmlns="http://www.w3.org/2000/svg">
                                            <path d="M297.216 243.2c0 15.616-11.52 28.416-26.112 28.416-14.336 0-26.112-12.8-26.112-28.416s11.52-28.416 26.112-28.416c14.592 0 26.112 12.8 26.112 28.416zm-119.552-28.416c-14.592 0-26.112 12.8-26.112 28.416s11.776 28.416 26.112 28.416c14.592 0 26.112-12.8 26.112-28.416.256-15.616-11.52-28.416-26.112-28.416zM448 52.736V512c-64.494-56.994-43.868-38.128-118.784-107.776l13.568 47.36H52.48C23.552 451.584 0 428.032 0 398.848V52.736C0 23.552 23.552 0 52.48 0h343.04C424.448 0 448 23.552 448 52.736zm-72.96 242.688c0-82.432-36.864-149.248-36.864-149.248-36.864-27.648-71.936-26.88-71.936-26.88l-3.584 4.096c43.52 13.312 63.744 32.512 63.744 32.512-60.811-33.329-132.244-33.335-191.232-7.424-9.472 4.352-15.104 7.424-15.104 7.424s21.248-20.224 67.328-33.536l-2.56-3.072s-35.072-.768-71.936 26.88c0 0-36.864 66.816-36.864 149.248 0 0 21.504 37.12 78.08 38.912 0 0 9.472-11.52 17.152-21.248-32.512-9.728-44.8-30.208-44.8-30.208 3.766 2.636 9.976 6.053 10.496 6.4 43.21 24.198 104.588 32.126 159.744 8.96 8.96-3.328 18.944-8.192 29.44-15.104 0 0-12.8 20.992-46.336 30.464 7.68 9.728 16.896 20.736 16.896 20.736 56.576-1.792 78.336-38.912 78.336-38.912z" />
                                        </svg>
                                    </a>}
                                    {<a href={profile['Reddit']} rel="nofollow noreferrer" target="_blank" >
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth={0}
                                            viewBox="0 0 1024 1024" height="1em" style={{ fill: "#fff", height: "23px", width: "23px" }} xmlns="http://www.w3.org/2000/svg">
                                            <path d="M288 568a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm338.7 119.7c-23.1 18.2-68.9 37.8-114.7 37.8s-91.6-19.6-114.7-37.8c-14.4-11.3-35.3-8.9-46.7 5.5s-8.9 35.3 5.5 46.7C396.3 771.6 457.5 792 512 792s115.7-20.4 155.9-52.1a33.25 33.25 0 1 0-41.2-52.2zM960 456c0-61.9-50.1-112-112-112-42.1 0-78.7 23.2-97.9 57.6-57.6-31.5-127.7-51.8-204.1-56.5L612.9 195l127.9 36.9c11.5 32.6 42.6 56.1 79.2 56.1 46.4 0 84-37.6 84-84s-37.6-84-84-84c-32 0-59.8 17.9-74 44.2L603.5 123a33.2 33.2 0 0 0-39.6 18.4l-90.8 203.9c-74.5 5.2-142.9 25.4-199.2 56.2A111.94 111.94 0 0 0 176 344c-61.9 0-112 50.1-112 112 0 45.8 27.5 85.1 66.8 102.5-7.1 21-10.8 43-10.8 65.5 0 154.6 175.5 280 392 280s392-125.4 392-280c0-22.6-3.8-44.5-10.8-65.5C932.5 541.1 960 501.8 960 456zM820 172.5a31.5 31.5 0 1 1 0 63 31.5 31.5 0 0 1 0-63zM120 456c0-30.9 25.1-56 56-56a56 56 0 0 1 50.6 32.1c-29.3 22.2-53.5 47.8-71.5 75.9a56.23 56.23 0 0 1-35.1-52zm392 381.5c-179.8 0-325.5-95.6-325.5-213.5S332.2 410.5 512 410.5 837.5 506.1 837.5 624 691.8 837.5 512 837.5zM868.8 508c-17.9-28.1-42.2-53.7-71.5-75.9 9-18.9 28.3-32.1 50.6-32.1 30.9 0 56 25.1 56 56 .1 23.5-14.5 43.7-35.1 52zM624 568a56 56 0 1 0 112 0 56 56 0 1 0-112 0z" />
                                        </svg>
                                    </a>}
                                </div>
                                <div onClick={() => { window.open("https://t.me/martik_en") }} style={{ display: "flex", gap: "10px", height: "30px", cursor: "pointer", width: "90%", background: "#444", borderRadius: "5px", margin: "auto" }}>
                                    <p style={{ margin: "auto" }}>    Update token data</p>

                                </div>
                            </div>
                            : <div onClick={() => { window.open("https://t.me/martik_en") }} style={{ display: "flex", gap: "10px", height: "35px", cursor: "pointer", width: "90%", background: "#444", borderRadius: "5px", margin: "auto" }}>
                                <p style={{ margin: "auto" }}>    Update token data</p>

                            </div>
                    }



                </div>}
              

            </div>
          {/*  <div style={{ border: "1px solid #ffffff10", borderRadius: "10px", gap: "10px", height: "min-content", width: "100%", display: "grid", paddingTop: "10px", paddingLeft: "10px" }}>

                <div style={{ height: "min-content", width: "100%", margin: "auto" }}>
                    <p style={{ textAlign: "left", fontSize: "12px" }}>
                        <span style={{ fontSize: "13px", color: outFont, fontWeight: "bold" }}>{"Tokenomics"}</span>
                        <p />
                        {tokenomics}
                    </p>
                    Lock tokens on Martik lock to show here
                </div>

                </div>*/}
            {<div onClick={() => { window.open("https://bscscan.com/token/" + tokenBCT) }} style={{ height: "50px", width: "100%", margin: "auto", alignItems: "left" }}>
                    <img style={{ cursor: "pointer", height: "30px", margin: "auto", }} src={'https://bscscan.com/assets/bsc/images/svg/logos/logo-bscscan-light.svg?v=23.8.2.2'} />
                </div>}


        </div>

    );
}
export default TokenInfo;