import React, { useState, useEffect, Component, useContext, useRef } from 'react';
import '../../pages/trade.css';
import '../../App.css';
import AppContext from '../../framework/helpers/AppContext';
import env from '../../hooks/data/Config';
import miniabi from "../../abis/coin.json";
import cakeabi from "../../abis/pankakeRouter.json";
import botabi from "../../abis/botbuyabi.json";
import factoryabi from "../../abis/factoryabi.json";
import Chart from './chart';
import bnbabi from "../../abis/bnbabi.json";
import Tokens from '../swap/tokens.json';
import Pairs from './pares.json';
import abipar from './pairabi.json';
import abiparv2 from './pairabiv2.json';
import { createChart, CrosshairMode } from 'lightweight-charts';
import './chartiprocol/styles.css';
import { BigNumber, ethers } from 'ethers';
import profiles from '../swap/profileTokens.json';
import { func } from 'prop-types';
import { Navigate, useNavigate } from 'react-router-dom';

const Web3 = require('web3-eth');
function Tradehub(data) {
    /*  const [amount, setamount] = useState(0);
    const [priceA, setpriceA] = useState(0);
    const [total, settotal] = useState(0);
    const [amountB, setamountB] = useState(0);
    const [priceB, setpriceB] = useState(0);
    const [totalB, settotalB] = useState(0);
    const [trades, settrades] = useState([]);

    useEffect(() => {
        if (document.getElementById('buyamount') != undefined) document.getElementById('buyamount').value = amount
        if (document.getElementById('buyprice') != undefined) document.getElementById('buyprice').value = priceA
        if (document.getElementById('buytotal') != undefined) document.getElementById('buytotal').value = total
        if (document.getElementById('sellamount') != undefined) document.getElementById('sellamount').value = amountB
        if (document.getElementById('sellprice') != undefined) document.getElementById('sellprice').value = priceB
        if (document.getElementById('selltotal') != undefined) document.getElementById('selltotal').value = totalB
    }, [totalB, total, amount, amountB, priceA, priceB])
    */
    const mds = {
        amount: document.getElementById('buyamount') != undefined && document.getElementById('buyamount').value,
        priceA: document.getElementById('buyprice') != undefined && document.getElementById('buyprice').value,
        total: document.getElementById('buytotal') != undefined && document.getElementById('buytotal').value,
        amountB: document.getElementById('sellamount') != undefined && document.getElementById('sellamount').value,
        priceB: document.getElementById('sellprice') != undefined && document.getElementById('sellprice').value,
        totalB: document.getElementById('selltotal') != undefined && document.getElementById('selltotal').value,
        setamount: (e) => { document.getElementById('buyamount').value = e },
        setpriceA: (e) => { document.getElementById('buyprice').value = e },
        settotal: (e) => { document.getElementById('buytotal').value = e },
        setamountB: (e) => { document.getElementById('sellamount').value = e },
        setpriceB: (e) => { document.getElementById('sellprice').value = e },
        settotalB: (e) => { document.getElementById('selltotal').value = e },
    }
    const {
        amount,
        priceA,
        total,
        amountB,
        priceB,
        totalB,
        setamount,
        setpriceA,
        settotal,
        setamountB,
        setpriceB,
        settotalB,
    } = mds


    const {
        pair,
        nSendTX,
        GLOBAL,
        pairdata,
        tokenBCT,
        tokenACT,
        decimalsTA,
        decimalsTB,
        tokenAname,
        tokenBname,
        balancetokenA,
        balancetokenB,
        sendex
    } = data.data;
    function Inputs(props) {
        return (
            <div style={{
                width: "90%",
                height: "35px",
                background: "#000",
                border: "1px solid #ffffff35",
                margin: "auto",
                display: "grid",
                gridTemplateColumns: "70px auto 70px"
            }}>
                <button style={{ width: "100%", background: "#fff00000", color: "#ddd", fontSize: "12px", border: "1px solid #ffffff35", }} className="token">{props.what}</button>
                <input id={props.id} style={{ width: "100%", background: "#fff00000", color: "#ddd", outline: "none", border: "none", textAlign: "right", fontSize: "12px", padding: " 0px 5px " }} type="number" onChange={(e) => {
                    props.setvariavel(e.target.value);
                }} min={0} />
                <button style={{ width: "100%", background: "#fff00000", color: "#ddd", fontSize: "12px", border: "1px solid #ffffff35", }} className="token">{props.symbol}</button>
            </div>
        )
    }
    function Boleta(props) {
        function update(func, e) {
            func(e)
        }
        return (
            <div style={{ margin: "0", width: "100%" }}>

                <div style={{
                    width: "100%",
                    height: "min-content",
                    display: "grid",
                    gap: "10px"
                }}>
                    <h3 style={{ fontSize: "12px", textAlign: "left", margin: "auto", width: "90%" }}>{props.tokenName} balance: {props.tokenBalance}</h3>
                    <Inputs id={props.ids[0]} setvariavel={(e) => {
                        //  tokenBCT, tokenACT, _price, v, dec

                        //let isBuy = _tokenBCT == tokenBCT;

                        /* let deserve = isBuy
                             ? (parseFloat(v) * (1)) / parseFloat(price)
                             : (parseFloat(v) * parseFloat(price)) / (1);
 */


                        props.Iamount(document.getElementById(props.ids[2]).value / e)


                        //  pairdata.callPrice(props.Iamount, tokenBCT, tokenACT, e, document.getElementById(props.ids[2]).value, decimalsTB, decimalsTA)
                        props.Iprice(e)

                    }} what={"PRICE"} symbol={tokenAname} />
                    <Inputs id={props.ids[1]} setvariavel={
                        (e) => {
                            //  tokenBCT, tokenACT, _price, v, dec

                            props.Itotal(e * document.getElementById(props.ids[0]).value)

                            // pairdata.callPrice(props.Itotal, tokenACT, tokenBCT, document.getElementById(props.ids[0]).value, e, decimalsTA, decimalsTB)
                            props.Iamount(e)
                        }
                    } what={"AMOUNT"} symbol={tokenBname} />



                    <div style={{
                        width: "90%",
                        height: "25px",
                        margin: "auto",
                        display: "grid",
                        gridTemplateColumns: "auto auto auto auto",
                        gap: "10px"
                    }}>
                        <div onClick={() => {
                            update(
                                props.tokenName == tokenAname ?
                                    (e) => {
                                        props.Iamount(e / document.getElementById(props.ids[0]).value)
                                        props.Itotal(e)
                                    } : (e) => {
                                        props.Itotal(e * document.getElementById(props.ids[0]).value)
                                        props.Iamount(e)
                                    },
                                (props.tokenBalance * 25) / 100)
                        }} style={{
                            width: "100%",
                            height: "25px",
                            background: "#000",
                            border: "1px solid #ffffff35",
                            margin: "auto",
                            display: "grid",
                            color: "#ddd",
                            fontSize: "12px",
                            alignItems: "center",
                            cursor: "pointer"
                        }}>
                            25%
                        </div>
                        <div onClick={() => {
                            update(props.tokenName == tokenAname ?
                                (e) => {
                                    props.Iamount(e / document.getElementById(props.ids[0]).value)
                                    props.Itotal(e)
                                } : (e) => {
                                    props.Itotal(e * document.getElementById(props.ids[0]).value)
                                    props.Iamount(e)
                                }, (props.tokenBalance * 50) / 100)
                        }} style={{
                            width: "100%",
                            height: "25px",
                            background: "#000",
                            border: "1px solid #ffffff35",
                            margin: "auto",
                            display: "grid",
                            color: "#ddd",
                            fontSize: "12px",
                            alignItems: "center",
                            cursor: "pointer"
                        }}>
                            50%
                        </div>
                        <div onClick={() => {
                            update(props.tokenName == tokenAname ?
                                (e) => {
                                    props.Iamount(e / document.getElementById(props.ids[0]).value)
                                    props.Itotal(e)
                                } : (e) => {
                                    props.Itotal(e * document.getElementById(props.ids[0]).value)
                                    props.Iamount(e)
                                }, (props.tokenBalance * 75) / 100)
                        }} style={{
                            width: "100%",
                            height: "25px",
                            background: "#000",
                            border: "1px solid #ffffff35",
                            margin: "auto",
                            display: "grid",
                            color: "#ddd",
                            fontSize: "12px",
                            alignItems: "center",
                            cursor: "pointer"
                        }}>
                            75%
                        </div>
                        <div onClick={() => {

                            update(props.tokenName == tokenAname ?
                                (e) => {
                                    props.Iamount(e / document.getElementById(props.ids[0]).value)
                                    props.Itotal(e)
                                } : (e) => {
                                    props.Itotal(e * document.getElementById(props.ids[0]).value)
                                    props.Iamount(e)
                                }, (props.tokenBalance * 100) / 100)
                        }} style={{
                            width: "100%",
                            height: "25px",
                            background: "#000",
                            border: "1px solid #ffffff35",
                            margin: "auto",
                            display: "grid",
                            color: "#ddd",
                            fontSize: "12px",
                            alignItems: "center",
                            cursor: "pointer"
                        }}>
                            100%
                        </div>
                    </div>
                    <Inputs id={props.ids[2]} setvariavel={
                        (e) => {
                            //  tokenBCT, tokenACT, _price, v, dec
                            props.Iamount(e / document.getElementById(props.ids[0]).value)
                            // pairdata.callPrice(props.Iamount, tokenBCT, tokenACT, document.getElementById(props.ids[0]).value, e, decimalsTB, decimalsTA)
                            props.Itotal(e)
                        }
                    } what={"TOTAL"} symbol={tokenAname} />

                    {props.button}
                    {props.approve}
                </div>
            </div>
        )
    }
    return (
        <div>
            <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)", borderRadius: "0px", padding: "30px 0px" }}>

                {<Boleta tokenName={tokenAname} tokenBalanceB={balancetokenB()} tokenBalance={balancetokenA()} button={
                    <div onClick={() => {
                        sendex(tokenBCT, tokenACT, document.getElementById("buytotal").value, document.getElementById("buyprice").value, decimalsTA, decimalsTA)
                    }} style={{
                        cursor: "pointer",
                        width: "90%",
                        height: "35px",
                        background: "#00ff0080",
                        margin: "auto",
                        display: "grid",
                    }}>
                        <p style={{ margin: "auto" }}>BUY</p>
                    </div>}
                    approve={
                        <>
                            {tokenAname != "BNB" ?
                                <div onClick={() => {
                                    nSendTX(tokenACT, bnbabi, GLOBAL['netid'], 'approve', '0', 0, pair, "1" + "0".repeat(50))
                                }} style={{
                                    cursor: "pointer",
                                    width: "90%",
                                    height: "20px",
                                    margin: "auto",
                                    display: "grid",
                                }}>
                                    <p style={{ margin: "auto" }}>Approve {tokenAname}</p>
                                </div> : <></>}
                        </>
                    }
                    Iamount={setamount}
                    Iprice={setpriceA}
                    Itotal={settotal}


                    ids={["buyprice", "buyamount", "buytotal"]}
                />}

                {<Boleta tokenName={tokenBname} tokenBalanceB={balancetokenB()} tokenBalance={balancetokenB()}
                    button={
                        <div onClick={() => {

                            sendex(tokenACT, tokenBCT, document.getElementById("sellamount").value, document.getElementById("sellprice").value, decimalsTB, decimalsTA)
                        }} style={{
                            cursor: "pointer",
                            width: "90%",
                            height: "35px",
                            background: "#ff000080",
                            margin: "auto",
                            display: "grid",
                        }}>
                            <p style={{ margin: "auto" }}>SELL</p>
                        </div>}
                    approve={
                        <>
                            {tokenBname != "BNB" ?
                                <div onClick={() => {
                                    nSendTX(tokenBCT, bnbabi, GLOBAL['netid'], 'approve', '0', 0, pair, "1" + "0".repeat(50))
                                }} style={{
                                    cursor: "pointer",
                                    width: "90%",
                                    height: "20px",
                                    margin: "auto",
                                    display: "grid",
                                }}>
                                    <p style={{ margin: "auto" }}>Approve {tokenBname}</p>
                                </div> : <></>}
                        </>
                    }
                    Iamount={setamountB}
                    Iprice={setpriceB}
                    Itotal={settotalB}


                    ids={["sellprice", "sellamount", "selltotal"]}
                />}
            </div>

        </div>
    )
}


const Trade = (props) => {

    const feedata = {
        "MTK": "0%",
        "INDAO": "0%",
        "BBM": "0%",
        "DGP": "10%",
        "BABYNU": "0%",
        "URPY": "0%",
        "S4E": "0%",
        "BMTK": "10%",

    }
    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT,
        publicrpc
    } = useContext(AppContext)

    const GLOBAL = {
        provider: provider,
        account: account,//wil
        netid: getID()
    };
    const tokens_default = Tokens;
    const navigate = useNavigate();
    const [hubinteract, setHint] = useState(false);
    const [settings, setSettings] = useState(false);
    const [active_mode, setactiveMode] = useState(0);
    const [cake, setCake] = useState(undefined);
    const [isa, setIsA] = useState(false);
    const [accountBNB, setBNBbalance] = useState(0);
    const [tokenAname, setTokenAname] = useState(tokens_default[GLOBAL['netid']][0].nome);
    const [tokenBname, setTokenBname] = useState(tokens_default[GLOBAL['netid']][2].nome);
    const [tokenA, setTokenA] = useState(0);
    const [tokenB, setTokenB] = useState(0);
    const [WBNB, setWBNB] = useState(tokens_default[GLOBAL['netid']][0].contract);
    const [tokenACT, setTokenACT] = useState(tokens_default[GLOBAL['netid']][0].contract);
    const [tokenBCT, setTokenBCT] = useState(tokens_default[GLOBAL['netid']][2].contract);

    const [accountBalancemTA, setAccountBalanceTA] = useState(0);
    const [accountBalancemTB, setAccountBalanceTB] = useState(0);
    const [decimalsTA, setCdecimalsTA] = useState(18);
    const [decimalsTB, setCdecimalsTB] = useState(18);
    const [iTA, setiTA] = useState(tokens_default[GLOBAL['netid']][0].icon);
    const [iTB, setiTB] = useState(tokens_default[GLOBAL['netid']][2].icon);
    const [totalSupplyA, setTotalSupplyA] = useState(0);
    const [price, setprice] = useState("");
    const [AllowA, setAllowA] = useState(0);
    const [totalSupplyB, setTotalSupplyB] = useState(0);
    const [splippage, setSlip] = useState(15);
    const [seconds, setSeconds] = useState(0);
    const [amountinswap, setAmountinSwap] = useState(0);
    const [amountoutswap, setAmountoutSwap] = useState(0);
    const [tname, settokenname] = useState("BNB");
    const [pair, setPair] = useState(undefined);

    const [listsearch, setlistsearch] = useState(null);
    const [ctfind, setctcatch] = useState(null);
    const [profile, setprofile] = useState({});
    const [values, setvalues] = useState([]);
    const [tokenomics, settokenomics] = useState(<></>);
    const [Gwei, setGWEI] = useState(5);
    const [dead, setDead] = useState(20);
    const [usdprices, setusdprices] = useState({
        token: undefined,
        lpBNB: undefined,
        lpBNBprice: undefined
    });
    const w3 = new Web3(provider);
    const [accounts, setAccounts] = useState([account]);
    const [conerror, setconerror] = useState(true);
    const [amountin, setAmountin] = useState(0);
    const [amountminout, setAmountout] = useState(0);



    const [amount, setamount] = useState(0);
    const [priceA, setpriceA] = useState(0);
    const [total, settotal] = useState(0);
    const [amountB, setamountB] = useState(0);
    const [priceB, setpriceB] = useState(0);
    const [totalB, settotalB] = useState(0);
    const [trades, settrades] = useState([]);


    const [chartCreated, setchartCreated] = useState(undefined);
    const [candleSeries, setcandleSeries] = useState(undefined);
    const [pairlist, setpairlist] = useState([]);
    const [pairdata, setpairdata] = useState({
        contractAddress: "",
        contract: undefined,
        price: "0.00",
        history: [],
        priceData: [],
        callPrice: (func, _tokenBCT, _tokenACT, _price, v, dec, deca) => {
            let pprice = usdpriceex(_tokenBCT, _tokenACT, _price, v, dec, deca)
            func(pprice)
        },
        sendOrder: async (_tokenBCT, _tokenACT, v, price, dec, deca) => {
            await sendex(_tokenBCT, _tokenACT, v, price, dec, deca)
        }
    });

    function nNumber(valueA, valueB) {
        return valueB.add(valueA)
    }

    useEffect(() => {

    }, []);
    /*useEffect(() => {
        connectToSelectedNetwork()
        let interval = setInterval(() => {
            setSeconds(seconds => seconds + 1)
            connectToSelectedNetwork()
            //
        }, 1000);
        return () => clearInterval(interval);
    }, [seconds]);*/
    useEffect(() => {
        //getQuery('token') == null ? '' : "&refFrom=" + getQuery('token')
        if (getQuery('trade') == null) {
            changeurl("exchange?trade=MTK_USDT", "MTK_USDT | Martik Spot")
            let e = (getQuery('trade')).toUpperCase()
            for (let index = 0; index < Pairs[GLOBAL['netid']][e.split("_")[1]].length; index++) {
                if (Pairs[GLOBAL['netid']][e.split("_")[1]][index].nome == e.split("_")[0]) {
                    setPair(Pairs[GLOBAL['netid']][e.split("_")[1]][index].par)
                    setTokenACT(Pairs[GLOBAL['netid']][e.split("_")[1]][index].address)
                    setTokenBCT(Pairs[GLOBAL['netid']][e.split("_")[1]][index].contract)
                    setTokenACT(Pairs[GLOBAL['netid']][e.split("_")[1]][index].address)
                    setTokenAname(e.split("_")[1])
                    setTokenBname(e.split("_")[0])
                    connectToSelectedNetwork(Pairs[GLOBAL['netid']][e.split("_")[1]][index])
                    break;
                }
            }
        } else {
            let e = (getQuery('trade')).toUpperCase()
            if (e.includes("_")) {
                for (let index = 0; index < Pairs[GLOBAL['netid']][e.split("_")[1]].length; index++) {
                    if (Pairs[GLOBAL['netid']][e.split("_")[1]][index].nome == e.split("_")[0]) {
                        setPair(Pairs[GLOBAL['netid']][e.split("_")[1]][index].par)
                        setTokenACT(Pairs[GLOBAL['netid']][e.split("_")[1]][index].address)
                        setTokenBCT(Pairs[GLOBAL['netid']][e.split("_")[1]][index].contract)
                        setTokenACT(Pairs[GLOBAL['netid']][e.split("_")[1]][index].address)
                        setTokenAname(e.split("_")[1])
                        setTokenBname(e.split("_")[0])
                        connectToSelectedNetwork(Pairs[GLOBAL['netid']][e.split("_")[1]][index])
                        break;
                    }
                }
            } else {
                changeurl("exchange?trade=MTK_USDT", "MTK_USDT | Martik Spot")
                for (let index = 0; index < Pairs[GLOBAL['netid']][e.split("_")[1]].length; index++) {
                    if (Pairs[GLOBAL['netid']][e.split("_")[1]][index].nome == e.split("_")[0]) {
                        setPair(Pairs[GLOBAL['netid']][e.split("_")[1]][index].par)
                        setTokenACT(Pairs[GLOBAL['netid']][e.split("_")[1]][index].address)
                        setTokenBCT(Pairs[GLOBAL['netid']][e.split("_")[1]][index].contract)
                        setTokenACT(Pairs[GLOBAL['netid']][e.split("_")[1]][index].address)
                        setTokenAname(e.split("_")[1])
                        setTokenBname(e.split("_")[0])
                        connectToSelectedNetwork(Pairs[GLOBAL['netid']][e.split("_")[1]][index])
                        break;
                    }
                }
            }
        }

    }, []);

    const chartContainerRef = useRef();
    const chart = useRef();
    const resizeObserver = useRef();
    function changeurl(url, title) {
        var new_url = '/' + url;
        window.history.pushState('data', title, new_url);
        //document.title = title

    }
    function getQuery(query) {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const foo = params.get(query);
        return foo
    }
    function usdpriceex(_tokenBCT, _tokenACT, price, v, dec, deca) {
        let isBuy = _tokenBCT == tokenBCT;

        let deserve = isBuy
            ? (parseFloat(v) * (1)) / parseFloat(price)
            : (parseFloat(v) * parseFloat(price)) / (1);

        return deserve;
        /*  try {
              const cake = await new web3.Contract(abipar, pair);
              const p1 = await cake.methods.priceEstimate(_tokenBCT, _tokenACT, ethers.utils.parseUnits(v, dec), ethers.utils.parseUnits(price, deca)).call()
              return parseFloat(nextblock(p1, dec))
          } catch (error) {
              return 0.00
          }*/
    }
    async function sendex(_tokenBCT, _tokenACT, v, price, dec, deca) {
        if (_tokenACT == WBNB) {
            await nSendTX(pair, abipar, GLOBAL['netid'], "_OrderLimit", ethers.utils.parseUnits(v, dec), 0, _tokenBCT, _tokenACT, ethers.utils.parseUnits(v, dec), ethers.utils.parseUnits(price, deca))

        } else {
            await nSendTX(pair, abipar, GLOBAL['netid'], "_OrderLimit", "0", 0, _tokenBCT, _tokenACT, ethers.utils.parseUnits(v, dec), ethers.utils.parseUnits(price, deca))

        }
    }
    async function usdpricebnb(token, v) {
        try {
            const cake = await new web3.Contract(cakeabi, props.array[1]);
            const p1 = await cake.methods.getAmountsOut(v, [tokens_default[GLOBAL['netid']][0].contract, tokens_default[GLOBAL['netid']][1].contract]).call()
            return parseFloat(nextblock(p1[1], 18))
        } catch (error) {
            return 0.00
        }
    }
    async function usdprice(token, v) {
        try {
            if (token == tokens_default[GLOBAL['netid']][0].contract) {
                return await usdpricebnb(token, v)
            } else {

                const cake = await new web3.Contract(cakeabi, props.array[1]);
                const p0 = await cake.methods.getAmountsOut(v, [token, tokens_default[GLOBAL['netid']][0].contract]).call()
                const p1 = await cake.methods.getAmountsOut(p0[1], [tokens_default[GLOBAL['netid']][0].contract, tokens_default[GLOBAL['netid']][1].contract]).call()
                return parseFloat(nextblock(p1[1], 18))
            }
        } catch (error) {
            return 0.00
        }
    }
    function getIcon(contract) {
        for (let index = 0; index < tokens_default[GLOBAL['netid']].length; index++) {
            if (tokens_default[GLOBAL['netid']][index].contract == contract) {
                return tokens_default[GLOBAL['netid']][index].icon
                break;
            } else {
                if (index == tokens_default[GLOBAL['netid']].length - 1) {
                    return "https://bscscan.com/images/main/empty-token.png"
                    break;
                }
            }
        }
    }
    function dateToChartTimeMinute(date) {
        return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0, 0) / 1000;
    };
    async function connectToSelectedNetwork(_par) {
        const __m = {
            pair: _par.par,
            tokenACT: _par.address,
            tokenBCT: _par.contract,
            version: _par.v
        }

        const {
            pair,
            tokenACT,
            tokenBCT,
            version
        } = __m
        const web3 = new Web3(publicrpc[getID()]);
        const ta = await Tokencontract(bnbabi, tokenACT);
        const tb = await Tokencontract(bnbabi, tokenBCT);
        const account = GLOBAL['account'] != null ? GLOBAL['account'] : null
        const bal = GLOBAL['account'] != null ? await web3['getBalance'](GLOBAL['account']) : 0
        if (account != null) {
            call(ta['methods']['balanceOf'], setAccountBalanceTA, GLOBAL['account']);
            call(tb['methods']['balanceOf'], setAccountBalanceTB, GLOBAL['account']);
        }
        { ta != undefined && call(ta.methods.totalSupply, setTotalSupplyA) };
        { tb != undefined && call(tb.methods.totalSupply, setTotalSupplyB) };
        { ta != undefined && call(ta.methods.decimals, setCdecimalsTA) };
        { tb != undefined && call(tb.methods.decimals, setCdecimalsTB) };
        const da = await ta['methods']['decimals']().call()
        const db = await tb['methods']['decimals']().call()
        setTokenA(ta);
        setTokenB(tb);
        setBNBbalance(bal)

        if (pair != undefined) {
            const _pair = await Tokencontract(abipar, pair);
            let obj = {
                contractAddress: pair,
                contract: _pair,
                price: await _pair['methods']['Price']().call(),
                prices: await _pair['methods']['AllPrices']().call(),
                history: await parseHistory(pair, version),

                callPrice: (func, _tokenBCT, _tokenACT, _price, v, dec, deca) => {
                    let pprice = usdpriceex(_tokenBCT, _tokenACT, _price, v, dec, deca)
                    func(pprice)
                },
                sendOrder: async (_tokenBCT, _tokenACT, v, price, dec, deca) => {
                    await sendex(_tokenBCT, _tokenACT, v, price, dec, deca)
                }
            }


            let data = []
            let volumeData = []
            let timestamp = {}
            let history = obj.history;
            let in24h = [];
            let volumeA = ethers.BigNumber.from(0);
            let volumeB = ethers.BigNumber.from(0);
            let MIN = 0;
            let MAX = 0;
            let c24 = 0;
            if (history.length > 0) {
                for (let index = 0; index < history.length; index++) {
                    if (timestamp[dateToChartTimeMinute(new Date(history[index].timestamp * 1000))] != undefined) {
                    } else {
                        let obj = {
                            time: dateToChartTimeMinute(new Date(history[index].timestamp * 1000)),
                            open: parseFloat(nextblock(history[index == 0 ? 0 : index - 1].price, da)),
                            high: parseFloat(nextblock(history[index].price, da)),
                            low: parseFloat(nextblock(history[index].price, da)),
                            close: parseFloat(nextblock(history[index].price, da)),
                        }
                        volumeData.push({
                            time: dateToChartTimeMinute(new Date(history[index].timestamp * 1000)),
                            value: parseFloat(nextblock(history[index].V1, da))
                        })
                        timestamp[obj.time] = index;
                        data.push(obj)
                    }
                }
                let date = new Date()
                let daystarted = Date.parse(new Date(("" + (parseInt(date.getMonth()) + 1)) + (" " + date.getDate() + ", ") + ("" + date.getFullYear()) + ' 00:00:00'))
                for (let index = 0; index < history.length; index++) {
                    if (parseInt(history[index].timestamp) >= parseInt(daystarted / 1000)) {
                        in24h.push(history[index])
                    } else {

                    }
                }

                for (let index = 0; index < in24h.length; index++) {
                    volumeA = volumeA.add(ethers.BigNumber.from(in24h[index].V0))
                    volumeB = volumeB.add(ethers.BigNumber.from(in24h[index].V1))
                    //   volumeA += nextblock(, db);
                    //volumeB += nextblock(in24h[index].V1, da);
                }
                if (in24h.length > 0) {
                    let rated = in24h.sort((a, b) => parseFloat(nextblock(a.price, da)) - parseFloat(nextblock(b.price, da)))
                    if (rated.length > 0) {
                        MIN = nextblock(rated[0].price, da)
                        MAX = nextblock(rated[rated.length - 1].price, da)
                    }

                    c24 = percDiff(nextblock(in24h[0].price, da), nextblock(obj.price, da))

                    if (c24 == undefined) { c24 = 0 }

                    /*   c24 = obj.price.toString().length >= da
                           ? (((nextblock(obj.price, da) - nextblock(in24h[0].price, da)) / nextblock(in24h[0].price, da)) * 100).toFixed(2)
                           : (((parseInt(obj.price) - parseInt(in24h[0].price)) / parseInt(in24h[0].price)) * 100).toFixed(2)
   
                       if (c24 == undefined) { c24 = 0 }
                       */
                }

            }

            try {
                chart.current = chartCreated == undefined ? createChart(chartContainerRef.current, {
                    width: chartContainerRef.current.clientWidth,
                    height: chartContainerRef.current.clientHeight,
                    layout: {
                        background: { type: 'solid', color: '#00000000' },
                        textColor: 'rgba(255, 255, 255, 0.9)',
                    },
                    grid: {
                        vertLines: {
                            color: '#334158',
                        },
                        horzLines: {
                            color: '#334158',
                        },
                    },
                    crosshair: {
                        mode: CrosshairMode.Normal,
                    },
                    priceScale: {
                        borderColor: '#485c7b',
                    },
                    timeScale: {
                        timeVisible: true,
                        secondsVisible: false,
                    },
                }) : chartCreated;
                const _candleSeries = candleSeries == undefined ? chart.current.addCandlestickSeries({
                    upColor: '#4bffb5',
                    downColor: '#ff4976',
                    borderDownColor: '#ff4976',
                    borderUpColor: '#4bffb5',
                    wickDownColor: '#838ca1',
                    wickUpColor: '#838ca1',
                }) : candleSeries;
                _candleSeries.applyOptions({
                    priceFormat: {
                        type: 'price',
                        precision: parseInt(decimalsTA - 2),
                        minMove: 0.000000000001,
                    },
                });
                setchartCreated(chart.current)


                /*  const volumeSeries = chart.current.addHistogramSeries({
                      color: '#182233',
                      lineWidth: 2,
                      priceFormat: {
                          type: 'volume',
                      },
                      overlay: true,
                      scaleMargins: {
                          top: 0.8,
                          bottom: 0,
                      },
                  });
          */
                if (data.length > 0) {
                    _candleSeries.setData(data);
                    setcandleSeries(_candleSeries)
                    //volumeSeries.setData(volumeData);
                }

            } catch (error) {

            }


            // const areaSeries = chart.current.addAreaSeries({
            //   topColor: 'rgba(38,198,218, 0.56)',
            //   bottomColor: 'rgba(38,198,218, 0.04)',
            //   lineColor: 'rgba(38,198,218, 1)',
            //   lineWidth: 2
            // });

            // areaSeries.setData(areaData);

            let prices = obj.prices;
            let sellprices = []
            let buyprices = []
            let orders = []
            let morders = []
            let buystatus = {}
            let sellstatus = {}

            let priceSetSell = {}
            let priceSetBop = {}

            if (prices.length > 0) {
                for (let index = 0; index < prices.length; index++) {
                    if (buystatus[prices[index]] == undefined) {

                        let from = await _pair['methods']['solvedordersindex'](prices[index]).call()
                        let to = await _pair['methods']['ordersindex'](prices[index]).call()
                        buystatus[prices[index]] = {
                            total0: ethers.BigNumber.from(0),
                            total1: ethers.BigNumber.from(0)
                        }
                        sellstatus[prices[index]] = {
                            total0: ethers.BigNumber.from(0),
                            total1: ethers.BigNumber.from(0)
                        }

                        for (let i = from; i < to; i++) {
                            let order = await _pair['methods']['waitlist'](prices[index], i).call()
                            let myorder = order.owner == account;


                            if ((order.Tokenwith).toString().toUpperCase() == (tokenACT).toString().toUpperCase()) {
                                try {
                                    let receive
                                    try {
                                        receive = ethers.BigNumber.from(100).sub(ethers.BigNumber.from(ethers.BigNumber.from(order.received).mul(100)).div(order.to))
                                    } catch (error) {
                                        receive = 1
                                    }

                                    buystatus[prices[index]].total0 = buystatus[prices[index]].total0.add(ethers.BigNumber.from(ethers.BigNumber.from(order.to).mul((receive).toString())).div(100))
                                    buystatus[prices[index]].total1 = buystatus[prices[index]].total1.add(ethers.BigNumber.from(ethers.BigNumber.from(order[4]).mul((receive).toString())).div(100))

                                    if (priceSetBop[prices[index]] == undefined) {
                                        priceSetBop[prices[index]] = order
                                        buyprices.push(order)
                                    }
                                    if (myorder)
                                        morders.push({
                                            pos: i,
                                            receive: receive,
                                            isBuy: true,
                                            usdprice: prices[index],
                                            to: nextblock(order.to, db)
                                        })
                                } catch (error) {

                                }

                            } else {
                                try {

                                    let receive
                                    try {
                                        receive = ethers.BigNumber.from('100').sub(ethers.BigNumber.from(ethers.BigNumber.from(order.received).mul('100')).div(order.to))
                                    } catch (error) {
                                        receive = 1
                                    }

                                    sellstatus[prices[index]].total0 = sellstatus[prices[index]].total0.add(ethers.BigNumber.from(ethers.BigNumber.from(order[4]).mul((receive).toString())).div('100'))

                                    sellstatus[prices[index]].total1 = sellstatus[prices[index]].total1.add(ethers.BigNumber.from(ethers.BigNumber.from(order.to).mul((receive).toString())).div('100'))


                                    if (priceSetSell[prices[index]] == undefined) {
                                        priceSetSell[prices[index]] = order
                                        sellprices.push(order)
                                    }
                                    if (myorder)
                                        morders.push({
                                            pos: i,
                                            receive: receive,
                                            isBuy: false,
                                            usdprice: prices[index],
                                            to: nextblock(order[4], db)
                                        })
                                } catch (error) {

                                }

                            }
                            orders.push(order)
                        }
                    }
                }
            }
            obj.inDay = {
                c24, MIN, MAX, volumeA, volumeB
            }
            obj.pricestatus = {
                buystatus,
                sellstatus
            };

            obj.buyorders = buyprices;
            obj.sellorders = sellprices;
            obj.orders = orders;
            obj.morders = morders;
            setpairdata(obj)

            if (document.getElementById('buyprice') != undefined) document.getElementById('buyprice').value = nextblock(obj.price, decimalsTA);
            if (document.getElementById('sellprice') != undefined) document.getElementById('sellprice').value = nextblock(obj.price, decimalsTA);

        }

        getpairData(tokenAname)

    }

    function percDiff(etalon, example) {
        return +Math.abs(100 - example / etalon * 100).toFixed(10);
    }
    async function parseHistory(pair, v) {
        const version = v

        const _pair = await Tokencontract(version == "1" ? abipar : abiparv2, pair);
        if (version == "1") {
            return await _pair['methods']['Historic']().call()
        } else {
            const Sindex = await _pair['methods']['_StoragesIndex']().call()
            if (Sindex == 0) {
                return await _pair['methods']['Historic'](Sindex).call()
            } else {
                let hist = []
                for (let index = 0; index < Sindex; index++) {
                    let h = await _pair['methods']['Historic'](index).call()
                    hist.concat(h)
                }
                return hist
            }
        }
    }
    async function getpairData(e) {

        let tempList = []

        for (let index = 0; index < Pairs[GLOBAL['netid']][e].length; index++) {

            const ta = await Tokencontract(bnbabi, Pairs[GLOBAL['netid']][e][index].address);
            const tb = await Tokencontract(bnbabi, Pairs[GLOBAL['netid']][e][index].contract);
            const da = await ta['methods']['decimals']().call()
            // const db = await tb['methods']['decimals']().call()
            const _pair = await Tokencontract(abipar, Pairs[GLOBAL['netid']][e][index].par);
            const price = await _pair['methods']['Price']().call()

            const history = (Pairs[GLOBAL['netid']][e][index].v).toString() == "1"
                ? await _pair['methods']['Historic']().call()
                : await parseHistory(Pairs[GLOBAL['netid']][e][index].par, Pairs[GLOBAL['netid']][e][index].v)

            let in24h = [];
            let c24 = 0;
            if (history.length > 0) {
                let date = new Date()
                let daystarted = Date.parse(new Date(("" + (parseInt(date.getMonth()) + 1)) + (" " + date.getDate() + ", ") + ("" + date.getFullYear()) + ' 00:00:00'))
                for (let index = 0; index < history.length; index++) {
                    if (parseInt(history[index].timestamp) >= parseInt(daystarted / 1000)) {
                        in24h.push(history[index])
                    } else {

                    }
                }
            }

            if (in24h.length > 0) {

                c24 = percDiff(nextblock(in24h[0].price, da), nextblock(price, da))

                if (c24 == undefined) { c24 = 0 }
                /*  c24 = price.toString().length >= da
                  ? (ethers.BigNumber.from(ethers.BigNumber.from(ethers.BigNumber.from(price).sub(in24h[0].price)).div(in24h[0].price)).mul('100')).toFixed(2)
                  : (ethers.BigNumber.from(ethers.BigNumber.from(ethers.BigNumber.from(price).sub(in24h[0].price)).div(in24h[0].price)).mul('100')).toFixed(2)
  
                  console.log(c24)*/

            }

            tempList.push({
                "nome": Pairs[GLOBAL['netid']][e][index].nome,
                "bname": Pairs[GLOBAL['netid']][e][index].bname,
                "icon": Pairs[GLOBAL['netid']][e][index].icon,
                "contract": Pairs[GLOBAL['netid']][e][index].contract,
                "par": Pairs[GLOBAL['netid']][e][index].par,
                "address": Pairs[GLOBAL['netid']][e][index].address,
                "c24": c24.toString(),
                "price": nextblock(price, da)
            })
        }
        setpairlist(tempList)

    }
    function nextblock(accountBalancemctTB, d) {
        if (accountBalancemctTB != undefined && accountBalancemctTB != 0) {
            if (accountBalancemctTB.toString().length >= d) {
                return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(5)
            } else {
                let cutin = (d - accountBalancemctTB.toString().length) + 2
                return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(cutin)
            }
        } else {
            return 0.00
        }
    }

    /* function nextblock(accountBalancemctTB, d, cs) {
         return (accountBalancemctTB != undefined && accountBalancemctTB != 0) ? parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(cs == undefined ? 5 : cs) : 0.00
     }*/


    // call takes in a function to execute and runs a given callback on the response
    async function call(func, callback, ...args) {
        // Trigger the function with the arguments
        func(...args).call()
            .then((result) => {
                // Apply given callback, this is our stateSetters
                callback(result);
                setconerror(false)
            })
            .catch((error) => {

                setconerror(true)
            })
    }

    const balancetokenA = () => {
        if (tokenAname == "BNB") {
            return nextblock(accountBNB, decimalsTA);
        } else {
            return nextblock(accountBalancemTA, decimalsTA)
        }
    }
    const balancetokenB = () => {
        if (tokenBname == "BNB") {
            return nextblock(accountBNB, decimalsTB);
        } else {
            return nextblock(accountBalancemTB, decimalsTB)
        }
    }

    function choicecoin(isa, e, c) {
        if (isa) {

            setTokenAname(e)
            setTokenACT(c);
        } else {
            setTokenBname(e)
            setTokenBCT(c);
        }


    }
    function Car(props) {
        return <li onClick={(e) => {
            choicecoin(false, props.brand, props.ct)
            choicecoin(true, tname, Pairs[GLOBAL['netid']][tname][props.pos].address)
            setPair(Pairs[GLOBAL['netid']][tname][props.pos].par)
            setHint(false)
            setAmountin(0)
            setAmountout(0)
            changeurl("exchange?trade=" + Pairs[GLOBAL['netid']][tname][props.pos].nome + "_" + tname, Pairs[GLOBAL['netid']][tname][props.pos].nome + "_" + tname + " | Martik Spot")

            setTimeout(() => {
                if (getQuery('trade') == null) {
                    changeurl("exchange?trade=MTK_USDT", "MTK_USDT | Martik Spot")
                } else {
                    let e = (getQuery('trade')).toUpperCase()
                    if (e.includes("_")) {
                        for (let index = 0; index < Pairs[GLOBAL['netid']][e.split("_")[1]].length; index++) {
                            if (Pairs[GLOBAL['netid']][e.split("_")[1]][index].nome == e.split("_")[0]) {
                                setPair(Pairs[GLOBAL['netid']][e.split("_")[1]][index].par)
                                setTokenACT(Pairs[GLOBAL['netid']][e.split("_")[1]][index].address)
                                setTokenBCT(Pairs[GLOBAL['netid']][e.split("_")[1]][index].contract)
                                setTokenACT(Pairs[GLOBAL['netid']][e.split("_")[1]][index].address)
                                setTokenAname(e.split("_")[1])
                                setTokenBname(e.split("_")[0])
                                connectToSelectedNetwork(Pairs[GLOBAL['netid']][e.split("_")[1]][index])
                                break;
                            }
                        }
                    } else {
                        changeurl("exchange?trade=MTK_USDT", "MTK_USDT | Martik Spot")
                    }
                }
            }, 2000);
        }} style={{ height: "35px", cursor: "pointer", width: "100%", display: "grid", gridTemplateColumns: "auto auto auto", margin: "0", borderRadius: "5px", paddingLeft: "1px" }}>
            <p style={{ margin: "auto 0", textAlign: "left" }}>
                <span style={{ fontSize: "10px" }}>{props.brand}</span>
                <span style={{ fontSize: "10px", color: "#888" }}>
                    /{tname}
                </span>
            </p>
            <p style={{ margin: "auto 0", fontSize: "10px", color: "#00ff00", textAlign: "left" }}>{props.data.price}</p>
            <p style={{
                margin: "auto 0", fontSize: "10px", color: props.data.c24 < 0
                    ? "#ff0000"
                    : "#00ff00", textAlign: "center"
            }}>{props.data.c24}%</p>
        </li>;
    }
    function BoletaItem(props) {
        const obj = props.isSell ? {
            price: nextblock(props.price, decimalsTA),
            amount: nextblock(pairdata.pricestatus.sellstatus[props.price].total0, decimalsTB),
            total: nextblock(pairdata.pricestatus.sellstatus[props.price].total1, decimalsTA)
        } : {
            price: nextblock(props.price, decimalsTA),
            amount: nextblock(pairdata.pricestatus.buystatus[props.price].total0, decimalsTB),
            total: nextblock(pairdata.pricestatus.buystatus[props.price].total1, decimalsTA)
        }

        return (
            <>
                {
                    parseFloat(obj.amount) > 0
                        ?
                        <div
                            onClick={() => {
                                document.getElementById('buyprice').value = obj.price;
                                document.getElementById('sellprice').value = obj.price;
                            }}
                            style={{
                                cursor: "pointer",
                                position: " relative",
                                width: 'calc(100% - 10px)',
                                height: "20px",
                                backgroundColor: props.color + "25",
                                borderRadius: "5px",
                                margin: "5px"
                            }
                            }>
                            <div style={{
                                position: "absolute",
                                width: ((obj.total * 100) / 100) + "%",
                                height: "100%",
                                backgroundColor: props.color + "50",
                                borderRadius: "5px",
                                margin: "0px 1px"
                            }}></div>
                            <div style={{ height: "20px", width: "100%", color: "#fff", display: "grid", gap: "5px", gridTemplateColumns: "auto auto auto" }}>
                                <p style={{ margin: "auto", fontSize: "10px", color: "#888", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{obj.price} </p>
                                <p style={{ margin: "auto", fontSize: "10px", color: "#888", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{obj.amount}</p>
                                <p style={{ margin: "auto", fontSize: "10px", color: "#888", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{obj.total}</p>
                            </div>
                        </div >
                        : <></>
                }
            </>
        )
    }
    function TradeItem(props) {
        let date = new Date(props.time * 1000)
        let time = ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + ":" + ("0" + date.getSeconds()).slice(-2)
        return <div style={{
            position: " relative",
            width: "100%",
            height: "20px",
            backgroundColor: props.color + "00",
        }}>
            <div style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: props.color + "00",
            }}></div>
            <div style={{ height: "20px", width: "100%", color: "#fff", display: "grid", gridTemplateColumns: "auto auto auto" }}>
                <p style={{ margin: "auto", fontSize: "12px", color: props.isBuy ? '#28a745' : '#ff0000' }}>{nextblock(props.price, decimalsTA)} </p>
                <p style={{ margin: "auto", fontSize: "12px", color: "#888" }}>{nextblock(props.amount, decimalsTB)}</p>
                <p style={{ margin: "auto", fontSize: "12px", color: "#888" }}>{time}</p>
            </div>
        </div>
    }


    function OrderItem(props) {
        // let date = new Date(props.time * 1000)
        // let time = ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + ":" + ("0" + date.getSeconds()).slice(-2)
        return <div style={{ height: "50px", width: "100%", color: "#fff", display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(65px, 1fr)", }}>
            <p style={{ margin: "auto", fontSize: "10px", color: props.isBuy ? '#28a745' : '#ff0000' }}>{nextblock(props.price, decimalsTA)} </p>
            <p style={{ margin: "auto", fontSize: "10px", color: "#888" }}>{props.amount}</p>
            <p style={{ margin: "auto", fontSize: "10px", color: "#888" }}>{(100 - parseFloat(props.receive)).toFixed(2) + "%"}</p>
            <p onClick={async () => {
                await nSendTX(pair, abipar, GLOBAL['netid'], "removeOrder", "0", 0, props.price, props.pos)

            }} style={{ margin: "auto", fontSize: "10px", color: "#888", cursor: "pointer" }}>{"Remove"}</p>
        </div>
    }
    function Cares(props) {
        return <li onClick={(e) => {
            setHint(false)
            setAmountin(0)
            setAmountout(0)
        }}>
            <img src={props.icon}></img>
            <h1>{props.brand}</h1>
        </li>;
    }

    function Ulconfig() {
        return listsearch != null
            ? <ul id='list' style={{ gap: "15px", paddingLeft: "5px", }}>
                {<Cares brand={listsearch.name} brands={listsearch.symbol} icon={"https://bscscan.com/images/main/empty-token.png"} ct={ctfind} />}
                {pairlist.map((car, i) => <Car pos={i} brand={car.nome} icon={car.icon} ct={car.contract} data={car} />)}
            </ul>
            : <ul id='list' style={{ gap: "15px", padding: "0", paddingLeft: "5px", }}>
                {pairlist.map((car, i) => <Car pos={i} brand={car.nome} icon={car.icon} ct={car.contract} data={car} />)}
            </ul>
    }
    function Modaltokensls() {
        return <div style={{ position: "unset", background: "#00000000", height: "360px", display: "grid", gridTemplateRows: "25px 25px auto", border: "1px solid #ffffff20", borderRadius: "0px", width: "100%" }}>

            <div style={{
                width: "100%",
                height: "25px",
                margin: "auto",
                display: "grid",
                gridTemplateColumns: "auto auto auto auto",
                borderBottom: "1px solid #ffffff35",
                gap: "10px"
            }}>

                <div onClick={() => { settokenname("BNB"); setpairlist([]); getpairData("BNB") }} style={{

                    height: "25px",
                    background: "#00000010",
                    margin: "auto",
                    display: "grid",
                    color: "#ddd",
                    fontSize: "12px",
                    alignItems: "center",
                    textAlign: "center",
                    paddingLeft: "3px",
                    cursor: "pointer",
                    borderRadius: "5px"
                }}>
                    BNB
                </div>

                {<div onClick={() => { settokenname("USDT"); setpairlist([]); getpairData("USDT") }} style={{

                    height: "25px",
                    background: "#00000010",

                    margin: "auto",
                    display: "grid",
                    color: "#ddd",
                    fontSize: "12px",
                    alignItems: "center",
                    textAlign: "center",
                    paddingLeft: "3px",
                    cursor: "pointer",
                    borderRadius: "5px"
                }}>
                    USDT
                </div>}
                {<div onClick={() => { settokenname("MTK"); setpairlist([]); getpairData("MTK") }} style={{

                    height: "25px",
                    background: "#00000010",

                    margin: "auto",
                    display: "grid",
                    color: "#ddd",
                    fontSize: "12px",
                    alignItems: "center",
                    textAlign: "center",
                    paddingLeft: "3px",
                    cursor: "pointer",
                    borderRadius: "5px"
                }}>
                    MTK
                </div>}
                <div onClick={() => { settokenname("BUSD"); setpairlist([]); getpairData("BUSD") }} style={{

                    height: "25px",
                    background: "#00000010",
                    margin: "auto",
                    display: "grid",
                    color: "#ddd",
                    fontSize: "12px",
                    alignItems: "center",
                    textAlign: "center",
                    paddingLeft: "3px",
                    cursor: "pointer",
                    borderRadius: "5px"
                }}>
                    BUSD
                </div>
            </div>
            {true && <div style={{ paddingLeft: "5px", height: "35px", cursor: "pointer", width: "100%", display: "grid", gridTemplateColumns: "auto auto auto", margin: "0", borderRadius: "5px", }}>

                <p style={{ margin: "auto 0", textAlign: "left" }}>{'Pair'} </p>
                <p style={{ margin: "auto 0", textAlign: "center" }}>{'Price'}</p>
                <p style={{ margin: "auto 0", textAlign: "center" }}>{'Variation'}</p>
            </div>
            }
            {<Ulconfig />}
        </div >
    }

    /* let chartA = <div className='chartwboleta'>
     
         <div style={{ height: "100%", width: "100%" }}>
             <div className="App">
                 <div ref={chartContainerRef} className="chart-container" />
             </div>
         </div>
     </div>*/

    return (
        <>

            <div style={{ height: "min-content", minHeight: "50px", width: "100%", display: "grid", gridTemplateColumns: window.innerWidth < 700 ? "auto" : "280px 150px 150px 150px 150px 150px 150px 150px", gap: "5px", margin: "auto" }}>

                <p style={{ width: "100%", display: "grid", gap: "3px", gridTemplateColumns: "auto", paddingLeft: "5px" }}>

                    <br /> <span style={{
                        textAlign: "left",
                        width: "100%",
                        fontWeight: "bold",
                        color: "#e5e5e5",
                        fontSize: "20px",
                        margin: "auto"
                    }}>{tokenBname + "/" + tokenAname} </span>


                    <div style={{
                        height: "min-content",
                        width: "100%",
                        margin: "auto",
                        display: "flex",
                        gap: "10px",
                        fontSize: "12px",

                    }}>
                        <p onClick={() => {
                            window.open("https://bscscan.com/token/" + tokenBCT)
                        }} style={
                            {
                                padding: "2px 0px",
                                cursor: "pointer",
                                background: "#FFBF0020",
                                color: "#FFBF00",
                                borderRadius: "2px",

                                width: "90px",
                                marginBottom: "5px"
                            }
                        }>
                            BSCSCAN
                        </p>
                        <p style={
                            {
                                padding: "2px 0px",
                                background: "#FFBF0020",
                                color: "#FFBF00",
                                borderRadius: "2px",

                                width: "90px",
                                marginBottom: "5px"
                            }
                        }>
                            FEE {feedata[tokenBname]}
                        </p>
                    </div>
                </p>
                <p style={{ width: "100%", height: "min-content", display: "grid", gridTemplateColumns: "auto " }}>

                    <br /> <span style={{
                        textAlign: "left",
                        width: "100%",
                        fontWeight: "bold",
                        color: "#00ff00",
                        fontSize: "18px",
                        margin: "auto"
                    }}>{nextblock(pairdata.price, decimalsTA)}
                    </span>
                    <p />
                    <span style={{
                        textAlign: "left",
                        width: "100%",
                        color: "#ddd",
                        fontSize: "14px",
                        margin: "auto"
                    }}>${nextblock(pairdata.price, decimalsTA)}
                    </span>
                </p>
                <p style={{ width: "100%", height: "min-content", display: "grid", gridTemplateColumns: "auto ", }}>

                    <br />   <span style={{
                        textAlign: "left",
                        width: "100%",

                        color: "#ddd",
                        fontSize: "13px",
                        margin: "auto"
                    }}>24h Change
                    </span>
                    <p />
                    <span style={
                        pairdata.inDay != undefined
                            ? {
                                textAlign: "left",
                                width: "100%",
                                color: pairdata.inDay.c24 < 0
                                    ? "#ff0000"
                                    : "#28a745",
                                fontSize: "14px",
                                fontWeight: "bold",
                                margin: "auto"
                            }
                            : {
                                textAlign: "left",
                                width: "100%",
                                color: "#28a745",
                                fontSize: "14px",
                                margin: "auto",
                                fontWeight: "bold"
                            }
                    }>{
                            pairdata.inDay != undefined && pairdata.inDay.c24} %
                    </span>
                </p>
                <p style={{ width: "100%", height: "min-content", display: "grid", gridTemplateColumns: "auto ", }}>
                    <br />    <span style={{
                        textAlign: "left",
                        width: "100%",
                        color: "#ddd",
                        fontSize: "13px",
                        margin: "auto"
                    }}>24h High
                    </span>
                    <p />
                    <span style={{
                        textAlign: "left",
                        width: "100%",
                        color: "#fff",
                        fontSize: "13px",
                        margin: "auto",
                        fontWeight: "bold"
                    }} >{pairdata.inDay != undefined && pairdata.inDay.MAX}
                    </span>
                </p>
                <p style={{ width: "100%", height: "min-content", display: "grid", gridTemplateColumns: "auto ", }}>
                    <br />  <span style={{
                        textAlign: "left",
                        width: "100%",
                        color: "#ddd",
                        fontSize: "13px",
                        margin: "auto"
                    }}>24h min
                    </span>
                    <p />
                    <span style={{
                        textAlign: "left",
                        width: "100%",
                        color: "#fff",
                        fontSize: "13px",
                        margin: "auto",
                        fontWeight: "bold"
                    }} >{pairdata.inDay != undefined && pairdata.inDay.MIN}
                    </span>
                </p>
                <p style={{ width: "100%", height: "min-content", display: "grid", gridTemplateColumns: "auto ", }}>
                    <br />  <span style={{
                        textAlign: "left",
                        width: "100%",
                        color: "#ddd",
                        fontSize: "13px",
                        margin: "auto"
                    }}>24h volume ({tokenBname})
                    </span>
                    <p />
                    <span style={{
                        textAlign: "left",
                        width: "100%",
                        color: "#fff",
                        fontSize: "13px",
                        margin: "auto",
                        fontWeight: "bold"
                    }} >{pairdata.inDay != undefined && nextblock(pairdata.inDay.volumeA, decimalsTA)}
                    </span>
                </p>
                <p style={{ width: "100%", height: "min-content", display: "grid", gridTemplateColumns: "auto", }}>
                    <br /> <span style={{
                        textAlign: "left",
                        width: "100%",
                        color: "#ddd",
                        fontSize: "13px",
                        margin: "auto"
                    }}>24h volume ({tokenAname})
                    </span>
                    <p />
                    <span style={{
                        textAlign: "left",
                        width: "100%",
                        color: "#fff",
                        fontSize: "13px",
                        margin: "auto",
                        fontWeight: "bold"
                    }} >{pairdata.inDay != undefined && nextblock(pairdata.inDay.volumeB, decimalsTB)}
                    </span>
                </p>
            </div>

            <div style={{ display: "grid", gridTemplateColumns: window.innerWidth < 1000 ? "auto" : "auto 280px", gap: "5px" }}>
                <div style={{
                    height: "min-content",
                    minWidth: "280px",
                    listStyleType: "none",
                    display: "block",
                    margin: "0 auto",

                    color: "#ddd",
                    fontSize: "12px",
                    width: "100%"
                }}>
                    <div style={{ height: "100%", minHeight: "480px", width: "100%", display: "grid", gridTemplateColumns: window.innerWidth < 700 ? "auto" : "280px auto ", gap: "5px", overflow: "hidden", }}>
                        <div style={{
                            height: "min-content",
                            minWidth: "280px",
                            listStyleType: "none",
                            display: "grid",
                            margin: "0 auto",
                            color: "#ddd",
                            fontSize: "12px",
                            border: "1px solid #ffffff20",

                        }}>
                            <div style={{ width: "100%", height: "min-content", display: "grid", gridTemplateRows: "40px 200px" }}>
                                <div style={{ height: "40px", width: "100%", display: "grid", gridTemplateColumns: "auto auto auto" }}>
                                    <p style={{ margin: "auto", fontSize: "10px", color: "#888" }}> {'Price'} </p>
                                    <p style={{ margin: "auto", fontSize: "10px", color: "#888" }}>{'Amount' + "(" + tokenBname + ")"}</p>
                                    <p style={{ margin: "auto", fontSize: "10px", color: "#888" }}>{'Total' + "(" + tokenAname + ")"}</p>
                                </div>
                                <div style={{ height: "200px", display: "grid", gridTemplateRows: "auto min-content" }}>
                                    <p style={{ height: "100%" }}></p>
                                    <div style={{ padding: "0", height: "min-content", maxHeight: "200px", width: "280px", display: "block", overflowY: "auto" }}>
                                        {pairdata.sellorders != undefined && pairdata.sellorders.sort((a, b) => parseFloat(nextblock(b.USDprice, decimalsTA)) < parseFloat(nextblock(a.USDprice, decimalsTA)) ? 1 : -1).map((e, index) => <BoletaItem color={"#ff0000"} price={e.USDprice} isSell={true} />).reverse()}
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: "0", minHeight: "200px" }}>
                                <div style={{ height: "40px", width: "100%", display: "grid", gridTemplateColumns: "50px auto 50px" }}>
                                    <span onClick={() => {
                                        document.getElementById('buyprice').value = nextblock(pairdata.price, decimalsTA);
                                        document.getElementById('sellprice').value = nextblock(pairdata.price, decimalsTA);
                                    }} style={{
                                        cursor: "pointer",
                                        width: "100%",
                                        fontWeight: "bold",
                                        color: "#e5e5e5",
                                        fontSize: "20px",
                                        margin: "auto",
                                        textAlign: "center",
                                    }}>
                                        <span style={{
                                            width: "100%",
                                            color: "#fff",
                                            fontSize: "20px",
                                            margin: "auto"
                                        }}>{nextblock(pairdata.price, decimalsTA)}
                                        </span>
                                        <span style={{
                                            width: "100%",
                                            color: "#ddd",
                                            fontSize: "10px",
                                            margin: "auto"
                                        }}>
                                            /{tokenAname}
                                        </span>
                                    </span>
                                </div>
                                <div style={{ padding: "0", width: "280px", display: "block", height: "200px", overflowY: "auto" }}>
                                    {pairdata.buyorders != undefined && pairdata.buyorders.sort((a, b) => parseFloat(nextblock(a.USDprice, decimalsTA)) < parseFloat(nextblock(b.USDprice, decimalsTA)) ? 1 : -1).map((e, index) => <BoletaItem color={"#00ff00"} price={e.USDprice} isSell={false} />)}
                                </div>

                            </div>
                            <div style={{ width: "100%", }}>
                                <div style={{ width: "100%", display: "grid", height: "50px", width: "100%" }}>
                                    <p style={{ margin: "auto", textAlign: "center", width: "100%", cursor: "pointer" }}>Market trades</p>
                                </div>
                                <div style={{ height: "40px", width: "100%", display: "grid", gridTemplateColumns: "auto auto auto" }}>
                                    <p style={{ margin: "auto 0", fontSize: "10px", color: "#888" }}>{'Price' + "(" + tokenAname + ")"} </p>
                                    <p style={{ margin: "auto 0", fontSize: "10px", color: "#888" }}>{'Amount' + "(" + tokenBname + ")"}</p>
                                    <p style={{ margin: "auto 0", fontSize: "10px", color: "#888" }}>{"Hora"}</p>
                                </div>
                                <div style={{ padding: "0", height: "360px", overflow: "auto" }}>
                                    {pairdata.history != undefined && pairdata.history.map((e, index) => <TradeItem color={"#00ff00"} isBuy={e.isBuy} amount={e.A0} time={e.timestamp} price={e.pricein} />).reverse()}
                                </div>
                            </div>
                        </div >
                        <div style={{ height: "100%", minHeight: "480px", width: "100%", gap: "5px", display: "grid" }}>
                            <div style={{ width: "100%", minHeight: "480px", overflow: "auto", border: "1px solid #ffffff20", }}>
                                <div className='chartwboleta'>
                                    <div ref={chartContainerRef} className="chart-container" style={{ height: "480px", width: "100%" }} />
                                </div>
                            </div>

                            {false && <Tradehub data={{
                                pair,
                                nSendTX,
                                GLOBAL,
                                pairdata,
                                tokenBCT,
                                tokenACT,
                                decimalsTA,
                                decimalsTB,
                                tokenAname,
                                tokenBname,
                                balancetokenA,
                                balancetokenB,
                                sendex
                            }} />}
                        </div>
                    </div>
                </div>
                <div style={{
                    height: "min-content",
                    minWidth: "280px",
                    listStyleType: "none",
                    display: "grid",
                    margin: "0 auto",
                    color: "#ddd",
                    fontSize: "12px",
                    gap: "5px"
                }}>

                    <div style={{ width: "100%" }}>
                        {<Modaltokensls />}
                    </div>
                    <div style={{ border: "1px solid #ffffff20", width: "100%", display: "grid", gridTemplateRows: "40px  40px auto" }}>
                        <div style={{ width: "100%", display: "grid", height: "50px", width: "100%" }}>
                            <p style={{ margin: "auto", textAlign: "center", width: "100%", cursor: "pointer" }}>My orders</p>
                        </div>
                        <div style={{ height: "50px", width: "100%", color: "#fff", display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(65px, 1fr)", }}>
                            <p style={{ margin: "auto", fontSize: "10px", color: "#888" }}>{'Price' + "(" + tokenAname + ")"} </p>
                            <p style={{ margin: "auto", fontSize: "10px", color: "#888" }}>{'Amount' + "(" + tokenBname + ")"}</p>
                            <p style={{ margin: "auto", fontSize: "10px", color: "#888" }}>{"Status"}</p>
                            <p style={{ margin: "auto", fontSize: "10px", color: "#888" }}>{"Action"}</p>
                        </div>
                        <div style={{ padding: "0", height: "360px", overflowY: "auto" }}>

                            {pairdata.morders != undefined && pairdata.morders.map((e, index) => <OrderItem color={"#00ff00"} isBuy={e.isBuy} amount={e.to} time={e.timestamp} price={e.usdprice} receive={e.receive} pos={e.pos} />).reverse()}
                        </div>
                    </div>
                </div >
            </div>
        </>
    );

}
const Exchange = (props) => {

    return (
        <Trade array={props.array} />
    );

}
export default Exchange;