import React, { useState, useEffect, useContext } from "react";
import { ethers } from 'ethers';
import abiToken from "../../abis/bnbabi.json";
import AppContext from "../../framework/helpers/AppContext";
import Minimized from "./minimized";
import ProjectData from '../../config';
import Button from "../../components/Launchpad/Button";
import nftsabi from "../../abis/nftsn.json"
import nftabi from "../../abis/nftu.json"
let projectName = "Martik"
const {
    Name,
    Logo,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,
    innerFont,
    innerBFont,
    Routers
} = ProjectData[window.location.host.split('.')[0] != undefined ? window.location.host.split('.')[0] : "Martik"]
const Web3 = require('web3-eth');

const NModal = (props) => {

    let provider = props.array[0]
    let contract = props.array[1]
    let stakesabi = props.array[2]
    let tokenabi = props.array[3]
    let accounts = [props.array[4]]
    const [tokenACT, settokenACT] = useState(undefined);
    const [tokenBCT, settokenBCT] = useState(undefined);
    const [STAKECTS, setSTAKECTS] = useState(contract);
    const [tokenB, settokenB] = useState('0x00000000000000000000000000000000');
    const [stakect, setStake] = useState([{}]);
    const [AllowA, setAllowA] = useState(0);
    const [account, setAccount] = useState([{}]);
    const [accountNRSC, setAccountNRSC] = useState(0);
    const [totalstaked, settotalstaked] = useState(0);
    const [accountBalancemTB, setAccountBalanceTB] = useState(0);
    const [APR, setAPR] = useState(0);
    const [poofee, setpoolfee] = useState(0);
    const [withdrawFee, setWithdrawFee] = useState(0);
    const [stakelimit, setstakelimit] = useState(0);
    const [symbola, setsymbolA] = useState("");
    const [symbolb, setsymbolB] = useState("");
    const [deca, setdecA] = useState(0);
    const [decb, setdecB] = useState(0);
    const [ia, setiA] = useState(0);
    const [ib, setiB] = useState(0);
    const [totalSupplyB, setTotalSupplyB] = useState(0);

    const [view_details, setview_details] = useState(true);
    const [view_Add, setview_Add] = useState(false);
    const [view_Remove, setview_Remove] = useState(false);
    const [view_Roi, setview_Roi] = useState(false);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        data()
    }, [])

    useEffect(() => {
        let interval = setInterval(() => {
            setSeconds(seconds => seconds + 1)
            data()
        }, 5000);
        return () => clearInterval(interval);
    }, [seconds]);

    async function data() {

        if (STAKECTS != null) {
            const _stakect = await props.Tokencontract(nftsabi, STAKECTS, provider);
            setStake(_stakect)
            call(_stakect.methods.NFT, gettokendataA);
            call(_stakect.methods.BEP20Rtoken, gettokendataB);
            if (ia == "https://bscscan.com/images/main/empty-token.png" || ia == 'https://martik.site/icons/stake/' + symbola + '.png') {
                setiA('https://martik.site/icons/stake/' + symbola + '.png')
            } else {
                setiA(await _stakect['methods']['_icon']().call())
            }


            if (ib == "https://bscscan.com/images/main/empty-token.png" || ib == 'https://martik.site/icons/stake/' + symbolb + '.png') {
                setiB('https://martik.site/icons/stake/' + symbolb + '.png')
            } else {
                setiB(await _stakect['methods']['_iconb']().call())
            }

            call(_stakect.methods._totalstaked, settotalstaked);

            try {
                if (props.title == "Holder Pool") { setAPR(props.apr) } else { call(_stakect.methods._FLEXAPR, setAPR) };
                if (accounts[0] != undefined) {
                    const _acc = await _stakect['methods']['userInfo'](accounts[0]).call()

                    setAccount(_acc)
                    setAccountNRSC((_acc['amountIR']).toString() == '0' ? 0 : await _stakect['methods']['rewardinstake'](accounts[0]).call())
                }
            } catch (error) {
                //console.log(error)
            }
        }
    }
    async function gettokendataA(e) {
        settokenACT(e)
        const token = await props.Tokencontract(nftabi, e, provider);
        settokenB(token)
        call(token.methods.name, setsymbolA);

 
    }
    async function gettokendataB(e) {
        settokenBCT(e)
        const token = await props.Tokencontract(tokenabi, e, provider);
        call(token.methods.symbol, setsymbolB);
        call(token.methods.decimals, setdecB);
    }
    function call(func, callback, ...args) {
        func(...args).call()
            .then((result) => {
                callback(result);
            })
            .catch((error) => {
                //console.log(error)
            })
    }
    function nextpercent(accountBalancemctTB, d) {
        return parseFloat((accountBalancemctTB) + "").toFixed(2)

    }

    function nextblock(accountBalancemctTB, d) {
        if (accountBalancemctTB != undefined && accountBalancemctTB != 0) {
            if (accountBalancemctTB.toString().length >= d) {
                return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(2)
            } else {
                let cutin = (d - accountBalancemctTB.toString().length) + 2
                return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(cutin)
            }
        } else {
            return 0.00
        }
    }
    async function nstake(value) {
      /*  let a = ['']
        let e = value + ""
        if (e.toString().includes(",")) {
            a = e.toString().split(",")
            e = ethers.utils.parseUnits(a[0] + "." + a[1], deca).toString()
        } else {
            e = ethers.utils.parseUnits(e, deca).toString()
        }*/
        await props.nSendTX(STAKECTS, nftsabi, props.netId, '_stake', props.poofee, 0, value)
        data()
    }
    async function rstake(value) {
        
        await props.nSendTX(STAKECTS, nftsabi, props.netId, 'unStake', props.poofee, 0)
        data()
    }
    async function hstake() {
        await props.nSendTX(STAKECTS, nftsabi, props.netId, 'Havest', props.poofee, 0)
        data()
    }
    async function approve(id) {
        await props.nSendTX(tokenB['_address'], nftabi, props.netId, 'approve', '0', 0, STAKECTS, id + "")
        data()
    }
    async function getAllowance(callback, id) {
        const token = await props.Tokencontract(nftabi, tokenB['_address'], provider);
        if (accounts[0] != undefined) {
            call(token.methods.getApproved, (e)=>{callback(STAKECTS == e ? true : false)} , id);
        }
    }
    const SAFE = (props) => {
        return (
            <p style={{ width: "95%", display: "grid", gridTemplateColumns: "auto auto", margin: "auto", marginTop: "5px" }}><span style={{
                textAlign: "left",
                width: "100%",
                color: outFont,
                fontSize: "15px",
            }}>{props.what} </span><span style={{
                textAlign: "right",
                width: "100%",
                fontSize: "16px",
                color: outFont,
            }}>
                    {props.result}
                </span></p>
        );
    }


    /*return (
      <div>
  
      </div>
    );*/
    return (
        <div style={{
            height: "min-content",
            width: "100%",
            minWidth: "280px",
            listStyleType: "none",
            display: "grid",
            margin: "auto",
            borderRadius: "10px",
            color: outFont,
            fontSize: "12px",
            paddingTop: "10px",
            background: buttonsColor
        }}>
           

            <div style={{ display: "grid", gap: "10px" }}>

                <div style={{ display: "grid", gridTemplateColumns: "100px auto ", width: "100%", height: "70px", marginBottom: "20px", padding: "auto" }}>
                    <div style={{
                        height: "90px",
                        width: "90px",
                        margin: "auto",
                        background: "url('" + ia + " ')  left top / 70px no-repeat",
                    }}>
                        <div style={{
                            position: "relative",
                            marginLeft: "40px",
                            marginTop: "40px",
                            height: "40px",
                            width: "40px",
                            border: "1px solid " + Borders,
                            borderRadius: "90px",
                            background: "url('" + ib + " ')  center center / 40px no-repeat",
                        }}>
                        </div>
                    </div>
                    <div style={{
                        height: "min-content",
                        width: "100%",
                        margin: "auto 0",
                        display: "grid",
                        gridTemplateColumns: "180px",

                    }}>
                        <p style={{ textAlign: "left", height: "min-content", fontWeight: "bold", fontSize: "15px", margin: "auto 0", color: outFont }}>{props.title == '' ? "Earn " + symbolb : props.title}  </p>

                        <p />
                        <p style={{ textAlign: "left", height: "min-content", fontSize: "12px", marginBottom: "0px", color: outFont }}>Stake {symbola} - Earn {symbolb}</p>
                        <div style={{
                            height: "30px",
                            marginLeft: "0",
                            display: "grid",
                            gridTemplateColumns: "100px 100px",
                            color: innerFont
                        }}>

                        </div>
                    </div>

                </div>

                <div style={{ display: "grid", gridTemplateColumns: "50% auto", height: "70px", width: "95%", margin: "auto", }}>
                    <p style={{
                        display: "grid", margin: "auto", textAlign: "left",
                        width: "100%",
                        fontSize: "13px"
                    }}>
                        Staked {symbola}
                        <span style={{ fontSize: "16px", fontWeight: "bold", }}>
                            {nextblock(account['amountIR'], 18)}
                        </span>

                    </p>
                    <div style={{ display: "grid", gridTemplateColumns: "auto auto", height: "50px", width: "auto", gap: "5px", margin: "auto", fontSize: "20px", marginRight: "0", color: innerBFont }}>
                        {props.online && <button onClick={() => {
                            setview_Add(true)
                            setview_Remove(false)
                        }} style={{ cursor: "pointer", display: "grid", background: outbuttonsColor, border: "1px solid" + Borders, borderRadius: "10px", height: "50px", width: "50px", margin: "auto", }}>
                            <p style={{ margin: "auto" }}>+</p>
                        </button>}
                        <button onClick={() => {
                            setview_Add(false)
                            setview_Remove(true)
                        }} style={{ cursor: "pointer", display: "grid", border: "1px solid" + Borders, background: account['amountIR'] > 0 ? outbuttonsColor : outbuttonsColor + "50", borderRadius: "10px", height: "50px", width: "50px", margin: "auto" }} disabled={!(account['amountIR'] > 0)}>
                            <p style={{ margin: "auto" }}>-</p>
                        </button>
                    </div>

                </div>

                {withdrawFee != 0 && <SAFE what={withdrawFee + "% pool fee "} result={''} />}
                <SAFE what={"Perfomance Fee"} result={props.poofee > 0 ? nextblock(props.poofee, 18) + " BNB" : '0%'} />


                <div style={{ height: "min-content", width: "260px", margin: "auto", marginTop: "0px", padding: "15px 5px", cursor: "pointer", borderRadius: "10px" }}>

                    <span style={{
                        textAlign: "left",
                        width: "100%",
                        fontSize: "16px",
                        margin: "auto",
                    }}>RECENT PROFIT {symbolb}</span>
                    <br />
                    {true &&
                        <div style={{ display: "grid", gridTemplateColumns: !props.title.toString().includes('Earn') ? "auto" : 'auto auto', height: "min-content", width: "95%", margin: "auto", marginTop: "5px" }}>
                            {<p style={{
                                display: "grid", margin: "auto", textAlign: "left",
                                width: "100%",
                                fontSize: "12px",

                                paddingTop: "20px"
                            }}>
                                <span style={{ fontSize: "14px", fontWeight: "bold", }}>
                                    <SAFE what={'Earned'} result={accountNRSC > 0 ? nextblock(accountNRSC, decb) + " " + symbolb : '-'} />
                                </span>
                            </p>}
                            {props.online && <div style={{ display: "grid", height: "50px", width: "100%", gap: "5px", margin: "auto", fontSize: "20px", marginRight: "0", marginLeft: "0" }}>
                                <Button func={() => { hstake() }} text={"Harvest"} />
                            </div>}
                        </div>}
                </div>

                <div style={{ display: "grid", height: "50px", width: "100%", gap: "5px", margin: "auto", fontSize: "20px", marginRight: "0", marginLeft: "0" }}>
                    <Button func={() => { props.setModal(undefined) }} text={"Close"} />
                </div>
            </div>
        </div >
    );

}

const Stake = (props) => {
    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = useContext(AppContext)

    const [address, setAddress] = useState(account);

    const [modal, setModal] = useState(undefined);
    const [disable_list, setDisable_list] = useState([{}]);
    const [list_mode, setListMode] = useState(0);
    const [active_mode, setactiveMode] = useState(0);
    const [only_staked_mode, setOnly_Staked_Mode] = useState(false);
    const [filter, setFilter] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [poofee, setpoofee] = useState(0);
    const [filterlay, setFilterLay] = useState('none');
    useEffect(() => {
        //document.title = "MARTIK | POOLS"
    }, []);
    useEffect(() => {
    }, [modal]);

    const Modalz = (props) => {
        return (
            <div style={{ color: "#222", height: "100%", width: "100%", position: "fixed", display: "grid", background: "linear-gradient(to bottom right, #ffffff20, #ffffff20)", top: "0", left: "0", zIndex: "10000" }} >
                <div style={{ height: "min-content", width: "auto", display: "grid", gridTemplateRows: "50px 70px 70px 70px 70px", margin: "auto", width: "280px", height: "min-content", borderRadius: "10px", paddingBottom: "10px" }}>
                    <NModal setModal={setModal} tokenM={modal.act} title={modal.title} minimo={modal.min} nSendTX={nSendTX} netId={getID()} Tokencontract={Tokencontract} desc={modal.desc} apr={modal.apr} online={modal.online} apydesc={modal.apydesc} poofee={modal.poofee} official={modal.official} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={['https://data-seed-prebsc-1-s3.binancmodal.org:8545', modal.cts, nftsabi, abiToken, address]} />
                </div>
            </div>
        );
    }
    return (
        <div style={{ width: "100%", margin: "auto", maxWidth: "1280px", display: "grid" }}>
            <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: window.innerWidth < 720 ? "auto" : "auto" }}>
                <div style={{ height: "min-content", }}>

                    <Minimized setModal={setModal} />
                </div>
                {modal != undefined && <Modalz />}
            </div>
        </div>
    );
}

export default Stake;