import React, { useState, useEffect, useContext } from 'react';
import { ethers } from 'ethers';
import bnbabi from "../../../abis/bnbabi.json";
import Numberinput from "../../../components/Launchpad/numberInput";
import SAFE from "../../../components/Launchpad/textpar";
import InputBlock from "../../../components/Launchpad/InputBlock";
import DescBlock from "../../../components/Launchpad/descBlock";
import Button from "../../../components/Launchpad/Button";
import Check from "../../../components/Launchpad/Checkbox";
import Checklist from "../../../components/Launchpad/ListCheck";
import StepBar from "../../../components/Launchpad/Stepbar";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import enUS from 'date-fns/locale/en-US';
import "react-datepicker/dist/react-datepicker.css";

import AppContext from "../../../framework/helpers/AppContext";
import env from '../../../hooks/data/Config';

import { useNavigate } from 'react-router-dom';
import { number } from 'prop-types';

import ProjectData from '../../../config';

let projectName = "Martik"
const {
    Name,
    Logo,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,
    innerFont,
    innerBFont,
    menudata,
    Routers,
    logoUrl,
    info,
    screens
} = ProjectData[window.location.host.split('.')[0] != undefined ? window.location.host.split('.')[0] : "Martik"]


const Web3 = require('web3-eth');
registerLocale('en-US', enUS)
const CreateLp = (props) => {
    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = useContext(AppContext)
    const tokens_default = screens["swap"].web3.Tokens;

    const providers = {
        56: "0x329713680405f3a384D84131c8b2aA8fa694712C",
        97: "0x4A915343bc5F9114067971aE68F20e144C1BC2B4",
        1999: "0xE069f9e403bbBa139D2F84D3C60Ef2151A22ef55"
    }

    const [LPprovider, setLPprovider] = useState(providers[getID()]);
    const [address, setaddress] = useState(account);

    const [ta, setta] = useState(tokens_default[getID()][0].contract);
    const [tb, settb] = useState("");
    const [aa, setaa] = useState(0);
    const [ab, setab] = useState(0);


    const [tokenA, setTokenA] = useState({
        ct: undefined,
        name: "",
        symbol: "BNB",
        dec: 18,
        supply: "",
        allow: 10000000000000000,
        balance: 0
    });
    const [tokenB, setTokenB] = useState({
        ct: undefined,
        name: "",
        symbol: "",
        dec: 0,
        supply: "",
        allow: 0,
        balance: 0
    });

    useEffect(() => {
        //document.title = "Token Add lp | Martik"
    }, []);
    function nextblock(accountBalancemctTB, d) {
        if (accountBalancemctTB != undefined && accountBalancemctTB != 0) {
          if (accountBalancemctTB.toString().length >= d) {
            return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(5)
          } else {
            let cutin = (d - accountBalancemctTB.toString().length) + 2
            return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(cutin)
          }
        } else {
          return 0.00
        }
      }
    function returnbignumber(value, dec) {
        let a = ['']
        let e = value + ""
        if (e.toString().includes(",")) {
            a = e.toString().split(",")
            e = ethers.utils.parseUnits(a[0] + "." + a[1], dec).toString()
        } else {
            e = ethers.utils.parseUnits(e, dec).toString()
        }
        return e
    }
    function numberformat(values) {
        let a = []
        for (let index = 0; index < values.length; index++) {
            a.push((values[index] * 100).toFixed(0))
        }
        return a
    }
    const navigate = useNavigate();
    async function create() {
        try {
            await nSendTX(
                LPprovider,
                [
                    {
                        "inputs": [
                            {
                                "internalType": "address",
                                "name": "tokenA",
                                "type": "address"
                            },
                            {
                                "internalType": "address",
                                "name": "tokenB",
                                "type": "address"
                            },
                            {
                                "internalType": "uint256",
                                "name": "_amountA",
                                "type": "uint256"
                            },
                            {
                                "internalType": "uint256",
                                "name": "_amountB",
                                "type": "uint256"
                            }
                        ],
                        "name": "_share",
                        "outputs": [],
                        "stateMutability": "payable",
                        "type": "function"
                    }
                ],
                getID(),
                '_share',
                (ta).toUpperCase() == (tokens_default[getID()][0].contract).toUpperCase() ? returnbignumber(parseFloat(aa) + parseFloat(0.05), 18) : returnbignumber(parseFloat(0.005), 18), 0
                , ta, tb, returnbignumber(parseFloat(aa), tokenA.dec), returnbignumber(parseFloat(ab), tokenB.dec))
        } catch (error) {
            console.log(error)
        }


    }
    async function gettoken(ct, set) {
        if (ct.length <= 0) {

        } else {
            if (ct.length == 42) {
                if (ct.includes("0x")) {
                    let obj = {}
                    const bota = await Tokencontract(bnbabi, ct);
                    const name = await bota.methods.name().call()
                    const symbol = await bota.methods.symbol().call()
                    const dec = await bota.methods.decimals().call()
                    const totalSupply = await bota.methods.totalSupply().call()
                    const allow = await bota.methods.allowance(address, LPprovider).call()

                    const bal = address != undefined ? await bota.methods.balanceOf(address).call() : 0
                    obj.ct = ct
                    obj.name = name
                    obj.symbol = symbol
                    obj.dec = dec
                    obj.supply = totalSupply
                    obj.allow = allow
                    obj.balance = bal
                    set(obj)
                }
            } else {

            }
        }
    }

    return (
        <div style={{ height: "min-content", padding: "20px 10px", width: "100%", maxWidth: "1024px", border: "1px solid #ffffff10", borderRadius: "10px", margin: "auto" }}>
            <div style={{ display: "grid", gap: "10px" }}>


                <InputBlock title={"Token A *"} place={"0x..."} variavel={ta} setvariavel={(e) => { setta(e); gettoken(e, setTokenA) }} desc={"Main token like (MTK, BNB, BUSD, CAKE, USDT)"} />
                <InputBlock type={"Number"} title={"Amount A *"} place={"0"} variavel={aa} setvariavel={setaa} desc={"Balance: " + nextblock(tokenA.balance, tokenA.dec)} />
                <InputBlock title={"Token B *"} place={"0x..."} variavel={tb} setvariavel={(e) => { settb(e); gettoken(e, setTokenB) }} desc={"Your token"} />
                <InputBlock type={"Number"} title={"Amount B *"} place={"0"} variavel={ab} setvariavel={setab} desc={"Balance: " + nextblock(tokenB.balance, tokenA.dec)} />


                Exclude from fee: {LPprovider + " "}
                FEE : 0.05
                <div className='flex items-center justify-center gap-6 mb-6'>

                    {!(tokenA.allow >= aa) && <Button func={() => {
                        nSendTX(tokenA.ct, bnbabi, getID(), 'approve', '0', 0, LPprovider, '1' + '0'.repeat(50)).then(a => {
                            gettoken(tokenA.ct, setTokenA)
                        }).catch(e => {

                        })
                    }} text={'Approve ' + tokenA.symbol} next={true} />}
                    {!(tokenB.allow >= ab) && <Button func={() => {
                        nSendTX(tokenB.ct, bnbabi, getID(), 'approve', '0', 0, LPprovider, '1' + '0'.repeat(50)).then(a => {
                            gettoken(tokenB.ct, setTokenB)
                        }).catch(e => {

                        })
                    }} text={'Approve ' + tokenB.symbol} next={true} />}
                    <Button func={() => {
                        create()
                    }} text={'ADD LIQUIDITY V2'} next={true} />

                </div>

            </div>

        </div>
    );


}
export default CreateLp;