import React, { useState, useEffect, useContext } from "react";
import pancakeabi from "../abis/pankakeRouter.json";
import abiToken from "../abis/bnbabi.json";
import Modal from './swap/modal';
import AppContext from "../framework/helpers/AppContext";
import env from '../hooks/data/Config';
import ProjectData from '../config';

let projectName = "Martik"
const {
  Name,
  Logo,
  backgroundColor,
  buttonsColor,
  modalsColor,
  buttonsBorders,
  inputsColor,
  inputsBorders,
  outbuttonsColor,
  Borders,
  outFont,
  innerFont,
  innerBFont,
  menudata,
  Routers,
  logoUrl,
  info,
  screens
} = ProjectData[window.location.host.split('.')[0] != undefined ? window.location.host.split('.')[0] : "Martik"]
const Web3 = require('web3-eth');
const Swap = (props) => {

    const GLOBAL = useContext(AppContext)
    const {
        account,
        provider,
        chain,
        chains,
        web3,
        aweb3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = useContext(AppContext)
    GLOBAL.provider = aweb3()
    GLOBAL.chain = {
        chainId: getID()
    }

    return (
        <div className="bg-light dark:bg-dark " style={{ background:modalsColor}}>
            <div className=" pt-[180px]">
                <div style={{ width: "100%", margin: "auto", display: "grid", padding: "2vh" }}>
                    <div style={{
                        height: "min-content",
                        width: "100%",
                        display: "grid",
                        gap: "10px",
                        borderRadius: "10px",
                        marginTop: "auto",
                        color: "#fff",

                    }}>
                        <Modal title={"sdgsg"} nSendTX={GLOBAL['nSendTX']} Tokencontract={GLOBAL['Tokencontract']} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} chainId={GLOBAL['chain']['chainId']} array={[GLOBAL['provider'], env.CONTRACTS[GLOBAL['chain']['chainId']].router, pancakeabi, abiToken, GLOBAL['account']]} />
                    </div>

                    <p style={{ height: "10vh" }}></p>
                </div>
            </div>
        </div>
    );
}

export default Swap;