import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import bnbabi from "../../../abis/bnbabi.json";
import Numberinput from "../../../components/Launchpad/numberInput";
import SAFE from "../../../components/Launchpad/textpar";
import InputBlock from "../../../components/Launchpad/InputBlock";
import DescBlock from "../../../components/Launchpad/descBlock";
import Button from "../../../components/Launchpad/Button";
import Check from "../../../components/Launchpad/Checkbox";
import Checklist from "../../../components/Launchpad/ListCheck";
import StepBar from "../../../components/Launchpad/Stepbar";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import enUS from 'date-fns/locale/en-US';
import "react-datepicker/dist/react-datepicker.css";
import abi from "../../../abis/lock/lock.json";
import env from '../../../hooks/data/Config';
import ProjectData from '../../../config';

let projectName = "Martik"
const {
    Name,
    Logo,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,
    innerFont,
    Routers,
    screens
} = ProjectData[window.location.host.split('.')[0] != undefined ? window.location.host.split('.')[0] : "Martik"]


const Web3 = require('web3-eth');
registerLocale('en-US', enUS)
const Create = (props) => {
    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = props.interact

    const [_poolfee, setpoofee] = useState(0);
    const [contract, setcontract] = useState(env.CONTRACTS[getID()].lockContract);

    const [tokenB, setTokenB] = useState({
        ct: undefined,
        name: "",
        symbol: "",
        dec: 0,
        supply: "",
        allow: 0
    });

    const [address, connector] = useState(account);
    const [tokenBCT, setTokenBCT] = useState(undefined);
    const [name, setName] = useState(undefined);
    const [amountTo, setAmountTo] = useState(0);
    const [endTime, setendTime] = useState(new Date());

    const [approveBut, setapproveBut] = useState(<Button func={() => {
        if (tokenB.allow > 1) {
            create()
        } else {
            approve()
        }
    }} text={tokenB.allow > 0 ? "CREATE" : "APPROVE"} next={true} />);
    useEffect(() => {
        setendTime(_toUTC(new Date()))
    }, [])
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
            gettoken(tokenB.ct)
        }, 1000);

        return () => clearInterval(interval);
    }, [seconds]);
    function nextblock(accountBalancemctTB, d) {
        if ((accountBalancemctTB / (10 ** d)).toString().includes('.')) {
            if (accountBalancemctTB.toString().length >= d) {
                return (
                    (accountBalancemctTB / (10 ** d)).toString().split('.')[0] +
                    '.' +
                    (accountBalancemctTB / (10 ** d)).toString().split('.')[1].slice(0, 2)
                );
            } else {
                return (
                    '0.' +
                    '0'.repeat(d - accountBalancemctTB.toString().length) +
                    accountBalancemctTB.toString().slice(0, 2)
                );
            }
        } else {
            if ((accountBalancemctTB / (10 ** d)).toString().includes(',')) {
                if (accountBalancemctTB.toString().length >= d) {
                    return (
                        (accountBalancemctTB / (10 ** d)).toString().split(',')[0] +
                        '.' +
                        (accountBalancemctTB / (10 ** d)).toString().split(',')[1].slice(0, 2)
                    );
                } else {
                    return (
                        '0.' +
                        '0'.repeat(d - accountBalancemctTB.toString().length) +
                        accountBalancemctTB.toString().slice(0, 2)
                    );
                }
            } else {
                return accountBalancemctTB / (10 ** d);
            }
        }
    }
    function nf(e) {
        return ('0' + e).slice(-2)
    }
    function toUTC(func, dateA) {
        func(dateA)
    } function _toUTC(dateA) {
        const time = new Date(
            dateA.getUTCFullYear()
            + "-" +
            nf(dateA.getUTCMonth() + 1)
            + "-" +
            nf(dateA.getUTCDate())
            + "T" +
            nf(dateA.getUTCHours())
            + ":" +
            nf(dateA.getUTCMinutes())
            + ":00");

        return (Date.parse(time))
    }

    function now() {
        const dateA = new Date()
        const time = new Date(
            dateA.getUTCFullYear()
            + "-" +
            nf(dateA.getUTCMonth() + 1)
            + "-" +
            nf(dateA.getUTCDate())
            + "T" +
            nf(dateA.getUTCHours())
            + ":" +
            nf(dateA.getUTCMinutes())
            + ":00");

        return Date.parse(time)
    }
    function returnbignumber(value, dec) {
        let a = ['']
        let e = value + ""
        if (e.toString().includes(",")) {
            a = e.toString().split(",")
            e = ethers.utils.parseUnits(a[0] + "." + a[1], dec).toString()
        } else {
            e = ethers.utils.parseUnits(e, dec).toString()
        }
        return e
    }
    async function create() {
        const ct = await Tokencontract(abi, contract);
        const end = parseInt(Date.parse(endTime) / 1000)
        console.log(amountTo)
        nSendTX(contract, abi, getID(), 'newLock', '0', 0,
            name,
            address,
            tokenB.ct,
            returnbignumber(amountTo, tokenB.dec),
            end
        ).then(a => {
            //a.hash
            //console.log(a)
        }).catch(e => {
            //console.log(e)
        })
    }
    async function approve() {
        nSendTX(tokenB.ct, bnbabi, getID(), 'approve', '0', 0, contract, '1' + '0'.repeat(50)).then(a => {
            gettoken(tokenBCT)
        }).catch(e => {

        })
    }

    async function gettoken(ct) {
        try {
            if (ct.length <= 0) {

            } else {
                if (ct.length == 42) {
                    if (ct.includes("0x")) {
                        let obj = tokenB
                        setTokenBCT(ct)
                        const bota = await Tokencontract(bnbabi, ct);
                        const name = await bota.methods.name().call()
                        const symbol = await bota.methods.symbol().call()
                        const dec = await bota.methods.decimals().call()
                        const totalSupply = await bota.methods.totalSupply().call()
                        const allow = await bota.methods.allowance(address, contract).call()

                        obj.ct = ct
                        obj.name = name
                        obj.symbol = symbol
                        obj.dec = dec
                        obj.supply = totalSupply
                        obj.allow = allow
                        setTokenB(obj)
                        setapproveBut(<Button func={() => {
                            if (allow > 1) {
                                create()
                            } else {
                                approve()
                            }
                        }} text={allow > 0 ? "CREATE" : "APPROVE"} next={true} />)
                    }
                } else {

                }
            }
        } catch (error) {

        }

    }

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button ref={ref} onClick={onClick} className='inpult-primary'>
            {new Date(value).toUTCString()}
        </button>
    ));

    return (
        <div style={{ height: "min-content", padding: "20px 10px", width: "100%", border: "1px solid" + Borders, borderRadius: "10px" }}>
            <div style={{ display: "grid", gap: "10px" }}>
                <InputBlock title={"Lock Name"} place={""} variavel={name} setvariavel={setName} desc={""} />
                <InputBlock title={"Token Address *"} place={"0x123..."} variavel={tokenBCT} setvariavel={gettoken} desc={""} />
                <SAFE what={"Name"} result={tokenB.name} />
                <SAFE what={"Symbol"} result={tokenB.symbol} />
                <SAFE what={"Decimals"} result={tokenB.dec} />
                <SAFE what={"Supply"} result={nextblock(tokenB.supply, tokenB.dec)} />

                <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)", width: "100%" }}>
                    <InputBlock title={"Amount to lock *"} place={"0"} variavel={amountTo} setvariavel={setAmountTo} desc={""} />
                    <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", }}>
                        <p >End time </p>
                        <DatePicker
                            selected={endTime}
                            onChange={(date) => toUTC(setendTime, date)}
                            touchUi={true} locale="en-US"
                            showTimeSelect
                            timeFormat="HH:mm aa"
                            timeIntervals={5}
                            timeCaption="time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            customInput={<CustomInput />}
                        />
                        <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#999", fontSize: "10px" }}></p>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {approveBut}

                </div>

            </div>
        </div>
    );


}
export default Create;