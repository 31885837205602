import React, { useState, useEffect, useRef } from 'react';
import './home.css';
import ProjectData from '../config';
import { ethers } from 'ethers';
import bnbabi from '../abis/bnbabi.json'
import Button from '../components/Launchpad/Button';
const Web3 = require('web3-eth');

let projectName = "Martik"
const {
  Name,
  Logo,
  backgroundColor,
  buttonsColor,
  buttonsBorders,
  inputsColor,
  inputsBorders,
  outbuttonsColor,
  Borders,
  outFont,
  innerFont,
  Routers,
  logoUrl,
  tokenContract,
  rpc,
  info
} = ProjectData[window.location.host.split('.')[0] != undefined ? window.location.host.split('.')[0] : "Martik"]

const Newhome = () => {
  const [token, setToken] = useState({
    Title: info.title,
    Desc: info.desc,
    buyon: info.buyon,
    name: info.name,
    symbol: info.symbol,
    supply: "wait...",
    supplyText: "tokens",
    csupply: "wait...",
    csupplyText: "tokens",
    projectwallet: "0",
    projectText: "tokens",
    burnit: "wait...",
    burnitText: "tokens",
  });
  useEffect(() => {
    price()
  }, []);
  function nextblock(accountBalancemctTB, d) {
    if (accountBalancemctTB != undefined && accountBalancemctTB != 0) {
      if (accountBalancemctTB.toString().length >= d) {
        return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(2)
      } else {
        let cutin = (d - accountBalancemctTB.toString().length) + 2
        return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(cutin)
      }
    } else {
      return 0.00
    }
  }
  async function price() {
    if (tokenContract != undefined) {
      const web3 = new Web3(new Web3.providers.HttpProvider(rpc));
      const token = await new web3.Contract(bnbabi, tokenContract);

      const decimals = await token.methods.decimals().call()
      const ts = await token.methods.totalSupply().call()
      const zerobalance = await token.methods.balanceOf("0x0000000000000000000000000000000000000000").call()
      const name = await token.methods.name().call()
      const symbol = await token.methods.symbol().call()

      setToken({
        Title: info.title,
        Desc: info.desc,
        buyon: info.buyon,
        name: name,
        symbol: symbol,
        supply: nextblock(ts, decimals),
        supplyText: "tokens",
        csupply: parseFloat(nextblock(ts, decimals)) - parseFloat(nextblock(zerobalance, decimals)),
        csupplyText: "tokens",
        projectwallet: "0",
        projectText: "tokens",
        burnit: nextblock(zerobalance, decimals),
        burnitText: "tokens",
      })
    }

  }

  const TokenInfo = () => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const ref = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      });

      observer.observe(ref.current);
      return () => observer.disconnect();
    }, [isIntersecting]);

    useEffect(() => {
      if (isIntersecting) {
        ref.current.querySelectorAll("div").forEach((el) => {
          el.classList.add("fade-in");
        });
      }
    }, [isIntersecting]);
    return (
      <>
        {<div ref={ref} style={{
          height: "min-content",
          width: "100%",
        }}>
          <div style={containerStyleA}>
            <div style={contentContainerStyleA}>
              <div style={itemStyle}>
                <p style={labelStyle}>Nome</p>
                <p style={valueStyle}>{token.name}</p>
              </div>
              <div style={itemStyle}>
                <p style={labelStyle}>Simbolo</p>
                <p style={valueStyle}>{token.symbol}</p>
              </div>
              <div style={itemStyle}>
                <p style={labelStyle}>Suprimento total</p>
                <p style={valueStyle}>{token.supply}</p>
                <p style={subValueStyle}>tokens</p>
              </div>
              <div style={itemStyle}>
                <p style={labelStyle}>Suprimento circulante</p>
                <p style={valueStyle}>{token.csupply}</p>
                <p style={subValueStyle}>tokens</p>
              </div>
            </div>

          </div>

        </div>}
      </>

    );
  };

  const containerStyleA = {
    height: 'min-content',
    width: '100%',
    display: 'grid',
    margin: 'auto',
    paddingTop: "10px",
    paddingBottom: "10px",
    maxWidth: "1280px",
    margin: "auto",
    marginTop: '40px',
  };

  const contentContainerStyleA = {
    height: 'min-content',
    width: '100%',
    display: 'grid',
    margin: 'auto',
    gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
    gap: '15px',
    marginTop: '0px',

  };

  const itemStyle = {
    height: '100px',
    width: '280px',
    borderRadius: "5px",
    padding: '10px 5px',
    margin: 'auto',
    background: "#ff446b00",
    border: "1px solid " + Borders
  };

  const labelStyle = {
    fontSize: '16px',
    color: outFont,
    textAlign: 'center',
  };

  const valueStyle = {
    fontSize: '25px',
    color: outFont,
    fontWeight: "bold",
    textAlign: 'center',
  };

  const subValueStyle = {
    fontSize: '16px',
    color: outFont,
    textAlign: 'center',
  };


  const Footer = () => {
    const footerStyle = {
      padding: "20px",
      textAlign: "center",
      fontSize: "14px",
      color: outFont,
    };

    return (
      <footer style={footerStyle}>
        <p>&copy; 2023 {token.name}. All rights reserved.</p>
      </footer>
    );
  };

  const containerStyle = {
    height: "min-content",
    minHeight: "600px",
    width: "100%",
    gap: "10px",
    //maxWidth: "1280px",
    display: "grid",
    gridTemplateColumns: window.innerWidth < 720 ? "auto" : "auto auto", //'repeat(auto-fit, minmax(100px, 1fr))',
    color: outFont,
    borderRadius: "2px",
    margin: "auto",

  };



  const contentContainerStyle = {
    height: "min-content",
    width: "100%",
    display: "grid",
    margin: "auto",
    gap: "10px",
    paddingLeft: window.innerWidth < 720 ? "10px" : "50px",
  };

  const headingStyle = {
    textAlign: "left",
    fontSize: "40px",
    fontWeight: "bold",
    margin: "auto 0",
  };

  const descriptionStyle = {

    fontSize: "16px",
    fontWeight: "200",
    height: "100%",
    width: "100%",

    margin: "0 auto",
    textAlign: "left",
  };

  const buttonContainerStyle = {
    width: "min-content",
    minWidth: "200px",
    display: "flex",

    gap: "10px",
  };



  const imageContainerStyle = {
    height: "280px",
    width: "280px",
    color: "#fff",
    margin: "auto",
    background: "url(" + logoUrl + ")",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "grid",
    borderRadius: "360px",
  };

  const Content0 = () => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const ref = useRef(null);

    useEffect(() => {

      const observer = new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      });

      observer.observe(ref.current);
      return () => observer.disconnect();
    }, [isIntersecting]);

    useEffect(() => {
      if (isIntersecting) {
        ref.current.querySelectorAll("div").forEach((el) => {
          el.classList.add("slide-in");
        });
      }
    }, [isIntersecting]);
    return (
      <div ref={ref} style={{
        height: "min-content",
        minHeight: "480px",
        width: "100%",
        display: 'grid'
      }}>
        <div style={{
          maxWidth: "1280px",
          margin: "auto",
        }}>

          <div style={containerStyle}>
            <div style={contentContainerStyle}>
              <p style={headingStyle}>{token.Title}


              </p>

              <div style={descriptionStyle}>
                <br />
                {token.Desc}
              </div>
              <div style={buttonContainerStyle}>
                <Button func={() => {
                  window.open(token.buyon)
                }} text={"Meet " + token.symbol} />
              </div>
            </div>

            <div style={imageContainerStyle}></div>
          </div>
        </div>
      </div>
    )
  }



  //('refFrom') == null
  return (
    <div>
      <Content0 />
      {tokenContract != undefined && <TokenInfo />}
      <Footer />
    </div>
  );

}

export default Newhome;