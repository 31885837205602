import React from 'react';
import ProjectData from '../../config';
let projectName = "Martik"
const {
  Name,
  Logo,
  backgroundColor,
  buttonsColor,
  buttonsBorders,
  inputsColor,
  inputsBorders,
  outbuttonsColor,
  Borders,
  outFont,
  innerFont,
  Routers
} = ProjectData[window.location.host.split('.')[0] != undefined ? window.location.host.split('.')[0] : "Martik"]

function DescBlock(props) {
  return (
    <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", gap: "2px", margin: "auto" }}>
      <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: outFont, fontSize: "14px" }}>{props.title}</p>
      <textarea onChange={(e) => { props.setvariavel(e.target.value) }} placeholder={props.place} value={props.variavel} style={{ fontSize: "15px", outline: "none", minHeight: "150px", color:innerFont, textAlign: "left", height: "35px", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", border: inputsBorders, background: inputsColor, borderRadius: "10px", paddingLeft: "10px" }}>

      </textarea>
      <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: outFont, fontSize: "10px" }}>{props.desc}</p>
    </div>
  );
}

export default DescBlock;
