import React from 'react';
import ProjectData from '../../config';

if (window.location.host.split('.')[0] != undefined) {

} else {

}

//let projectName = "Martik"
const {
    Name,
    Logo,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,
    innerFont,
    innerBFont,
    Routers
} = ProjectData[window.location.host.split('.')[0] != undefined ? window.location.host.split('.')[0] : "Martik"]

const Button = (props) => {
    return (
        <button onClick={props.func} style={{ background: outbuttonsColor, border: buttonsBorders, cursor: "pointer", borderRadius: "5px", padding: "5px 20px", color: innerBFont, fontSize: "14px", width: "100%", maxWidth: "250px", margin: "auto", marginTop: "7px" }}>
            <p >{props.text}</p>
        </button>
    );
}

export default Button;